import { apiInstance } from "../api";

export class UrlService {
  static save(url) {
    return apiInstance.post("/url/save.php", { url });
  }

  static check(url) {
    return apiInstance.post("/url/check.php", { url });
  }
}
