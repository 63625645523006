import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userModel } from "../../entities/user";
import { validateEmail } from "../../shared/lib/validation/email";

import { Button } from "../../components/Button";
import { ButtonTextLink } from "../../components/ButtonText";
import TextFieldRounded from "../../components/TextFieldRounded";
import { routeNames } from "../../shared/config";

import "../auth-styles/sign.scss";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  async function Auth() {
    if (!validateEmail(email)) {
      return setErrorMessage("Введите корректную почту!");
    }
    if (password.length < 6) {
      return setErrorMessage("Пароль должен быть больше 6 символов!");
    }

    const { success, error } = await userModel.signIn(email, password);

    if (!success) {
      return setErrorMessage(error);
    }

    if (success) navigate(routeNames.main);
  }

  return (
    <MainTemplate>
      <div className="wrapper sign column justify-center items-center">
        <h1 className="text">Авторизация</h1>

        <TextFieldRounded className="grey-textfield" type="email" placeholder="E-mail" value={email} set={setEmail} />

        <TextFieldRounded
          className="grey-textfield"
          type="password"
          placeholder="Пароль"
          value={password}
          set={setPassword}
        />

        {errorMessage && <div className="error">{errorMessage}</div>}

        <div className="sign__buttons flex w-100 justify-between items-center">
          <Button text="Войти" action={Auth} />

          <ButtonTextLink text="Забыли пароль?" link={routeNames.recoveryPassword} />
        </div>

        <div className="sign__reglink flex flex-col items-center">
          <p className="text">У вас ещё нет аккаунта?</p>

          <ButtonTextLink text="Зарегистрироваться" link={routeNames.signUp} />
        </div>
      </div>
    </MainTemplate>
  );
};
