import React, { useState } from "react";
import { userModel } from "../../entities/user";
import { validateEmail } from "../../shared/lib/validation/email";

import { Button } from "../../components/Button";
import TextFieldRounded from "../../components/TextFieldRounded";
import "../auth-styles/sign.scss";
import { Link } from "../../shared/ui/link/link";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const SignUp = () => {
  const [formSended, setFormSended] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function Reg() {
    if (email.length === 0) {
      return setErrorMessage("Заполните адрес электронной почты!");
    }
    if (password.length === 0) {
      return setErrorMessage("Заполните пароль!");
    }
    if (email.length > 30 && email.length < 6) {
      return setErrorMessage("Email должен содержать от 6 до 30 символов!");
    }
    if (password.length < 6) {
      return setErrorMessage("Пароль должен содержать минимум 6 символов!");
    }
    if (password !== passwordRepeat) {
      return setErrorMessage("Пароли не совпадают!");
    }
    if (!validateEmail(email)) {
      return setErrorMessage("Введите корректную почту!");
    }
    if (!accepting) {
      return setErrorMessage(
        "Для регистрации Вы должны подтвердить принятие наших Условий и Политики использования данных"
      );
    }

    const { success, error } = await userModel.signUp(email, password);

    if (success) return setFormSended(true);
    if (!success) return setErrorMessage(error);
  }

  return (
    <MainTemplate>
      <div className="wrapper sign sign_up column justify-center items-center">
        <h1 className="text text-center">{formSended ? "Подтвердите почту" : "Регистрация"}</h1>

        {formSended ? (
          <p className="text text-center">
            Здравствуйте! Мы отправили на ваш e-mail:
            <br />
            <span className="text-blue">{email}</span> письмо. Проверьте свою почту, чтобы подтвердить аккаунт.
          </p>
        ) : (
          <React.Fragment>
            <TextFieldRounded
              className="grey-textfield"
              type="email"
              placeholder="E-mail"
              value={email}
              set={setEmail}
            />

            <TextFieldRounded
              className="grey-textfield"
              type="password"
              placeholder="Придумайте пароль"
              value={password}
              set={setPassword}
            />

            <TextFieldRounded
              className="grey-textfield"
              type="password"
              placeholder="Введите пароль еще раз"
              value={passwordRepeat}
              set={setPasswordRepeat}
            />

            <div className="flex acception">
              <input
                type="checkbox"
                id="acception"
                defaultChecked={accepting}
                onClick={() => setAccepting(!accepting)}
              />
              <label htmlFor="acception">
                Регистрируясь, вы принимаете <Link to="/privacy-policy">политику конфиденциальности, </Link>
                <Link to="/terms">условия публичной оферты</Link>
              </label>
            </div>

            {errorMessage && <div className="error">{errorMessage}</div>}

            <Button containerClassName="fullwidth-button-container" text="Зарегистрироваться" action={Reg} />
          </React.Fragment>
        )}
      </div>
    </MainTemplate>
  );
};
