export const routeNames = {
  main: "/",
  contacts: "/contacts",
  signIn: "/sign/in",
  signUp: "/sign/up",
  recoveryPassword: "/password/recovery",
  changePassword: "/password/change",
  emailConfirm: "/email/confirm",
  settings: "/settings",
  createUrl: "/url/create",
  adminCourses: "/admin/courses",
  adminCourseCreate: "/admin/courses/create",
  privacyPolicy: "/privacy-policy",
  terms: "/terms",
  tariff: "/tariff",
  subscriptions: "/subscriptions",
  balance: "/balance",
  sales: "/sales",
  salesTable: "/sales-table",
};
