import { routeNames, routeNamesWithParams } from "../shared/config";

import { AdminCourseFeedbacks } from "./admin-course-feedbacks";
import { AdminCourseSubscribers } from "./admin-course-subscribers";
import { AdminCourses } from "./admin-courses";
import { Articles } from "./articles";
import { Balance } from "./balance/balance";
import { CoursePay } from "./course-pay";
import { CreateArticle } from "./create-article";
import { CreateCourse } from "./create-course/create-course";
import { CreateUrl } from "./create-url/create-url";
import { Payment } from "./payment";
import { SalesTable } from "./sales-table/sales-table";
import { Sales } from "./sales/sales";
import { Settings } from "./settings";
import { Subscriptions } from "./subscriptions";

export const privateRoutes = [
  { path: routeNames.settings, Component: Settings },
  { path: routeNames.createUrl, Component: CreateUrl },
  { path: routeNames.adminCourses, Component: AdminCourses },
  { path: routeNames.adminCourseCreate, Component: CreateCourse },
  { path: routeNames.subscriptions, Component: Subscriptions },
  { path: routeNames.balance, Component: Balance },
  { path: routeNames.sales, Component: Sales },
  { path: routeNames.salesTable, Component: SalesTable },

  { path: routeNamesWithParams().coursePay, Component: CoursePay },
  { path: routeNamesWithParams().adminCoursePayment, Component: Payment },
  { path: routeNamesWithParams().adminCourseEdit, Component: CreateCourse },
  { path: routeNamesWithParams().adminCourseSubscribers, Component: AdminCourseSubscribers },
  { path: routeNamesWithParams().adminCourseFeedbacks, Component: AdminCourseFeedbacks },
  { path: routeNamesWithParams().adminCourseArticles, Component: Articles },
  { path: routeNamesWithParams().adminCourseArticleCreate, Component: CreateArticle },
  { path: routeNamesWithParams().adminCourseArticleEdit, Component: CreateArticle },
];
