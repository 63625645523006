import axios from "axios";
import { REACT_APP_API_URL } from "../config";
import cookie from "react-cookies";

export const apiInstance = axios.create({
  baseURL: REACT_APP_API_URL,
});

apiInstance.interceptors.request.use((config) => {
  const token = cookie.load("token");

  if (token) config.headers = { Authorization: `Bearer ${token}` };
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => console.log(error)
);
