import React from "react";
import "./CourseRow.scss";
import { ButtonLink } from "../Button";
import { routeNamesWithParams } from "../../shared/config";
import { Video } from "../../shared/ui/video/video";
import { Link } from "../../shared/ui/link/link";

export const CourseRow = ({ url, id, video, text, description }) => {
  return (
    <div className="courseRow">
      {/* {image && <div className="img" style={{ backgroundImage: `url( ${REACT_APP_API_URL}/${image} )` }} />} */}
      {video && <Video link={video} />}

      <div className="text">{text}</div>
      <p className="course-row-description">{description}</p>

      {/* TODO: сделать динамически фон и текст по условию */}
      {/* <Link style={{ background: "#67b12c" }} className="course-row-rounded-button" to="#">
        Бесплатно
      </Link> */}

      <div className="buttonRow">
        <ButtonLink link={routeNamesWithParams({ url, courseId: id }).course} text="Пройти Курс" />
        {/* <Link className="course-feedbacks-button" to={routeNamesWithParams({ url, courseId: id }).courseFeedbacks}>
          Смотреть отзывы
        </Link> */}
      </div>
    </div>
  );
};
