import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { salesArchiveModel } from "../../entities/sales/archive";
import { mySubscribersModel } from "../../entities/user/subscribers";
import Header from "../../components/Header";
import styles from "./styles.module.scss";
import { userModel } from "../../entities/user";
import { salesModel } from "../../entities/sales/sales";
import { Link } from "../../shared/ui/link/link";
import { routeNamesWithParams } from "../../shared/config";

export const SalesTable = observer(() => {
  useEffect(() => {
    salesModel.getAll();
    salesArchiveModel.getAll();
    mySubscribersModel.getAll();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Header />
      <br />

      <div className={styles.block}>
        <h2 className={styles.title}>Реквизиты для выплат:</h2>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headRow}>
              <th className={styles.cell}>Кто вы</th>
              <th className={styles.cell}>БИК</th>
              <th className={styles.cell}>КОР СЧЕТ</th>
              <th className={styles.cell}>ИНН</th>
              <th className={styles.cell}>ФИО</th>
              <th className={styles.cell}>НОМЕР СЧЕТА</th>
            </tr>
          </thead>

          <tbody>
            <tr className={styles.bodyRow}>
              <td className={styles.cell}>{userModel.user?.legalPerson}</td>
              <td className={styles.cell}>{userModel.user?.bic}</td>
              <td className={styles.cell}>{userModel.user?.correspondentAccount}</td>
              <td className={styles.cell}>{userModel.user?.tin}</td>
              <td className={styles.cell}>{userModel.user?.personFullName}</td>
              <td className={styles.cell}>{userModel.user?.accountNumber}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.block}>
        <h2 className={styles.title}>Статистика продаж на данный момент:</h2>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headRow}>
              <th className={styles.cell}>Вам пришло</th>
              <th className={styles.cell}>Наша комиссия</th>
              <th className={styles.cell}>Мы вам перевели</th>
              <th className={styles.cell}>Мы должны вам</th>
            </tr>
          </thead>

          <tbody>
            <tr className={styles.bodyRow}>
              <td className={styles.cell}>{salesModel.payin} руб.</td>
              <td className={styles.cell}>{salesModel.commission} руб.</td>
              <td className={styles.cell}>{salesModel.payout} руб.</td>
              <td className={styles.cell}>{salesModel.balance} руб.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.block}>
        <h2 className={styles.title}>Архив выплат:</h2>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headRow}>
              <th className={styles.cell}>Пришло</th>
              <th className={styles.cell}>Комиссия</th>
              <th className={styles.cell}>Выплатили</th>
              <th className={styles.cell}>Чек</th>
              <th className={styles.cell}>Дата выплаты</th>
            </tr>
          </thead>

          <tbody>
            {salesArchiveModel.sales.map((sale) => {
              return (
                <tr className={styles.bodyRow} key={sale.id}>
                  <td className={styles.cell}>{sale.payin}</td>
                  <td className={styles.cell}>{sale.commission}</td>
                  <td className={styles.cell}>{sale.amount}</td>
                  <td className={styles.cell}>{sale.check_url}</td>
                  <td className={styles.cell}>{new Date(sale.date).toLocaleString("en-US")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.block}>
        <h2 className={styles.title}>Подписчики:</h2>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headRow}>
              <th className={styles.cell}>Курс</th>
              <th className={styles.cell}>E-mail подписчика</th>
              <th className={styles.cell}>Подписка до</th>
              <th className={styles.cell}>Дата подписки</th>
              <th className={styles.cell}>Сумма</th>
              <th className={styles.cell}>Комиссия</th>
              <th className={styles.cell}>Сумма комиссии</th>
              <th className={styles.cell}>Должны</th>
              <th className={styles.cell}>Статус</th>
            </tr>
          </thead>

          <tbody>
            {mySubscribersModel.subscribers.map((subscriber) => {
              return (
                <tr className={styles.bodyRow} key={subscriber.id}>
                  <td className={styles.cell}>
                    <Link to={routeNamesWithParams({ url: subscriber.url, courseId: subscriber.course_id }).course}>
                      {subscriber.course_name}
                    </Link>
                  </td>
                  <td className={styles.cell}>{subscriber.subscriber_email}</td>
                  <td className={styles.cell}>{new Date(subscriber.subscribe_to).toLocaleString("en-US")}</td>
                  <td className={styles.cell}>{new Date(subscriber.subscribe_date).toLocaleString("en-US")}</td>
                  <td className={styles.cell}>{subscriber.summ}</td>
                  <td className={styles.cell}>{subscriber.commission}</td>
                  <td className={styles.cell}>{subscriber.summ_commission}</td>
                  <td className={styles.cell}>{subscriber.prepare_to_pay}</td>
                  <td className={styles.cell}>{subscriber.payment_status ? "Выплачено" : "Не выплачено"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});
