import React, { useState, useEffect } from "react";

import { Button } from "../../components/Button";
import TextFieldRounded from "../../components/TextFieldRounded";
import { userModel } from "../../entities/user";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

import "../auth-styles/sign.scss";

export const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [errorMessage, setErrorMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    errorMessage.length > 0 && setErrorMsg("");
  }, [oldPassword, newPassword, passwordRepeat]);

  async function change() {
    if (oldPassword.length === 0) {
      setErrorMsg("Введите старый пароль");
      return false;
    }
    if (newPassword !== passwordRepeat) {
      setErrorMsg("Пароли не совпадают");
      return false;
    }

    const { success, message } = await userModel.changePassword(oldPassword, newPassword);

    if (success) return setSuccessMessage(message);
    if (!success) return setErrorMsg(message);
  }

  return (
    <MainTemplate>
      <div className="wrapper sign sign_up column justify-center items-center">
        <h1 className="text">Изменить пароль</h1>

        <TextFieldRounded
          className="grey-textfield"
          type="password"
          placeholder="Введите старый пароль"
          value={oldPassword}
          set={setOldPassword}
        />

        <TextFieldRounded
          className="grey-textfield"
          type="password"
          placeholder="Придумайте новый пароль"
          value={newPassword}
          set={setNewPassword}
        />

        <TextFieldRounded
          className="grey-textfield"
          type="password"
          placeholder="Введите новый пароль еще раз"
          value={passwordRepeat}
          set={setPasswordRepeat}
        />

        {errorMessage && <div className="error">{errorMessage}</div>}
        {successMessage && <div className="success">{successMessage}</div>}

        <Button containerClassName="fullwidth-button-container" text="Изменить пароль" action={change} />
      </div>
    </MainTemplate>
  );
};
