import React from "react";
import { Link } from "react-router-dom";
import "./ButtonText.scss";

export const ButtonText = ({ action, text = "OK" }) => (
  <p className="buttonText_text" onClick={action}>
    {text}
  </p>
);

export const ButtonTextLink = ({ link = "", text = "OK" }) => (
  <Link to={link}>
    <p className="buttonText_text">{text}</p>
  </Link>
);
