import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "./icons/logo.svg";
import { userModel } from "../../entities/user";
import { observer } from "mobx-react-lite";
import { REACT_APP_API_URL, routeNames, routeNamesWithParams } from "../../shared/config";

import mockAvatarSource from "./mock-avatar.svg";

import "./header.scss";
import { debounce, isObject } from "lodash";
import { coursesSearchModel } from "../../entities/courses/search";

const Menu = observer(({ touchMenu }) => {
  const navigate = useNavigate();

  return (
    <div className="header_menu">
      <Link className="profile-link" to={routeNamesWithParams({ id: userModel.user?.id }).myProfile}>
        <img
          className="profile-link__image"
          src={
            isObject(userModel.user?.avatar)
              ? URL.createObjectURL(userModel.user?.avatar)
              : typeof userModel.user?.avatar === "string" && userModel.user?.avatar
                ? `${REACT_APP_API_URL}${userModel.user?.avatar}`
                : mockAvatarSource
          }
          alt=""
        />
        Мой профиль
      </Link>

      {/* <MenuElement
        icon="play"
        text="Мои курсы"
        action={() =>
          touchMenu(() => {
            if (userModel.user?.url) return navigate(routeNames.adminCourses);

            return navigate(routeNames.createUrl);
          })
        }
      /> */}

      <MenuElement
        icon="subscriptions"
        text="Мои подписки"
        action={() => touchMenu(() => navigate(routeNames.subscriptions))}
      />

      <MenuElement icon="rub" text="Статистика продаж" action={() => touchMenu(() => navigate(routeNames.sales))} />

      <MenuElement icon="settings" text="Настройки" action={() => touchMenu(() => navigate(routeNames.settings))} />

      <MenuElement
        icon="arrow"
        text="Выйти"
        action={() => {
          userModel.signOut();

          navigate(0);
        }}
      />
    </div>
  );
});

const Header = observer(() => {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function touchMenu(action) {
    setShowMenu(false);
    action();
  }

  const debouncedSearch = useRef(
    debounce(async (search) => {
      coursesSearchModel.search(search);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <header className="flex justify-between items-center">
      <div style={{ display: "flex", width: "100%" }}>
        <Link to={routeNames.main}>
          <img src={logo} alt="" />
        </Link>

        {pathname === "/" && (
          <div className="search">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.7688 14.6976L13.4582 11.3876C13.3088 11.2382 13.1062 11.1552 12.8937 11.1552H12.3525C13.2689 9.9832 13.8135 8.50913 13.8135 6.90558C13.8135 3.09091 10.7221 0 6.90676 0C3.09144 0 0 3.09091 0 6.90558C0 10.7202 3.09144 13.8112 6.90676 13.8112C8.51058 13.8112 9.98491 13.2667 11.1571 12.3504V12.8915C11.1571 13.104 11.2401 13.3065 11.3895 13.4559L14.7001 16.7659C15.0122 17.078 15.517 17.078 15.8258 16.7659L16.7655 15.8264C17.0776 15.5143 17.0776 15.0097 16.7688 14.6976ZM6.90676 11.1552C4.55912 11.1552 2.65644 9.25613 2.65644 6.90558C2.65644 4.55834 4.5558 2.65599 6.90676 2.65599C9.25439 2.65599 11.1571 4.55502 11.1571 6.90558C11.1571 9.25281 9.25771 11.1552 6.90676 11.1552Z"
                fill="#CECECE"
              />
            </svg>

            <input
              className="search-input"
              value={coursesSearchModel.searchValue}
              onChange={(event) => {
                const { value } = event.currentTarget;

                coursesSearchModel.setSearchValue(value);
                debouncedSearch(value);
              }}
              placeholder="Искать курс..."
              type="text"
            />
          </div>
        )}
      </div>

      {/* {userModel.token && (
        <Link to={routeNames.balance} className="wallet">
          <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.334 8.66699C11.334 8.93221 11.2286 9.18656 11.0411 9.3741C10.8536 9.56164 10.5992 9.66699 10.334 9.66699C10.0688 9.66699 9.81441 9.56164 9.62688 9.3741C9.43934 9.18656 9.33398 8.93221 9.33398 8.66699C9.33398 8.40178 9.43934 8.14742 9.62688 7.95989C9.81441 7.77235 10.0688 7.66699 10.334 7.66699C10.5992 7.66699 10.8536 7.77235 11.0411 7.95989C11.2286 8.14742 11.334 8.40178 11.334 8.66699V8.66699Z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.20867 3.81868L10.542 1.72334C10.7901 1.65245 11.0512 1.64011 11.3048 1.68729C11.5585 1.73448 11.7977 1.83991 12.0037 1.99527C12.2096 2.15064 12.3767 2.3517 12.4918 2.58262C12.6068 2.81354 12.6667 3.06802 12.6667 3.32601V4.00001C13.0203 4.00001 13.3594 4.14049 13.6095 4.39054C13.8595 4.64058 14 4.97972 14 5.33334V12C14 12.3536 13.8595 12.6928 13.6095 12.9428C13.3594 13.1929 13.0203 13.3333 12.6667 13.3333H3.33333C2.97971 13.3333 2.64057 13.1929 2.39052 12.9428C2.14048 12.6928 2 12.3536 2 12V5.33334C2 4.63668 2.55667 4.00468 3.20867 3.81868ZM10.9087 3.00534C10.9582 2.99123 11.0104 2.98879 11.061 2.99822C11.1117 3.00765 11.1595 3.0287 11.2006 3.05971C11.2418 3.09071 11.2752 3.13084 11.2982 3.17693C11.3212 3.22302 11.3332 3.27382 11.3333 3.32534V4.00001H7.42667L10.9087 3.00534ZM3.33333 5.33334H12.6667V12H3.33333V5.33334Z"
              fill="currentColor"
            />
          </svg>
          <span className="wallet-balance">{userModel.user?.balance}</span>
        </Link>
      )} */}

      <div
        className="menu"
        onClick={() => (userModel.token ? setShowMenu((prev) => !prev) : navigate(routeNames.signIn))}
      >
        {userModel.token ? "МЕНЮ" : "ВОЙТИ"}
      </div>
      {showMenu && <Menu touchMenu={touchMenu} />}
    </header>
  );
});

export const MenuElement = ({ text, icon, action }) => (
  <div className="header_menu__element flex items-center" onClick={action}>
    <div className={`header_menu__element__icon header_menu__element__icon--${icon}`} />
    <div className="header_menu__element__text">{text}</div>
  </div>
);

export default Header;
