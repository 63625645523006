import { makeAutoObservable, runInAction } from "mobx";
import { CoursesService } from "../../shared/api/courses";

class FeedbacksModel {
  feedbacks = [];
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getAll(courseId) {
    const { data } = await CoursesService.fetchFeedbacks(courseId);

    if (!data) return;

    runInAction(() => {
      this.feedbacks = data.feedbacks;
      this.isLoaded = true;
    });
  }
}

export const feedbacksModel = new FeedbacksModel();
