import React, { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import { Button } from "../../components/Button";
import TextField, { TextArea } from "../../components/TextField";
import InputRadio from "../../components/InputRadio";
import { courseModel } from "../../entities/course";
import { observer } from "mobx-react-lite";
import { routeNames } from "../../shared/config";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { Video } from "../../shared/ui/video/video";

export const CreateCourse = observer(() => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const isEditPage = Boolean(courseId);

  useEffect(() => {
    if (courseId) courseModel.get(courseId);
    else courseModel.reset();
  }, [courseId]);

  const saveCourse = () => {
    if (courseModel.course.name.length === 0) {
      alert("Название не может быть пустым!");
      return;
    }

    if (
      courseModel.course.video !== "" &&
      !courseModel.course.video.includes("www.youtube.com/watch?v=") &&
      !courseModel.course.video.includes("kinescope.io/") &&
      !courseModel.course.video.includes("youtu.be/")
    ) {
      alert("Вставьте пожалуйста корректную ссылку из Youtube и Kinescope");
      return;
    }

    if (courseId) {
      courseModel.edit().then(({ success }) => success && navigate(routeNames.adminCourses));
    } else {
      courseModel.add().then(({ success }) => success && navigate(routeNames.adminCourses));
    }
  };

  // function deleteCourse() {
  //   const isConfirmed = window.confirm(
  //     "Вы точно хотите удалить курс и все вложенные в него статьи?! Это действие нельзя отменить!"
  //   );

  //   if (isConfirmed) courseModel.remove(courseId).then(({ success }) => success && navigate(routeNames.adminCourses));
  // }

  return (
    <MainTemplate>
      <div className="navbar row items-center">
        <Link to={routeNames.adminCourses} className="buttonText_text">
          Курсы/
        </Link>
        <p>Статьи</p>
      </div>
      <TextField
        isShowSymbols
        title="Название курса:"
        value={courseModel.course.name}
        set={(newValue) => courseModel.changeField("name", newValue)}
      />
      <TextArea
        isShowSymbols
        title="Краткое описание курса:"
        value={courseModel.course.description}
        set={(newValue) => courseModel.changeField("description", newValue)}
      />
      <TextField
        // helpButtonProps={{
        //   popupContent: (
        //     <>
        //       Как выглядит и зачем нужен "Видео обзор вашего курса":
        //       <Video link="https://youtu.be/HRQFp5wVZEE" />
        //       <br />
        //       Как правильно добавить ссылку с Youtube:
        //       <Video link="https://youtu.be/F9lUT4z5RRI" />
        //       <br />
        //       Как защитить видео с помощью Kinescope:
        //       <Video link="https://youtu.be/edhYAGVKn80" />
        //     </>
        //   ),
        // }}
        title="Видео краткого обзора на ваш курс (ссылка с Youtube, Kinescope):"
        value={courseModel.course.video}
        set={(newValue) => courseModel.changeField("video", newValue)}
      />
      {/* <ImageUploader
        existingImageUrl={typeof courseModel.course.image === "object" ? "" : courseModel.course.image}
        onImageDelete={() => {
          if (isEditPage) {
            courseModel.removeImage(courseId, "image");
            courseModel.changeField("image", "");
          }
        }}
        getImageOnLoad={(newCourseImage) => courseModel.changeField("image", newCourseImage)}
        title="Картинка курса (до 3 МБ. 566x350)"
      /> */}
      {!isEditPage && (
        <InputRadio
          title="СТАТУС КУРСА"
          values={[
            "Открытый курс с возможностью добавления платных статьей",
            // "Ничего не подключать, я лично буду открывать доступ к курсу.",
            "",
            "Закрытый курс, для внутреннего использования",
          ]}
          // videoLinks={[
          //   "https://www.youtube.com/watch?v=8_DazhgI57w",
          //   "",
          //   "https://www.youtube.com/watch?v=8_DazhgI57y",
          // ]}
          selected={courseModel.course.paymentType}
          set={(newValue) => courseModel.changeField("paymentType", newValue)}
        />
      )}
      {courseModel.course.paymentType === 1 && (
        <InputRadio
          // helpPopupContent={
          //   <>
          //     <h3>Для чего нужно отображение курса:</h3>
          //     <Video link="https://youtu.be/w_yM2oFwi7w" />
          //   </>
          // }
          title="ОТОБРАЖЕНИЕ КУРСА"
          values={["Показать", "Скрыть"]}
          selected={parseInt(courseModel.course.courseVisibleType)}
          set={(newValue) => courseModel.changeField("courseVisibleType", newValue)}
        />
      )}
      <br />

      {courseModel.course.paymentType === 2 && (
        <TextArea
          title="Как с вами связаться тем, кто хочет купить подписку?"
          value={courseModel.course.contactsInfo}
          set={(newValue) => courseModel.changeField("contactsInfo", newValue)}
        />
      )}
      <div className="row">
        <Button text="Сохранить" action={saveCourse} />
        {/* {courseId && <Button text="Удалить" action={deleteCourse} />} */}
      </div>
    </MainTemplate>
  );
});
