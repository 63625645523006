import React, { useState } from "react";
import { courseReviewsModel } from "../../entities/course/reviews";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { Feedback } from "./feedback/feedback";

import TextField, { TextArea } from "../../components/TextField";
import { Button } from "../../components/Button";

import styles from "./styles.module.scss";
import { observer } from "mobx-react-lite";
import { articlesModel } from "../../entities/articles";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { courseMyReviewModel } from "../../entities/course/my-review";
import { userModel } from "../../entities/user";

export const CourseFeedbacks = observer(() => {
  const [feedbackText, setFeedbackText] = useState("");
  const [isShowForm, setIsShowForm] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const { url, courseId } = useParams();

  const getReviewsData = async () => {
    courseReviewsModel.getAllReviews(courseId);
    const { review } = await courseMyReviewModel.getMyReview(courseId);

    if (review) setIsShowForm(false);
  };

  const sendFeedback = async (event) => {
    event.preventDefault();

    if (!userModel.user.fullName) return;

    if (isEditMode) {
      const { error } = await courseReviewsModel.editReview(courseId, userModel.user.fullName, feedbackText);

      if (error) return alert(error);
    } else {
      const { error } = await courseReviewsModel.addReview(courseId, userModel.user.fullName, feedbackText);

      if (error) return alert(error);
    }

    getReviewsData();
    alert("Отзыв успешно отправлен!");
  };

  const deleteMyReview = async () => {
    const { error } = await courseMyReviewModel.deleteMyReview(courseId);

    if (error) return alert(error);

    getReviewsData();
    setIsEditMode(false);
    alert("Отзыв успешно удален!");
  };

  useEffect(() => {
    getReviewsData();
    articlesModel.getAll(url, courseId);
  }, [courseId, url]);

  return (
    <MainTemplate>
      <p className={styles.description}>Отзыв могут оставить только те, кто купил платную подписку на этот курс.</p>

      {courseMyReviewModel.review && !isShowForm && (
        <Button
          action={() => {
            setFeedbackText(courseMyReviewModel.review.text);
            setIsShowForm(true);
            setIsEditMode(true);
          }}
          text="Редактировать отзыв"
        />
      )}

      {articlesModel.course?.isBuyed && isShowForm && (
        <form onSubmit={sendFeedback}>
          <TextField
            title="Ваше Имя:"
            value={userModel.user?.fullName ?? ""}
            set={(newValue) => userModel.changeUserField("fullName", newValue)}
          />

          <TextArea title="Ваше отзыв:" value={feedbackText} set={setFeedbackText} />

          <div className="row">
            <Button text="Отправить" type="submit" />
            {isEditMode && <Button action={deleteMyReview} text="Удалить" type="button" />}
          </div>
        </form>
      )}

      {courseReviewsModel.reviews.length ? (
        courseReviewsModel.reviews.map(({ id, userId, fullName, avatar, publishDate, text, isEditable }) => {
          return (
            <Feedback
              key={id}
              userId={userId}
              fullName={fullName}
              avatarUrl={avatar}
              publishDate={publishDate}
              text={text}
              isEditable={isEditable}
            />
          );
        })
      ) : (
        <div className={styles.noFeedbacks}>К этому курсу еще нет отзывов.</div>
      )}
    </MainTemplate>
  );
});
