import clsx from "clsx";
import React from "react";
import Header from "../../../components/Header";
import { Container } from "../container/container";

import styles from "./main-template.module.scss";

export const MainTemplate = ({ children, title, titleMarginBottom }) => {
  return (
    <Container>
      <Header />
      <div className={styles.content}>
        {title && <h1 className={clsx(styles.title, titleMarginBottom && styles.titleMarginBottom)}>{title}</h1>}
        {children}
      </div>
    </Container>
  );
};
