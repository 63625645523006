import { routeNames, routeNamesWithParams } from "../shared/config";

import { ChangePassword } from "./change-password/change-password";
import { EmailConfirm } from "./email-confirm/email-confirm";
import { Main } from "./main/main";
import { RecoveryPassword } from "./recovery-password/recovery-password";
import { Tariff } from "./tariff/tariff";
import { Privacy } from "./terms/privacy";
import { Terms } from "./terms/terms";
import { SignIn } from "./sign-in/sign-in";
import { SignUp } from "./sign-up/sign-up";
import { Courses } from "./courses";
import { Course } from "./course";
import { Article } from "./article";
import { Contacts } from "./contacts";
import { CourseFeedbacks } from "./course-feedbacks/course-feedbacks";
import { MyProfile } from "./my-profile/my-profile";
import { Profile } from "./profile/profile";

export const publicRoutes = [
  { path: routeNames.main, Component: Main },
  { path: routeNames.contacts, Component: Contacts },
  { path: routeNames.signIn, Component: SignIn },
  { path: routeNames.signUp, Component: SignUp },
  { path: routeNames.recoveryPassword, Component: RecoveryPassword },
  { path: routeNames.changePassword, Component: ChangePassword },
  { path: routeNames.emailConfirm, Component: EmailConfirm },
  { path: routeNames.privacyPolicy, Component: Privacy },
  { path: routeNames.terms, Component: Terms },
  { path: routeNames.tariff, Component: Tariff },

  { path: routeNamesWithParams().courses, Component: Courses },
  { path: routeNamesWithParams().course, Component: Course },
  { path: routeNamesWithParams().article, Component: Article },
  { path: routeNamesWithParams().courseFeedbacks, Component: CourseFeedbacks },
  { path: routeNamesWithParams().profile, Component: Profile },
  { path: routeNamesWithParams().myProfile, Component: MyProfile },
];
