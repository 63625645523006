import { apiInstance } from "../api";

export class ArticlesService {
  static fetchAll(url, courseId) {
    return apiInstance.post("/articles/all.php", { url, courseId });
  }

  static fetchOne(courseId, articleId, url) {
    return apiInstance.post("/articles/get.php", { courseId, articleId, url });
  }

  static add(formData) {
    return apiInstance.post("/articles/add.php", formData, { headers: { "Content-Type": "multipart/form-data" } });
  }

  static edit(formData) {
    return apiInstance.post("/articles/edit.php", formData, { headers: { "Content-Type": "multipart/form-data" } });
  }

  static remove(courseId, articleId) {
    return apiInstance.post("/articles/remove.php", { courseId, articleId });
  }

  static sort(courseId, sort) {
    return apiInstance.post("/articles/sort.php", { courseId, sort });
  }

  static addFeedback(courseId, articleId, message) {
    return apiInstance.post("/articles/feedback.php", { courseId, articleId, message });
  }

  static removeImage(courseId, articleId, fileType) {
    return apiInstance.post("/articles/remove.file.php", { courseId, articleId, fileType });
  }

  static completeArticle(articleId) {
    return apiInstance.post("/articles/complete.article.php", { articleId });
  }
}
