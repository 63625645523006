import { makeAutoObservable, runInAction } from "mobx";
import { CoursesService } from "../../shared/api/courses";

class CourseReviewsModel {
  reviews = [];
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getAllReviews(courseId) {
    this.isLoading = true;
    const { data } = await CoursesService.fetchAllReviews(courseId);

    if (!data) return;

    runInAction(() => {
      this.reviews = data.reviews;
      this.isLoading = false;
    });

    return data;
  }

  async addReview(courseId, fullName, text) {
    const { data } = await CoursesService.addReview(courseId, fullName, text);

    return data;
  }

  async editReview(courseId, fullName, text) {
    const { data } = await CoursesService.editReview(courseId, fullName, text);

    return data;
  }
}

export const courseReviewsModel = new CourseReviewsModel();
