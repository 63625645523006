export const convertToEmbeddedVideo = (url) => {
  if (url.includes("youtube")) {
    const videoId = url.split("v=").pop();
    return `https://www.youtube.com/embed/${videoId}`;
  }

  if (url.includes("youtu.be")) {
    const videoId = url.split("/").pop();
    return `https://www.youtube.com/embed/${videoId}`;
  }

  if (url.includes("kinescope")) {
    const videoId = url.split("/").pop();
    return `https://kinescope.io/embed/${videoId}`;
  }
};
