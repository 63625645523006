import React, { useState } from "react";
import clsx from "clsx";

import styles from "./help-button.module.scss";
import { Popup } from "../popup/popup";

export const HelpButton = ({ popupContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button className={clsx(styles.button)} onClick={() => setIsOpen(true)}>
        <svg width="18" height="18" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="6.5" cy="6.5" r="6" fill="white" stroke="#B0B0B0" />
          <path
            d="M6.78809 7.99805H5.87988C5.88314 7.68555 5.91081 7.43001 5.96289 7.23145C6.01823 7.02962 6.10775 6.8457 6.23145 6.67969C6.35514 6.51367 6.51953 6.32487 6.72461 6.11328C6.87435 5.96029 7.01107 5.81706 7.13477 5.68359C7.26172 5.54688 7.36426 5.40039 7.44238 5.24414C7.52051 5.08464 7.55957 4.89421 7.55957 4.67285C7.55957 4.44824 7.51888 4.25456 7.4375 4.0918C7.35938 3.92904 7.24219 3.80371 7.08594 3.71582C6.93294 3.62793 6.74251 3.58398 6.51465 3.58398C6.32585 3.58398 6.14681 3.61816 5.97754 3.68652C5.80827 3.75488 5.67155 3.86068 5.56738 4.00391C5.46322 4.14388 5.40951 4.3278 5.40625 4.55566H4.50293C4.50944 4.18783 4.60059 3.87207 4.77637 3.6084C4.9554 3.34473 5.19629 3.1429 5.49902 3.00293C5.80176 2.86296 6.1403 2.79297 6.51465 2.79297C6.92806 2.79297 7.27962 2.86784 7.56934 3.01758C7.8623 3.16732 8.08529 3.38216 8.23828 3.66211C8.39128 3.9388 8.46777 4.26758 8.46777 4.64844C8.46777 4.94141 8.40755 5.21159 8.28711 5.45898C8.16992 5.70312 8.01855 5.93262 7.83301 6.14746C7.64746 6.3623 7.45052 6.56738 7.24219 6.7627C7.06315 6.92871 6.94271 7.11589 6.88086 7.32422C6.81901 7.53255 6.78809 7.75716 6.78809 7.99805ZM5.84082 9.5459C5.84082 9.39941 5.88639 9.27572 5.97754 9.1748C6.06868 9.07389 6.20052 9.02344 6.37305 9.02344C6.54883 9.02344 6.68229 9.07389 6.77344 9.1748C6.86458 9.27572 6.91016 9.39941 6.91016 9.5459C6.91016 9.68587 6.86458 9.80632 6.77344 9.90723C6.68229 10.0081 6.54883 10.0586 6.37305 10.0586C6.20052 10.0586 6.06868 10.0081 5.97754 9.90723C5.88639 9.80632 5.84082 9.68587 5.84082 9.5459Z"
            fill="#B0B0B0"
          />
        </svg>
      </button>
      <Popup isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {popupContent}
      </Popup>
    </>
  );
};
