import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import TextField from "../../components/TextField";
import { courseModel } from "../../entities/course";
import { routeNames, routeNamesWithParams } from "../../shared/config";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const Payment = observer(() => {
  const { courseId } = useParams();
  const [isShowForm, setShowForm] = useState(true);
  const [priceMonthError, setPriceMonthError] = useState("");
  const [priceSixMonthsError, setPriceSixMonthsError] = useState("");
  const [priceUnlimitedError, setPriceUnlimitedError] = useState("");

  useEffect(() => {
    courseModel.get(courseId).then(({ priceMonth }) => priceMonth && setShowForm(false));
  }, [courseId]);

  const save = () => {
    const min = 100;

    if (courseModel.course.priceMonth < min) {
      return setPriceMonthError(`Цена подписки не может быть меньше ${min} кредитов`);
    }

    if (courseModel.course.priceSixMonths < min && courseModel.course.priceSixMonths !== "") {
      return setPriceSixMonthsError(`Цена подписки не может быть меньше ${min} кредитов`);
    }

    if (courseModel.course.priceUnlimited < min && courseModel.course.priceUnlimited !== "") {
      return setPriceUnlimitedError(`Цена подписки не может быть меньше ${min} кредитов`);
    }

    courseModel.edit();
    setPriceMonthError(false);
    setPriceSixMonthsError(false);
    setPriceUnlimitedError(false);
    setShowForm(false);
  };

  const edit = () => {
    setShowForm(true);
  };

  return (
    <MainTemplate>
      <div className="navbar row items-center">
        <Link to={routeNames.adminCourses} className="text-blue text">
          Курсы/
        </Link>
        <Link to={routeNamesWithParams({ courseId }).adminCourseArticles} className="text-blue text">
          Статьи
        </Link>
      </div>
      <h1 style={{ fontSize: 20, fontWeight: 500 }}>{courseModel.course?.name}</h1>
      <p>{courseModel.course?.description}</p>
      <br />
      <p>
        После того как пользователь подпишется на курс, все закрытые статьи курса станут открытыми, даже те статьи,
        которые вы добавите после этого.
      </p>

      <br />
      {isShowForm ? (
        <>
          <TextField
            maxSymbolsCount={6}
            title="Подписка на месяц в рублях:"
            value={courseModel.course.priceMonth}
            set={(newValue) => courseModel.changeField("priceMonth", newValue)}
            type="number"
            error={priceMonthError}
          />
          <TextField
            maxSymbolsCount={6}
            title="Подписка на полгода в рублях:"
            value={courseModel.course.priceSixMonths}
            set={(newValue) => courseModel.changeField("priceSixMonths", newValue)}
            type="number"
            error={priceSixMonthsError}
          />
          <TextField
            maxSymbolsCount={6}
            title="Подписка на год в рублях:"
            value={courseModel.course.priceUnlimited}
            set={(newValue) => courseModel.changeField("priceUnlimited", newValue)}
            type="number"
            error={priceUnlimitedError}
          />
          <Button text="Сохранить" action={save} />
        </>
      ) : (
        <>
          <span>Подписка на месяц: {courseModel.course.priceMonth} руб.</span>
          {courseModel.course.priceSixMonths && (
            <span>Подписка на полгода: {courseModel.course.priceSixMonths} руб.</span>
          )}
          {courseModel.course.priceUnlimited && <span>Подписка на год: {courseModel.course.priceUnlimited} руб.</span>}
          <Button text="Редактировать" action={edit} />
        </>
      )}
    </MainTemplate>
  );
});
