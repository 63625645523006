import { makeAutoObservable, runInAction } from "mobx";
import { AuthService } from "../../shared/api/auth";

class MySubscribersModel {
  subscribers = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    const { data } = await AuthService.fetchSubscribers();

    if (!data) return;

    runInAction(() => {
      this.subscribers = data.data;
    });
  }
}

export const mySubscribersModel = new MySubscribersModel();
