import { makeAutoObservable, runInAction } from "mobx";
import { AuthService } from "../../shared/api/auth";

class MySubscriptionsModel {
  subscriptions = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    const { data } = await AuthService.fetchMySubscriptions();

    if (!data) return;

    runInAction(() => {
      this.subscriptions = data.data;
    });
  }
}

export const mySubscriptionsModel = new MySubscriptionsModel();
