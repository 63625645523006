import { observer } from "mobx-react-lite";
import cookie from "react-cookies";
import { routeNames } from "../../shared/config";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const ProtectedRoutes = observer(() => {
  const location = useLocation();
  const token = cookie.load("token");

  return token ? <Outlet /> : <Navigate to={routeNames.signIn} replace state={{ from: location }} />;
});
