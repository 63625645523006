import React from "react";

import "./styles.scss";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { Link } from "../../shared/ui/link/link";
import { routeNames } from "../../shared/config";

export const Tariff = () => {
  return (
    <MainTemplate>
      <div className="sign">
        <h1 className="text text-center">Тарифы</h1>
      </div>

      <p>
        Автор может создать неограниченное количество открытых или закрытых курсов (доступ к закрытым курсам автор
        открывает лично). Это мы предоставляем бесплатно.
        <br />
        <br />
        Если автор создает платный курс, наша комиссия составляет 25% с продаж.
        <br />
        Например, автор создал курс с платной подпиской на месяц стоимостью 1000 рублей.
        <br />
        4% от этой суммы берет агрегатор
        <br />
        7% - оплата налога государству с индивидуального предпринимателя (ИП Горшков)
        <br />
        14% - перечисляется (ИП Горшков), которая идет на развитие данного проекта.
        <br />
        75% - ИП Горшков перечисляет вам на счет. (подробная информация в режиме реального времени
        отображается на вашей странице в <Link to={routeNames.sales}>статистике продаж</Link>)
        <br />
        <br />
        Получается с 1000 рублей, вам поступит на счет 750 рублей. <br />
        Если курс не продается - вы ничего не платите.
        <br />
        <br />
        Для тех авторов, которые не хотят платить процент с продаж - мы можем предложить абонентскую плату -
        индивидуальный подход к каждому.
        <br />
        <br />
        Если вы хотите собственное приложение с вашими курсами, оно будет стоить 120 000 руб. В эту сумму входит
        разработка приложения на ios и android + размещение в appstore и google play.
        <br />
        <br />
        Для тех, кому нужен курс под ключ, разработка курса, съемки, монтаж, курирование курса, уникальное приложение
        или дополнительный функционал - у нас к каждому клиенту индивидуальный подход. Для этого достаточно связаться с
        нами и рассказать о том, что вы хотите сделать.
        <br />
        <br /> С уважением, команда DirectPro.
        <br />
        <br />
        <br />
      </p>
    </MainTemplate>
  );
};
