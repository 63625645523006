import { makeAutoObservable, runInAction } from "mobx";
import { CoursesService } from "../../shared/api/courses";

class CoursesSearchModel {
  courses = [];
  searchValue = "";
  isLoaded = false;
  error = false;

  constructor() {
    makeAutoObservable(this);
  }

  async search(searchValue) {
    const { data } = await CoursesService.search(searchValue);

    if (data.error) {
      return runInAction(() => (this.error = data.error));
    }

    runInAction(() => {
      this.courses = data.courses;
      this.error = false;
      this.isLoaded = true;
    });
  }

  setSearchValue(value) {
    this.searchValue = value;
  }
}

export const coursesSearchModel = new CoursesSearchModel();
