import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

import { CourseRow } from "../../components/CourseRow";

import { coursesModel } from "../../entities/courses/courses";
import { observer } from "mobx-react-lite";

export const Courses = observer(() => {
  const { url } = useParams();

  useEffect(() => {
    coursesModel.getAll(url);
  }, [url]);

  return (
    <MainTemplate>
      <div className="column" style={{ marginTop: "unset" }}>
        {coursesModel.isLoaded ? (
          coursesModel.courses?.length > 0 ? (
            coursesModel.courses.map((course) => (
              <CourseRow
                key={course.id}
                id={course.id}
                url={url}
                text={course.name}
                image={course.image}
                video={course.video}
                description={course.description}
              />
            ))
          ) : (
            <p>Пока нет ни одного курса</p>
          )
        ) : (
          <p>Список курсов загружается...</p>
        )}
      </div>
    </MainTemplate>
  );
});
