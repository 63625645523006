import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import TextField from "../../components/TextField";
import { userModel } from "../../entities/user";
import { UrlService } from "../../shared/api/url";
import { REACT_APP_SELF_URL, routeNames } from "../../shared/config";
import { convertToEmbeddedVideo } from "../../shared/lib/video";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const CreateUrl = observer(() => {
  const [url, setUrl] = useState("");
  const [isFree, setIsFree] = useState(true);

  const navigate = useNavigate();

  function handleSetUrl(value) {
    let _value = value;

    _value = _value.replace(/[^a-z-0-9]/g, "");

    setIsFree(true);

    setUrl(_value);

    const values = Object.values(routeNames);
    values.forEach((item) => {
      if (item.split("/")[1] === _value && _value !== "") return setIsFree(false);
    });

    value !== "" && UrlService.check(_value).then(({ data }) => !data.success && setIsFree(false));
  }

  function savePageUrl() {
    UrlService.save(url).then(({ data }) => {
      if (data.success) {
        userModel.check();
        navigate(routeNames.adminCourses);
        return;
      }

      alert(data?.error);
    });
  }

  if (userModel.user?.url) return null;

  return (
    <MainTemplate>
      <div className="column">
        <div className="navbar row items-center">
          <p style={{ fontWeight: 700, fontSize: 16, lineHeight: "20px" }} className="text">
            Мои курсы
          </p>
        </div>
        <p style={{ marginTop: -12, marginBottom: 8 }}>
          Введите адресс страницы на которой пользователи увидят все ваши курсы:
        </p>
        <p
          style={{
            color: isFree ? "#479BFF" : "red",
            fontWeight: 700,
            fontSize: 15,
            lineHeight: "20px",
            marginBottom: -17,
          }}
        >
          {REACT_APP_SELF_URL}/{url}
          {!isFree && <span className="error"> уже кем то занят</span>}
        </p>
        <TextField isShowSymbols maxSymbolsCount={30} value={url} set={handleSetUrl} color={isFree ? "black" : "red"} />
        <p style={{ marginTop: 4 }}>Можно вводить маленькие латинские буквы, цифры и тире.</p>
        <p style={{ marginTop: 4 }}>
          ВАЖНО! Этот адрес после сохранения нельзя будет изменить, также нельзя будет создать еще один такой адрес.
          Будьте очень внимательны!
        </p>
        <Button text="Сохранить" action={savePageUrl} disabled={url === "" || !isFree} />
        {/* <span style={{ fontSize: 18, marginBottom: 4 }}>Подсказка:</span>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 0,
            paddingBottom: "56.25%",
            backgroundColor: "#000000",
            cursor: "pointer",
            marginBottom: 10,
          }}
        >
          <iframe
            style={{ position: "absolute", height: "100%", width: "100%", top: "0", left: "0" }}
            src={convertToEmbeddedVideo("https://youtu.be/jKEjT_Kzq2w")}
            title="Video player"
            allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div> */}
      </div>
    </MainTemplate>
  );
});
