import { makeAutoObservable, runInAction } from "mobx";
import { AuthService } from "../../shared/api/auth";

class ProfileModel {
  user = null;

  constructor() {
    makeAutoObservable(this);
  }

  async getById(id) {
    const { data } = await AuthService.fetchUserData(id);

    if (!data) return;

    runInAction(() => {
      this.user = data.userdata;
    });
  }

  async saveProfile(avatar, fullName, aboutMe) {
    const formData = new FormData();

    formData.append("avatar", avatar);
    formData.append("fullName", fullName);
    formData.append("aboutMe", aboutMe);

    const { data } = await AuthService.saveProfile(formData);

    return data;
  }
}

export const profileModel = new ProfileModel();
