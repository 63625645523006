import React, { useEffect, useState } from "react";

import "../auth-styles/sign.scss";
import { userModel } from "../../entities/user";
import { routeNames } from "../../shared/config";
import { useLocation, useNavigate } from "react-router-dom";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const EmailConfirm = () => {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const hash = location.search.split("hash=")[1];

      if (hash) {
        const { success } = await userModel.confirmEmail(hash);

        if (success) return navigate(routeNames.main);

        setLoading(false);
      } else {
        console.error("Подтвердить адрес электронной почты не удалось - отсутствует хэш подтверждения");
      }
    })();
  }, [location.search, navigate]);

  if (isLoading) return null;

  return (
    <MainTemplate>
      <div className="wrapper sign sign_confirm column justify-center items-center">
        <h1 className="text">Увы...</h1>
        <p className="text text-center">Не удалось подтвердить адрес электронной почты: ссылка недействительна</p>
      </div>
    </MainTemplate>
  );
};
