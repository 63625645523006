import React, { useEffect } from "react";
import { ButtonLink } from "../../components/Button";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

import "./styles.scss";
import { observer } from "mobx-react-lite";
import { routeNames, routeNamesWithParams } from "../../shared/config";
import { coursesSearchModel } from "../../entities/courses/search";
import { CourseRow } from "../../components/CourseRow";
import { Link } from "../../shared/ui/link/link";
import { Video } from "../../shared/ui/video/video";

export const Main = observer(() => {
  useEffect(() => {
    coursesSearchModel.search("");
  }, []);

  return (
    <MainTemplate>
      <div className="sign sign_up column justify-center items-center">
        {coursesSearchModel.searchValue === "" && (
          <>
            <h1 style={{ marginTop: 40, marginBottom: 20 }} className="text">
              DirectPro
            </h1>
            <h3 className="main-page-subheader">
              Это универсальная платформа для создания курсов с интерактивными возможностями.
            </h3>
            <Video link="https://www.youtube.com/watch?v=v2jXzFyTgyc" />
            {/* <ButtonLink
              linkClassName="create-course-link"
              link={routeNamesWithParams({ url: "pro", courseId: 28, articleId: 180 }).article}
              text="Создать курс"
            /> */}
            <h1 style={{ marginTop: 20, marginBottom: 20 }} className="text">
              Маркетплейс
            </h1>
            <h3 className="main-page-subheader">
              В Маркетплэйс DirectPro попадают только лучшие и проверенные курсы авторов. Вот один из них:
            </h3>
          </>
        )}
        {coursesSearchModel.isLoaded ? (
          !coursesSearchModel.error ? (
            coursesSearchModel.courses.map((course) => (
              <CourseRow
                key={course.id}
                id={course.id}
                url={course.url}
                text={course.name}
                image={course.image}
                video={course.video}
                description={course.description}
              />
            ))
          ) : (
            <p style={{ color: "grey", marginTop: "40px", marginBottom: 20, textAlign: "center" }}>
              К сожалению такого курса ещё нет, <br /> но вы можете
              <Link style={{ marginLeft: 5 }} to={routeNames.adminCourses}>
                создать свой.
              </Link>
            </p>
          )
        ) : (
          <p>Список курсов загружается...</p>
        )}
      </div>
      <footer>
        <div className="links">
          <Link to={routeNames.contacts} className="link">
            Контакты
          </Link>
          <Link to={routeNames.tariff} className="link">
            Тарифы
          </Link>
          <Link to={routeNames.privacyPolicy} className="link">
            Политика конфиденциальности
          </Link>
          <Link to={routeNames.terms} className="link">
            Условия публичной оферты
          </Link>
        </div>
        Все права принадлежат ИП Горшков Петр Владимирович <br />
        ИНН: 370305802700 ОГРНИП: 323370000026538 <br /> <br /> © 2022 DirectPro
      </footer>
    </MainTemplate>
  );
});
