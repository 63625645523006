import React from "react";
import { REACT_APP_SELF_URL } from "../../shared/config";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const Privacy = () => {
  return (
    <MainTemplate>
      <div className="sign sign_up column justify-center items-center">
        <h1 className="text text-center">Политика конфиденциальности</h1>
        <p className="text">
          Дата размещения на Сайте 01 июля 2022 г. <br /> <br />
          Настоящая Политика конфиденциальности регулирует порядок обработки и использования персональных и иных данных
          при использовании Пользователями Сайта.
          <br />
          Передавая “ИП Горшков Петр Владимирович” персональные и иные данные посредством Сайта, Пользователь подтверждает
          свое согласие на использование указанных данных на условиях, изложенных в настоящей Политике и иных
          соглашениях (договорах), акцептуемых Пользователем при использовании Сайта.
          <br />
          Если Пользователь не согласен с условиями настоящей Политики конфиденциальности, он обязан прекратить
          использование Сайта. Безусловным акцептом настоящей Политики конфиденциальности является начало использования
          Сайта Пользователем.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>1. ТЕРМИНЫ</span> <br /> <br />
          1.1. Сайт - сайт, расположенный в сети Интернет по адресу {REACT_APP_SELF_URL} <br />
          1.2. Пользователь — лицо использующее Сайт.
          <br />
          1.3. Законодательство — действующее законодательство Российской Федерации.
          <br />
          1.4. Персональные данные — персональные данные Пользователя, которые Пользователь предоставляет о себе
          самостоятельно при Регистрации или в процессе использования функционала Сайта.
          <br />
          1.5. Данные — иные данные о Пользователе (не входящие в понятие Персональных данных).
          <br />
          1.6. Регистрация — заполнение Пользователем Регистрационной формы, расположенной на Сайте, путем указания
          необходимых сведений и выбора Логина и пароля.
          <br />
          1.7. Регистрационная форма — форма, расположенная на Сайте, которую Пользователь должен заполнить для
          прохождения Регистрации на Сайте.
          <br />
          1.8. Услуга(и) — услуги, предоставляемые “ИП Горшков Петр Владимирович” <br />
          на основании договора оферты.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>2. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
          <br />
          <br />
          2.1. “ИП Горшков Петр Владимирович” собирает и хранит только те Персональные данные, которые необходимы для
          оказания Услуг “ИП Горшков Петр Владимирович <br />
          “ и взаимодействия с Пользователем.
          <br />
          2.2. Персональные данные могут использоваться в следующих целях:
          <br />
          2.2.1 оказание Услуг Пользователю;
          <br />
          2.2.2 идентификация Пользователя;
          <br />
          2.2.3 взаимодействие с Пользователем;
          <br />
          2.2.4 направление Пользователю информационных материалов и запросов;
          <br />
          2.2.5 проведение статистических и иныхисследований;
          <br />
          2.2.6 обработка платежей Пользователя;
          <br />
          2.2.7 мониторинг операций Пользователя в целях предотвращения мошенничества, противоправных ставок, отмывания
          денег.
          <br />
          2.3. “ИП Горшков Петр Владимирович” в том числе обрабатывает данные адреса электронной почты;
          <br />
          2.4. Срок обработки Персональных данных, ограничен сроком, указанным в Лицензионном соглашении.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>3. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ И ИНЫХ ДАННЫХ</span>
          <br />
          <br />
          3.1. “ИП Горшков Петр Владимирович” обязуется использовать Персональные данные в соответствии с Федеральным
          Законом «О персональных данных» № 152-ФЗ от 27 июля 2006 г. и внутренними документами Холдинга DirectPro.
          <br />
          3.2. В отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность, кроме
          случаев, когда указанные данные являются общедоступными.
          <br />
          3.3. “ИП Горшков Петр Владимирович” имеет право сохранять архивную копию Персональных данных и Данных, в том
          числе после удаления аккаунта Пользователя. “ИП Горшков Петр Владимирович” имеет право хранить Персональные
          данные и Данные на серверах вне территории Российской Федерации.
          <br />
          3.4. “ИП Горшков Петр Владимирович” имеет право передавать Персональные данные и Данные Пользователя без
          согласия Пользователя следующим лицам:
          <br />
          3.4.1 государственным органам, в том числе органам дознания и следствия, и органам местного самоуправления по
          их мотивированному запросу; 3.4.2 партнерам “ИП Горшков Петр Владимирович”;
          <br />
          3.4.3 в иных случаях, прямо предусмотренных действующим законодательством РФ. 3.5. “ИП Горшков Петр Владимирович” имеет право передавать Персональные данные и Данные третьим лицам, не указанным в п. 3.4. настоящей
          Политики конфиденциальности, в следующих случаях:
          <br />
          3.5.1 Пользователь выразил свое согласие на такие действия;
          <br />
          3.5.2 передача необходима в рамках использования Пользователем Сайта или оказания Услуг Пользователю;
          <br />
          3.5.3 передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к
          приобретателю переходят все обязательства по соблюдению условий настоящей Политики.
          <br />
          3.6. “ИП Горшков Петр Владимирович” осуществляет автоматизированную обработку Персональных данных и Данных. 3.7.
          “ИП Горшков Петр Владимирович” осуществляет трансграничную передачу Персональных данных.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>4. ИЗМЕНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
          <br />
          <br />
          4.1. Пользователь может в любой момент изменить (обновить, дополнить) Персональные данные посредством Личного
          кабинета.
          <br />
          4.2. Пользователь в любой момент имеет право удалить Персональные данные и Данные.
          <br />
          4.3. Пользователь гарантирует, что все Персональные данные являются актуальными и не относятся к третьим
          лицам.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>5. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
          <br />
          <br />
          5.1. “ИП Горшков Петр Владимирович” осуществляет надлежащую защиту Персональных и иных данных в соответствии с
          Законодательством и принимает необходимые и достаточные организационные и технические меры для защиты
          Персональных данных.
          <br />
          5.2. Применяемые меры защиты в том числе позволяют защитить Персональные данные от неправомерного или
          случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных
          неправомерных действий с ними третьих лиц.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>6. ПЕРСОНАЛЬНЫЕ ДАННЫЕ ТРЕТЬИХ ЛИЦ ИСПОЛЬЗУЕМЫЕ ПОЛЬЗОВАТЕЛЯМИ</span>
          <br />
          <br />
          6.1. Используя Сайт Пользователь имеет право заносить данные третьих лиц для последующего использования.
          <br />
          6.2. Пользователь обязуется получить согласие субъекта персональных данных на использование посредством Сайта.
          <br />
          6.3. “ИП Горшков Петр Владимирович” не использует персональные данные третьих лиц занесенные Пользователем.
          <br />
          6.4. “ИП Горшков Петр Владимирович” обязуется предпринять необходимые меры для обеспечения сохранности
          персональных данных третьих лиц, занесенных Пользователем.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>7. ИНЫЕ ПОЛОЖЕНИЯ</span>
          <br />
          <br />
          7.1. К настоящей Политике конфиденциальности и отношениям между Пользователем и “ИП Горшков Петр Владимирович” ,
          возникающим в связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.
          <br />
          7.2. Все возможные споры, вытекающие из настоящего Соглашения, подлежат разрешению в соответствии с
          действующим законодательством по месту регистрации “ИП Горшков Петр Владимирович”. Перед обращением в суд
          Пользователь должен соблюсти обязательный досудебный порядок и направить “ИП Горшков Петр Владимирович”
          соответствующую претензию в письменном виде. Срок ответа на претензию составляет 30 (тридцать) рабочих дней.
          <br />
          7.3. Если по тем или иным причинам одно или несколько положений Политики будут признаны недействительными или
          не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных
          положений Политики.
          <br />
          7.4. “ИП Горшков Петр Владимирович” имеет право в любой момент изменять Политику конфиденциальности (полностью
          или в части) в одностороннем порядке без предварительного согласования с Пользователем. Все изменения вступают
          в силу на следующий день после размещения на Сайте.
          <br />
          7.5. Пользователь обязуется самостоятельно следить за изменениями Политики путем ознакомления с актуальной
          редакцией, размещенной на Сайте.
          <br />
          <br />
          Дата размещения на Сайте 01 июля 2022 г.
          <br />
          <br />
          <br />
        </p>
      </div>
    </MainTemplate>
  );
};
