import { makeAutoObservable, runInAction } from "mobx";
import { AuthService } from "../../shared/api/auth";

class SalesModel {
  payin = 0;
  payout = 0;
  balance = 0;
  commission = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    const { data } = await AuthService.fetchSales();

    if (!data) return;

    runInAction(() => {
      this.payin = data.payin;
      this.payout = data.payout;
      this.balance = data.balance;
      this.commission = data.commission;
    });
  }
}

export const salesModel = new SalesModel();
