import { makeAutoObservable, runInAction } from "mobx";
import { ArticlesService } from "../../shared/api/articles";

class ArticlesModel {
  course = null;
  articles = null;
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getAll(url, courseId) {
    const { data } = await ArticlesService.fetchAll(url, courseId);

    if (!data.success) return;

    const visibleArticles = data.data.visible;

    if (data.course.sortArticles) {
      const sortOrder = data.course.sortArticles.split(",").map((id) => Number(id));

      const itemPositions = {};

      for (const [index, id] of sortOrder.entries()) {
        itemPositions[id] = index;
      }

      data.data.visible.sort((a, b) => itemPositions[a.id] - itemPositions[b.id]);
    }

    runInAction(() => {
      this.articles = { ...data.data, visible: visibleArticles };
      this.course = data.course;
      this.isLoaded = true;
    });
  }

  async add(form) {
    const { data } = await ArticlesService.add(form);

    return data;
  }

  async edit(form) {
    const { data } = await ArticlesService.edit(form);

    return data;
  }

  async remove(courseId, articleId) {
    const { data } = await ArticlesService.remove(courseId, articleId);

    return data;
  }

  async sort(courseId) {
    const visibleArticleIds = this.articles?.visible?.map(({ id }) => id).join(",");

    const { data } = await ArticlesService.sort(courseId, visibleArticleIds);

    return data;
  }

  moveArticle(dragIndex, hoverIndex) {
    const article = [...this.articles.visible][dragIndex];

    this.articles.visible.splice(dragIndex, 1);
    this.articles.visible.splice(hoverIndex, 0, article);
  }

  async feedback(courseId, articleId, message) {
    const { data } = await ArticlesService.addFeedback(courseId, articleId, message);

    return data;
  }

  async removeImage(courseId, articleId, fileType) {
    const { data } = await ArticlesService.removeImage(courseId, articleId, fileType);

    return data;
  }

  async completeArticle(articleId) {
    const { data } = await ArticlesService.completeArticle(articleId);

    return data;
  }
}

export const articlesModel = new ArticlesModel();
