import React from "react";

import "./TextFieldRounded.scss";

const TextFieldRounded = ({ className, type = "text", value, set, placeholder = "" }) => (
  <input
    className={className}
    type={type}
    value={value}
    onChange={(e) => set(e.target.value)}
    placeholder={placeholder}
  />
);

export default TextFieldRounded;
