import React, { useState, useEffect } from "react";
import { userModel } from "../../entities/user";

import { Button } from "../../components/Button";
import TextFieldRounded from "../../components/TextFieldRounded";
import { validateEmail } from "../../shared/lib/validation";

import "../auth-styles/sign.scss";
import { useLocation } from "react-router-dom";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const RecoveryPassword = () => {
  const [formSended, setFormSended] = useState(false);
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const location = useLocation();

  useEffect(() => {
    errorMessage && setErrorMessage("");
  }, [email]);

  async function sendRecoverPasswordEmail() {
    if (validateEmail(email)) {
      const { success, message } = await userModel.sendRecoverPasswordEmail(email);

      if (success) setSuccessMessage(message);
      if (!success) setErrorMessage(message);
    } else {
      setErrorMessage("Пожалуйста, укажите адрес электронной почты, который вы использовали для входа на сайт.");
    }
  }

  const recoverPassword = async () => {
    if (newPassword === confirmNewPassword) {
      const { success, message, error } = await userModel.recoverChangePassword(newPassword, passwordRecoveryHash);

      if (success) setSuccessMessage(message);
      if (!success) setErrorMessage(error);
    } else {
      setErrorMessage("Пароли не совпадают!");
    }
  };

  const passwordRecoveryHash = location.search.split("hash=")[1];

  return (
    <MainTemplate>
      <div className="wrapper sign sign_up column justify-center items-center">
        <h1 className="text" style={{ marginBottom: "32px" }}>
          {passwordRecoveryHash ? "Изменить пароль" : "Забыли пароль"}
        </h1>

        {passwordRecoveryHash && (
          <div>
            <TextFieldRounded
              className="grey-textfield"
              type="password"
              placeholder="Новый пароль"
              value={newPassword}
              set={setNewPassword}
            />

            <TextFieldRounded
              className="grey-textfield"
              type="password"
              placeholder="Подтвердить пароль"
              value={confirmNewPassword}
              set={setConfirmNewPassword}
            />

            {errorMessage ? (
              <div className="error">
                <center>{errorMessage}</center>
              </div>
            ) : successMessage ? (
              <div style={{ textAlign: "center" }} className="success">
                {successMessage}
              </div>
            ) : null}

            <Button containerClassName="forget-password-button" text="ИЗМЕНИТЬ" action={recoverPassword} />
          </div>
        )}
        {formSended ? (
          <p className="text text-center">
            Мы отправили на ваш e-mail:
            <br />
            <span className="text-blue">{email}</span> письмо с новым паролем.
          </p>
        ) : (
          !passwordRecoveryHash && (
            <React.Fragment>
              <TextFieldRounded
                className="grey-textfield"
                type="email"
                placeholder="Укажите ваш E-mail"
                value={email}
                set={setEmail}
              />

              {errorMessage ? (
                <div className="error">
                  <center>{errorMessage}</center>
                </div>
              ) : successMessage ? (
                <div style={{ textAlign: "center" }} className="success">
                  {successMessage}
                </div>
              ) : null}

              <Button containerClassName="forget-password-button" text="ОТПРАВИТЬ" action={sendRecoverPasswordEmail} />
            </React.Fragment>
          )
        )}
      </div>
    </MainTemplate>
  );
};
