import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./app/app";
import { reportWebVitals } from "./reportWebVitals";

import "./app/css/index.css";
import "./app/scss/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
