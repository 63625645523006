import React from "react";
import { Link } from "react-router-dom";
import "./ArticleRow.scss";

import okIcon from "./svg/ok.svg";
import circleIcon from "./svg/circle.svg";
import greyLockIcon from "./svg/grey-lock.svg";
import { REACT_APP_API_URL, routeNames, routeNamesWithParams } from "../../shared/config";
import { userModel } from "../../entities/user";
import { Label } from "./label";

const labelsProps = {
  completed: { text: "пройдено", textColor: "#fff", backgroundColor: "#67B12C" },
  locked: { text: "закрыто", textColor: "#fff", backgroundColor: "#747474" },
};

export const ArticleRow = ({ article, id, url, course, text, isCompleted, image }) => {
  return (
    <Link
      to={
        !userModel.token && article.access !== 1
          ? routeNames.signIn
          : course.paymentType === 3
          ? routeNamesWithParams({ url, courseId: course.id, articleId: id }).article
          : article.access === 2 && !course.isBuyed
          ? routeNamesWithParams({ url, courseId: course.id }).coursePay
          : routeNamesWithParams({ url, courseId: course.id, articleId: id }).article
      }
      className="articleRow"
    >
      {image && <img className="img" src={`${REACT_APP_API_URL}${image}`} alt="" />}
      {/* {video && <Video link={video} />} */}
      <div className="row">
        <div className="icon-wrapper">
          <img
            className="icon"
            src={
              isCompleted
                ? okIcon
                : course.isBuyed
                ? circleIcon
                : course.paymentType === 3
                ? circleIcon
                : article.access === 2
                ? greyLockIcon
                : !isCompleted
                ? circleIcon
                : ""
            }
            alt=""
          />
        </div>

        <div className="text">
          <span style={{ marginRight: 5 }}>{text}</span>
          <Label
            {...labelsProps[isCompleted ? "completed" : article.access === 2 && !course.isBuyed ? "locked" : ""]}
          />
        </div>
      </div>
    </Link>
  );
};
