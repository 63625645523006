import React from "react";
import styles from "./checkbox.module.scss";

export const Checkbox = ({ checked, onChange, labelContent }) => {
  return (
    <label className={styles.label}>
      <input className={styles.input} type="checkbox" checked={checked} onChange={onChange} />
      <div className={styles.content}>{labelContent}</div>
    </label>
  );
};
