import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";

import { userModel } from "../entities/user";
import { privateRoutes } from "../pages/private.routes";
import { publicRoutes } from "../pages/public.routes";
import { ProtectedRoutes } from "./providers/protected-route";
import { DndProvider } from "react-dnd";

export const App = () => {
  useEffect(() => {
    userModel.loadToken();
    userModel.check();
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <Routes>
          {publicRoutes.map(({ Component, path }) => {
            return <Route key={path} path={path} element={<Component />} />;
          })}

          <Route element={<ProtectedRoutes />}>
            {privateRoutes.map(({ path, Component }) => {
              return <Route key={path} path={path} element={<Component />} />;
            })}
          </Route>
        </Routes>
      </BrowserRouter>
    </DndProvider>
  );
};
