import React from "react";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

import { userModel } from "../../entities/user";
import { observer } from "mobx-react-lite";

import mockAvatarSource from "./mock-avatar.svg";

import styles from "./styles.module.scss";

import { isObject } from "lodash";
import { REACT_APP_API_URL } from "../../shared/config";
import { profileModel } from "../../entities/user/profile";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const Profile = observer(() => {
  const { userId } = useParams();

  useEffect(() => {
    profileModel.getById(userId);
  }, [userId]);

  const renderText = (string) => {
    if (!string) return;

    const linkExp = /^https?:\/\/[a-z0-9_./-]*$/i;

    return (
      <>
        {string.split(/(https?:\/\/[a-z0-9_./-]*)/gi).map((part, index) => (
          <React.Fragment key={index}>
            {part.match(linkExp) ? (
              <a className={styles.link} href={part} target="_blank" rel="noreferrer">
                {part}
              </a>
            ) : (
              part
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <MainTemplate>
      <div className={styles.avatarWrapper}>
        <img
          className={styles.avatar}
          src={
            isObject(profileModel.user?.avatar)
              ? URL.createObjectURL(profileModel.user?.avatar)
              : typeof profileModel.user?.avatar === "string" && profileModel.user?.avatar
              ? `${REACT_APP_API_URL}${profileModel.user?.avatar}`
              : mockAvatarSource
          }
          alt=""
        />
      </div>

      <div className={styles.about}>
        <div className={styles.fullName}>{profileModel.user?.fullName}</div>
        <div className={styles.aboutText}>{renderText(profileModel.user?.aboutMe)}</div>
      </div>
    </MainTemplate>
  );
});
