import React, { useRef, useState } from "react";

export const AvatarUploader = ({ getImageOnLoad, onImageDelete, triggerElement }) => {
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const openFileUploader = () => inputRef.current.click();

  const maxUploadSizeLimitInMb = 3;

  return (
    <div>
      {triggerElement({ openFileUploader })}

      <input
        ref={inputRef}
        onChange={(event) => {
          const [newFile] = event.currentTarget.files;
          event.currentTarget.value = null;

          if (newFile.size / 1024 / 1024 > maxUploadSizeLimitInMb) return;

          setFile(newFile);
          getImageOnLoad(newFile);
        }}
        style={{ opacity: 0, visibility: "hidden", height: 0 }}
        type="file"
      />
    </div>
  );
};
