import { makeAutoObservable, runInAction } from "mobx";
import { ArticlesService } from "../../shared/api/articles";

class ArticleModel {
  article = null;
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async get(courseId, articleId, url) {
    const { data } = await ArticlesService.fetchOne(courseId, articleId, url);

    if (!data) return;

    runInAction(() => {
      this.article = data;
      this.isLoaded = true;
    });
  }

  changeField(fieldName, value) {
    this.article[fieldName] = value;
  }
}

export const articleModel = new ArticleModel();
