import { observer } from "mobx-react-lite";
import React from "react";
import { Link } from "react-router-dom";
import { userModel } from "../../../../entities/user";
import { routeNamesWithParams } from "../../../../shared/config";
import { PAYMENT_TYPES_NAMES } from "../../../../shared/config/constants";
import { ButtonLink } from "../../../Button";

import "./course.row.admin.scss";

export const CourseRowAdmin = observer(({ id, name, description, messages = 0, paymentType, status }) => (
  <div className="course_row_admin">
    <Link to={routeNamesWithParams({ url: userModel.user?.url, courseId: id }).course}>
      <div className="course_row_admin__name">{name}</div>
    </Link>

    <p style={{ fontWeight: 400, fontSize: 14, lineHeight: "18px" }}>{description}</p>

    <div className="course_row_admin__info">
      {/* <div style={{ fontWeight: 600 }} className="course_row_admin__info__item">
        Подписано на курс:
        <Link style={{ fontWeight: 400 }} to={`/admin/courses/${id}/subscribers`}>
          {subscribers} пользователей
        </Link>
      </div> */}

      {/* <div className="course_row_admin__info__item">
        Отображение:
        {/* TODO: принять из пропсов
        <span>КУРС СКРЫТ</span>
      </div> */}

      <div className="course_row_admin__info__item row" {...(!status && { style: { marginBottom: "-5px" } })}>
        СТАТУС КУРСА:
        {paymentType === 3 || status ? (
          <span className="row" style={{ color: "#67b12c", fontWeight: 500 }}>
            Активно
          </span>
        ) : (
          <div className="row">
            <span style={{ marginRight: 5, color: "#B0B0B0", fontWeight: 500 }}>На модерации</span>
            {/* <HelpButton videoLink="https://www.youtube.com/watch?v=8_DazhgI57w" /> */}
          </div>
        )}
      </div>

      <div className="course_row_admin__info__item">
        КУРС:{" "}
        {paymentType === 1 ? (
          <Link to={routeNamesWithParams({ courseId: id }).adminCoursePayment}>
            {PAYMENT_TYPES_NAMES[+paymentType]}
          </Link>
        ) : (
          <Link to={routeNamesWithParams({ courseId: id }).adminCourseSubscribers}>
            {PAYMENT_TYPES_NAMES[+paymentType]}
          </Link>
        )}
      </div>

      <div className="course_row_admin__info__item">
        Обратная связь:
        <Link to={routeNamesWithParams({ courseId: id }).adminCourseFeedbacks}>
          {messages > 0 ? `${messages} сообщений` : "новых сообщений нет"}
        </Link>
      </div>
      <div className="row">
        <ButtonLink link={routeNamesWithParams({ courseId: id }).adminCourseEdit} text="РЕДАКТИРОВАТЬ" />
        <ButtonLink link={routeNamesWithParams({ courseId: id }).adminCourseArticles} text="СТАТЬИ КУРСА" />
      </div>
    </div>
  </div>
));
