import React from "react";
import { convertToEmbeddedVideo } from "../../lib/video";
import styles from "./video.module.scss";

export const Video = ({ link }) => {
  return (
    <div className={styles.wrapper}>
      <iframe
        className={styles.iframe}
        src={convertToEmbeddedVideo(link)}
        title="Video player"
        allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};
