import React from "react";

import { Link } from "react-router-dom";
import "./Button.scss";

export const Button = ({ type, containerClassName, className, action, text, disabled = false, styles = {} }) => (
  <div className={`button-container ${containerClassName ? containerClassName : ""}`}>
    <button
      type={type}
      className={`button ${className ? className : ""} ${disabled ? "disabled" : ""}`}
      onClick={action}
      style={styles}
    >
      {text}
    </button>
  </div>
);

export const ButtonLink = ({ style, className, linkClassName, link, text, disabled }) => (
  <div style={style} className={`button-container ${className ? className : ""}`}>
    <Link to={link} className={`button ${linkClassName ? linkClassName : ""} ${disabled ? "disabled" : ""}`}>
      {text}
    </Link>
  </div>
);
