import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, ButtonLink } from "../Button";
import InputRadio from "../InputRadio";
import TextFieldRounded from "../TextFieldRounded";
import "./Module.scss";
import { TextArea } from "../TextField";
import { articlesModel } from "../../entities/articles";
import { routeNamesWithParams } from "../../shared/config";

const Module = (props) => {
  const RENDER = {
    1: <ModuleButtons {...props} />,
    2: (
      <ButtonLink
        link={routeNamesWithParams({ url: props.user, courseId: props.course, articleId: props.m_next?.id }).article}
        text="Далее"
      />
    ),
    3: <ModuleInput {...props} />,
    4: <ModuleChoose {...props} />,
    5: <ModuleIcon {...props} />,
    6: <ModuleText {...props} />,
  };

  return (
    <div style={{ textAlign: "center" }} className="module">
      {RENDER[props.module]}
    </div>
  );
};

export default Module;

const ModuleButtons = (props) => {
  const ARR = [
    props.m_btns_btn1,
    props.m_btns_btn2,
    props.m_btns_btn3,
    props.m_btns_btn4,
    props.m_btns_btn5,
    props.m_btns_btn6,
  ];

  return (
    <div className="module__buttons">
      <div className="module__buttons__list">
        {ARR.map(
          (btn, key) =>
            btn !== "" && (
              <Link
                key={key}
                to={routeNamesWithParams({ url: props.user, courseId: props.course, articleId: btn.id }).article}
                className="module__buttons__btn"
              >
                {key + 1}
              </Link>
            )
        )}
      </div>
    </div>
  );
};

const ModuleInput = ({ user, course, m_input_from, m_input_to, m_input_true, m_input_false }) => {
  const [answer, setAnswer] = useState("");

  return (
    <div className="module__input">
      <div className="module__input__controls">
        <TextFieldRounded type="number" value={answer} set={setAnswer} />
        <ButtonLink
          link={
            routeNamesWithParams({
              url: user,
              courseId: course,
              articleId:
                answer >= parseFloat(m_input_from) && answer <= parseFloat(m_input_to) ? m_input_true.id : m_input_false.id,
            }).article
          }
          text="Ответить"
        />
      </div>
    </div>
  );
};

const ModuleText = ({ user, course, m_text_value, m_text_true, m_text_false }) => {
  const [answer, setAnswer] = useState("");

  return (
    <div className="module__input">
      <div className="module__input__controls">
        <div style={{ marginTop: -12, width: "100%" }}>
          <TextArea style={{ width: "100%", marginBottom: 16 }} value={answer} set={setAnswer} />

          <ButtonLink
            style={{ textAlign: "left" }}
            link={
              routeNamesWithParams({
                url: user,
                courseId: course,
                articleId: answer === m_text_value ? m_text_true.id : m_text_false.id,
              }).article
            }
            text="Ответить"
          />
        </div>
      </div>
    </div>
  );
};

const ModuleChoose = (props) => {
  const [selected, setSelected] = useState(1);
  const { pathname } = useLocation();

  useEffect(() => {
    setSelected(1);
  }, [pathname]);

  const KEYS = [];
  const VALUES = [];

  function pushIfNotEmpty(keys, values, key, value) {
    if (key !== "" && value !== "") {
      keys.push(key);
      values.push(value);
    }
  }

  pushIfNotEmpty(KEYS, VALUES, props.m_choose_1_article.id, props.m_choose_1_text);
  pushIfNotEmpty(KEYS, VALUES, props.m_choose_2_article.id, props.m_choose_2_text);
  pushIfNotEmpty(KEYS, VALUES, props.m_choose_3_article.id, props.m_choose_3_text);
  pushIfNotEmpty(KEYS, VALUES, props.m_choose_4_article.id, props.m_choose_4_text);
  pushIfNotEmpty(KEYS, VALUES, props.m_choose_5_article.id, props.m_choose_5_text);
  pushIfNotEmpty(KEYS, VALUES, props.m_choose_6_article.id, props.m_choose_6_text);

  return (
    <>
      <div className="module_border">
        <InputRadio
          //// title = "Куда перейти с этой статьи?"
          values={VALUES}
          selected={selected}
          set={setSelected}
        />
      </div>
      <ButtonLink
        link={routeNamesWithParams({ url: props.user, courseId: props.course, articleId: KEYS[selected - 1] }).article}
        text="Ответить"
      />
    </>
  );
};

const ModuleIcon = (props) => {
  const navigate = useNavigate();

  async function Action() {
    let link = {
      1: props.m_icon_redirectArticleID.id
        ? routeNamesWithParams({
          url: props.user,
          courseId: props.course,
          articleId: props.m_icon_redirectArticleID.id,
        }).article
        : routeNamesWithParams({ url: props.user, courseId: props.course }).course,
      // 2: `/${props.user}/${props.course}/${props.m_icon_redirectCourseID.id}`,
    };

    const url = link[1];

    const redirect = () => navigate(url, { replace: true });

    const { success } = await articlesModel.completeArticle(props.m_icon_changeID.id);
    if (success) redirect();
  }

  return <Button action={Action} text="Далее" />;
};
