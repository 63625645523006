import React from "react";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import styles from "./styles.module.scss";

export const Contacts = () => {
  return (
    <MainTemplate>
      <div className="sign">
        <h1 className="text">Контакты</h1>
        <p className={styles.description}>Для тех, кто хочет самостоятельно разместить платный или закрытый курс на платформе DirectPro. Звоните, или пишите нам. Мы дадим вам подробную видео инструкцию и ответим на все вопросы.</p>
        <div className={styles.contacts}>
          <div className={styles.row}>
            Тел:
            <a className={styles.link} href="tel:+9150792207">
              +915 07-922-07
            </a>
          </div>

          <div className={styles.row}>
            Telegram:
            <a className={styles.link} href="https://t.me/directpro2023">
              @directpro2023
            </a>
          </div>

        </div>
      </div>
    </MainTemplate>
  );
};
