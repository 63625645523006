import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import circleIcon from "../assets/circle.svg";
import greyLockIcon from "../assets/grey-lock.svg";

import { routeNamesWithParams } from "../../../shared/config";
import { userModel } from "../../../entities/user";
import { observer } from "mobx-react-lite";

import styles from "./styles.module.scss";
import { useDrag, useDrop } from "react-dnd";
import { articlesModel } from "../../../entities/articles";
import { debounce } from "lodash";

export const ItemTypes = {
  CARD: "card",
};

export const SortableArticleRow = observer(({ paymentType, article, name, courseId, index }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      articlesModel.moveArticle(dragIndex, hoverIndex);
      item.index = hoverIndex;

      debouncedSort();
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id: article.id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const debouncedSort = useRef(
    debounce(() => {
      articlesModel.sort(courseId);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSort.cancel();
    };
  }, [debouncedSort]);

  drag(drop(ref));

  return (
    <div ref={ref} className={styles.row} style={{ opacity: isDragging ? 0 : 1 }}>
      <span style={{ marginTop: 3, marginRight: 6 }}>
        {paymentType === 1 && article.access === 1 && (
          <img style={{ height: 7, width: 7, marginRight: 3, marginTop: 4, marginLeft: 4 }} src={circleIcon} alt="" />
        )}
        {paymentType === 1 && article.access === 2 && (
          <img style={{ height: 12, width: 12 }} src={greyLockIcon} alt="" />
        )}
        {paymentType === 3 && article.access === 2 && (
          <img style={{ height: 12, width: 12 }} src={greyLockIcon} alt="" />
        )}
      </span>

      <div style={{ marginTop: -2 }}>
        <Link
          className={styles.name}
          to={
            routeNamesWithParams({
              url: userModel.user?.url,
              courseId,
              articleId: article.id,
            }).article
          }
        >
          {name}
        </Link>

        <Link
          to={
            routeNamesWithParams({
              url: userModel.user?.url,
              courseId,
              articleId: article.id,
            }).adminCourseArticleEdit
          }
          className={styles.editLink}
        >
          ред.
        </Link>
      </div>
    </div>
  );
});
