import React, { useState } from "react";
import { Button } from "../../components/Button";
import TextField, { TextArea } from "../../components/TextField";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

import emailIcon from "./email.svg";
import { userModel } from "../../entities/user";
import { observer } from "mobx-react-lite";
import { profileModel } from "../../entities/user/profile";
import { AvatarUploader } from "../../shared/ui/avatar-uploader/avatar-uploader";

import mockAvatarSource from "./mock-avatar.svg";

import styles from "./styles.module.scss";

import { isObject } from "lodash";
import { REACT_APP_API_URL } from "../../shared/config";

export const MyProfile = observer(() => {
  const [isEdit, setIsEdit] = useState(false);

  const save = (event) => {
    event.preventDefault();

    if (!userModel.user?.fullName) return;

    profileModel.saveProfile(userModel.user?.avatar, userModel.user?.fullName, userModel.user?.aboutMe);

    setIsEdit(false);
  };

  const renderText = (string) => {
    if (!string) return;

    const linkExp = /^https?:\/\/[a-z0-9_./-]*$/i;

    return (
      <>
        {string.split(/(https?:\/\/[a-z0-9_./-]*)/gi).map((part, index) => (
          <React.Fragment key={index}>
            {part.match(linkExp) ? (
              <a className={styles.link} href={part} target="_blank" rel="noreferrer">
                {part}
              </a>
            ) : (
              part
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <MainTemplate>
      <div className={styles.avatarWrapper}>
        <img
          className={styles.avatar}
          src={
            isObject(userModel.user?.avatar)
              ? URL.createObjectURL(userModel.user?.avatar)
              : typeof userModel.user?.avatar === "string" && userModel.user?.avatar
              ? `${REACT_APP_API_URL}${userModel.user?.avatar}`
              : mockAvatarSource
          }
          alt=""
        />
        {isEdit && (
          <AvatarUploader
            getImageOnLoad={(image) => userModel.changeUserField("avatar", image)}
            triggerElement={({ openFileUploader }) => (
              <div onClick={openFileUploader} className={styles.avatarText}>
                Сменить фотографию профиля
              </div>
            )}
          />
        )}
      </div>

      {isEdit ? (
        <form onSubmit={save}>
          <TextField
            title="Ваше Имя:"
            value={userModel.user?.fullName ?? ""}
            set={(newValue) => userModel.changeUserField("fullName", newValue)}
          />

          <TextArea
            title="О себе:"
            value={userModel.user?.aboutMe}
            set={(newValue) => userModel.changeUserField("aboutMe", newValue)}
          />

          <h4 className={styles.personalInfo}>Личная информация:</h4>
          <div className={styles.email}>
            <img className={styles.emailIcon} src={emailIcon} alt="" />
            {userModel.user?.email}
          </div>

          <Button text="Сохранить" type="submit" />
        </form>
      ) : (
        <div className={styles.about}>
          <div className={styles.fullName}>{userModel.user?.fullName}</div>
          <div className={styles.aboutText}>{renderText(userModel.user?.aboutMe)}</div>

          <Button text="Редактировать" action={() => setIsEdit(true)} />
        </div>
      )}
    </MainTemplate>
  );
});
