import { observer } from "mobx-react-lite";
import { articlesModel } from "../../entities/articles";
import { REACT_APP_API_URL } from "../../shared/config";
import { convertToEmbeddedVideo } from "../../shared/lib/video";
import { Accordion } from "../../shared/ui/accordion/accordion";
import { Popup } from "../../shared/ui/popup/popup";

export const ArticlesPopup = observer(({ isOpen, onClose, onNameClick }) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      {articlesModel.isLoaded && (
        <Accordion
          data={[...(articlesModel.articles?.visible || []), ...(articlesModel.articles?.hidden || [])]}
          onNameClick={(rowData) => {
            onClose();
            onNameClick(rowData);
          }}
          panelContent={(rowData) => (
            <>
              <p style={{ marginBottom: 15 }}>{rowData.text}</p>
              <img style={{ marginBottom: 15 }} src={`${REACT_APP_API_URL}${rowData.imageInArticle}`} alt="" />
              {rowData.video && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: 0,
                    paddingBottom: "56.25%",
                    backgroundColor: "#000000",
                    cursor: "pointer",
                  }}
                >
                  <iframe
                    style={{ position: "absolute", height: "100%", width: "100%", top: "0", left: "0" }}
                    src={convertToEmbeddedVideo(rowData.video)}
                    title="Video player"
                    allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </>
          )}
        />
      )}
    </Popup>
  );
});
