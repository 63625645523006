import { apiInstance } from "../api";

export class AuthService {
  static check() {
    return apiInstance.post("/sign/check.php");
  }

  static signUp(email, password) {
    return apiInstance.post("/sign/up.php", { email, password, role: "user" });
  }

  static signIn(email, password) {
    return apiInstance.post("/sign/in.php", { email, password });
  }

  static changePassword(oldPassword, newPassword) {
    return apiInstance.post("/sign/changepass.php", { oldpass: oldPassword, newpass: newPassword });
  }

  static sendRecoverPasswordEmail(email) {
    return apiInstance.post("/sign/recovery.php", { email });
  }

  static recoverChangePassword(newPassword, hash) {
    return apiInstance.post("/sign/recovery.changepass.php", { newpass: newPassword, hash });
  }

  static confirmEmail(hash) {
    return apiInstance.post("/sign/email.confirm.php", { hash });
  }

  static fetchUserData(id) {
    return apiInstance.post("/sign/get.user.data.php", { id });
  }

  static saveRequisites({ legalPerson, bic, correspondentAccount, tin, personFullName, accountNumber }) {
    return apiInstance.post("/sign/save.user.data.php", {
      legalPerson,
      bic,
      correspondentAccount,
      tin,
      personFullName,
      accountNumber,
    });
  }

  static saveProfile(formData) {
    return apiInstance.post("/sign/save.profile.php", formData, { headers: { "Content-Type": "multipart/form-data" } });
  }

  static fetchSales() {
    return apiInstance.post("/sign/sales.statistics.php");
  }

  static fetchSalesArchive() {
    return apiInstance.post("/sign/payout.archive.php");
  }

  static fetchSubscribers() {
    return apiInstance.post("/sign/subscribers.php");
  }

  static fetchMySubscriptions() {
    return apiInstance.post("/sign/my.subscriptions.php");
  }
}
