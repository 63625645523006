/**
 * @example
 * routeNamesWithParams().adminCourseEdit
 * // возвращает /admin/courses/:courseId/edit
 * @example
 * routeNamesWithParams({ courseId: 50 }).adminCourseEdit
 * // возвращает /admin/courses/50/edit
 */
export const routeNamesWithParams = (params) => ({
  adminCourseEdit: setParams("/admin/courses/:courseId/edit", params),
  adminCourseSubscribers: setParams("/admin/courses/:courseId/subscribers", params),
  adminCoursePayment: setParams("/admin/courses/:courseId/payment", params),
  adminCourseFeedbacks: setParams("/admin/courses/:courseId/feedbacks", params),
  adminCourseArticles: setParams("/admin/courses/:courseId/articles", params),
  adminCourseArticleCreate: setParams("/admin/courses/:courseId/articles/new", params),
  adminCourseArticleEdit: setParams("/admin/courses/:courseId/articles/:articleId/edit", params),

  courses: setParams("/:url", params),
  course: setParams("/:url/:courseId", params),
  courseFeedbacks: setParams("/:url/:courseId/feedbacks", params),
  coursePay: setParams("/:url/:courseId/pay", params),
  article: setParams("/:url/:courseId/:articleId", params),

  profile: setParams("/profile/:userId", params),
  myProfile: setParams("/my-profile", params),
});

function setParams(route, params) {
  if (!params) return route;

  const routeParams = route.split("/").filter((value) => value.includes(":"));

  routeParams.forEach((param) => {
    if (!params[param.replace(":", "")]) return;

    route = route.replace(param, params[param.replace(":", "")]);
  });

  return route;
}
