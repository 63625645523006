import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { mySubscriptionsModel } from "../../entities/user/subscriptions";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { Card } from "./card/card";

import styles from "./styles.module.scss";

export const Subscriptions = observer(() => {
  useEffect(() => {
    mySubscriptionsModel.getAll();
  }, []);

  return (
    <MainTemplate title="Мои подписки">
      <h4 className={styles.subTitle}>Здесь отображаются все курсы на которые вы подписаны:</h4>
      {mySubscriptionsModel.subscriptions?.map((subscription) => {
        return (
          <Card
            key={subscription.id}
            className={styles.card}
            courseName={subscription.course_name}
            dateExpired={subscription.subscribe_date}
            dateBuyed={subscription.subscribe_to}
            check={subscription.check_url}
            url={subscription.url}
            courseId={subscription.course_id}
          />
        );
      })}
    </MainTemplate>
  );
});
