import React from "react";
import { InputRadio } from "../input-radio/input-radio";
import styles from "./input-radio-group.module.scss";

export const InputRadioGroup = ({ className, title, value, setValue, values }) => {
  const handleChange = (event) => {
    setValue(event.currentTarget.value);
  };

  return (
    <div className={className}>
      <h4 className={styles.title}>{title}</h4>

      {values?.map((inputValue) => {
        return (
          <InputRadio
            key={inputValue}
            value={inputValue}
            label={inputValue}
            className={styles.inputRadio}
            checked={inputValue === value}
            onChange={handleChange}
          />
        );
      })}
    </div>
  );
};
