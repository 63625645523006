import React from "react";
import styles from "./popup.module.scss";

export const Popup = ({ isOpen, onClose, children }) => {
  if (isOpen) {
    return (
      <div className={styles.popup}>
        <button onClick={onClose} className={styles.closeButton} />
        <div className={styles.content}>{children}</div>
      </div>
    );
  }

  return null;
};
