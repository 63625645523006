import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import LoadingSpinner from "../../components/loader";
import { feedbacksModel } from "../../entities/courses/feedbacks";
import { FeedBack } from "./feedback";

import { userModel } from "../../entities/user";

import "./styles.scss";
import { observer } from "mobx-react-lite";
import { routeNames } from "../../shared/config";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const AdminCourseFeedbacks = observer(() => {
  const { courseId } = useParams();

  useEffect(() => {
    courseId && feedbacksModel.getAll(courseId);
  }, [courseId]);

  return (
    <MainTemplate>
      {!feedbacksModel.isLoaded ? (
        <LoadingSpinner />
      ) : (
        <div className="column">
          <div className="navbar row items-center">
            <Link to={routeNames.adminCourses} className="text-blue text">
              Курсы/
            </Link>
            <p>Статьи</p>
          </div>

          <p>Если на вас поступила жалоба, пожалуйста устраните проблему и напишите пользователю ответ.</p>
          <br />

          <p style={{ marginBottom: 16, fontWeight: 700, fontSize: "14px", lineHeight: "19px" }}>ЖАЛОБЫ:</p>

          {feedbacksModel.feedbacks?.length === 0 ? (
            <p>Нет сообщений</p>
          ) : (
            feedbacksModel.feedbacks?.map((feedback, key) => (
              <FeedBack
                key={key}
                course={courseId}
                article={feedback.article_id}
                email={feedback.email}
                date={feedback.date}
                text={feedback.message}
                course_url={userModel.user?.url}
              />
            ))
          )}
        </div>
      )}
    </MainTemplate>
  );
});
