import React from "react";
import styles from "./link.module.scss";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

export const Link = ({ to, rel, target, className, children, style }) => {
  return (
    <RouterLink to={to} rel={rel} target={target} style={style} className={clsx(styles.link, className)}>
      {children}
    </RouterLink>
  );
};
