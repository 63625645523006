import React from "react";
import { routeNamesWithParams } from "../../../shared/config";
import { isUnlimitedDate } from "../../../shared/lib/date/is-unlimited-date";
import { Link } from "../../../shared/ui/link/link";

import styles from "./styles.module.scss";

export const Card = ({ className, courseName, dateBuyed, dateExpired, checkUrl, url, courseId }) => {
  const isUnlimited = isUnlimitedDate(dateBuyed);

  return (
    <div className={className}>
      <Link className={styles.name} to={routeNamesWithParams({ url, courseId }).course}>
        {courseName}
      </Link>
      <div>Дата покупки: {dateExpired}</div>
      <div>Доступ до: {isUnlimited ? "на год" : dateBuyed}</div>
      {checkUrl && (
        <span>
          Чек покупки: <Link to={checkUrl}>смотреть</Link>
        </span>
      )}
    </div>
  );
};
