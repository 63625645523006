import React, { useEffect, useRef, useState } from "react";
import styles from "./accordion.module.scss";

const Row = ({ rowData, PanelContent, onNameClick }) => {
  const [isOpened, setIsOpened] = useState(false);
  const panelRef = useRef(null);

  useEffect(() => {
    if (isOpened) panelRef.current.style.maxHeight = panelRef.current.scrollHeight + "px";
    else panelRef.current.style.maxHeight = null;
  }, [isOpened]);

  return (
    <div className={`${styles.row} ${isOpened ? styles.rowActive : ""}`}>
      <button
        onClick={() => {
          setIsOpened((prev) => !prev);
        }}
        className={styles.accordion}
      >
        <span
          className={styles.name}
          onClick={(event) => {
            event.stopPropagation();
            onNameClick();
          }}
        >
          {rowData.name}
        </span>
        <span className={styles.arrow} />
      </button>
      <div ref={panelRef} className={styles.panel}>
        <div className={styles.panelInner}>
          <PanelContent />
        </div>
      </div>
    </div>
  );
};

export const Accordion = ({ data, onNameClick, panelContent }) => {
  return (
    <div>
      {data.map((rowData) => {
        return (
          <Row
            key={rowData.name}
            rowData={rowData}
            onNameClick={() => onNameClick(rowData)}
            PanelContent={() => panelContent(rowData)}
          />
        );
      })}
    </div>
  );
};
