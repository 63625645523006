import React, { useEffect } from "react";
import "./loader.scss";

const LoadingSpinner = () => {
  useEffect(() => {
    document.querySelector(".loader")?.scrollIntoViewIfNeeded();
  }, []);
  return <div className="loader" />;
};

export default LoadingSpinner;
