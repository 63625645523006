import React from "react";
import styles from "./label.module.scss";

export const Label = ({ backgroundColor, borderColor, textColor, text }) => {
  return (
    <span style={{ backgroundColor, border: `1px solid ${borderColor}`, color: textColor }} className={styles.label}>
      {text}
    </span>
  );
};
