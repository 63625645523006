import clsx from "clsx";
import React from "react";
import styles from "./input-radio.module.scss";

export const InputRadio = ({ className, label, value, checked, onChange }) => {
  return (
    <label className={clsx(styles.label, className)}>
      {label}

      <input className={styles.input} type="radio" onChange={onChange} checked={checked} value={value} name="radio" />
      <span className={styles.checkmark} />
    </label>
  );
};
