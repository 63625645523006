import { makeAutoObservable, runInAction } from "mobx";
import { AuthService } from "../../shared/api/auth";

class SalesArchiveModel {
  sales = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    const { data } = await AuthService.fetchSalesArchive();

    if (!data) return;

    runInAction(() => {
      this.sales = data.data;
    });
  }
}

export const salesArchiveModel = new SalesArchiveModel();
