import React, { useRef, useState } from "react";
import { Button } from "../../../components/Button";
import { REACT_APP_API_URL } from "../../config";
import "./file-uploader.scss";

export const ImageUploader = ({
  title,
  // isPreviewMaxHeight = true,
  getImageOnLoad,
  existingImageUrl,
  onImageDelete,
}) => {
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const openFileUploader = () => inputRef.current.click();

  const maxUploadSizeLimitInMb = 3;

  const isFileLoaded = Boolean(file) || Boolean(existingImageUrl);

  return (
    <div className="textField column">
      {title !== "" && <p className="text title_text">{title}</p>}
      {isFileLoaded && (
        <div
          className="adaptive-image"
          style={{
            backgroundImage: `url(${
              existingImageUrl ? REACT_APP_API_URL + existingImageUrl : URL.createObjectURL(file)
            }) `,
          }}
        />
      )}

      {!isFileLoaded && <Button action={openFileUploader} className="upload-file-button" text="Загрузить" />}
      {isFileLoaded && (
        <Button
          action={() => {
            onImageDelete();
            setFile(null);
          }}
          text="Удалить"
        />
      )}
      <input
        ref={inputRef}
        onChange={(event) => {
          const [newFile] = event.currentTarget.files;
          event.currentTarget.value = null;

          if (newFile.size / 1024 / 1024 > maxUploadSizeLimitInMb) return;

          setFile(newFile);
          getImageOnLoad(newFile);
        }}
        style={{ opacity: 0, visibility: "hidden", height: 0 }}
        type="file"
      />
    </div>
  );
};
