import { apiInstance } from "../api";

export class CoursesService {
  static fetchAll(url) {
    return apiInstance.post("/courses/all.php", { url });
  }

  static fetchOne(courseId) {
    return apiInstance.post("/courses/get.php", { courseId });
  }

  static add(formData) {
    return apiInstance.post("/courses/add.php", formData, { headers: { "Content-Type": "multipart/form-data" } });
  }

  static editOne(formData) {
    return apiInstance.post("/courses/edit.php", formData, { headers: { "Content-Type": "multipart/form-data" } });
  }

  static search(search) {
    return apiInstance.post("/courses/search.php", { search });
  }

  static removeOne(courseId) {
    return apiInstance.post("/courses/remove.php", { courseId });
  }

  static removeImage(courseId, fileType) {
    return apiInstance.post("/courses/remove.file.php", { courseId, fileType });
  }

  static fetchSubscribers(courseId) {
    return apiInstance.post("/courses/subscribers.php", { courseId });
  }

  static addSubscriber(courseId, email, period) {
    return apiInstance.post("/courses/subscriber_add.php", { courseId, email, period });
  }

  static removeSubscriber(courseId, userId) {
    return apiInstance.post("/courses/subscriber_remove.php", { courseId, userId });
  }

  static fetchFeedbacks(courseId) {
    return apiInstance.post("/courses/feedbacks.php", { courseId });
  }

  static pay(courseId, fullName, period) {
    return apiInstance.post("/courses/pay.php", { courseId, fullName, period });
  }

  static fetchAllReviews(courseId) {
    return apiInstance.post("/courses/get.reviews.php", { courseId });
  }

  static getMyReview(courseId) {
    return apiInstance.post("/courses/get.my.review.php", { courseId });
  }

  static addReview(courseId, fullName, text) {
    return apiInstance.post("/courses/add.review.php", { courseId, fullName, text });
  }

  static editReview(courseId, fullName, text) {
    return apiInstance.post("/courses/edit.review.php", { courseId, fullName, text });
  }

  static deleteReview(courseId) {
    return apiInstance.post("/courses/delete.review.php", { courseId });
  }
}
