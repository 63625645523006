import React, { useState } from "react";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import TextField from "../../components/TextField";
import { userModel } from "../../entities/user";
import { observer } from "mobx-react-lite";
import { Button } from "../../components/Button";
import { YookassaService } from "../../shared/api/yookassa";
import { Link } from "../../shared/ui/link/link";
import { Checkbox } from "../../shared/ui/checkbox/checkbox";

export const Balance = observer(() => {
  const [credits, setCredits] = useState(1500);
  const [creditsError, setCreditsError] = useState("");
  const [formError, setFormError] = useState("");
  const [accepted, setAccepted] = useState(false);

  const replenish = async (event) => {
    event.preventDefault();

    const minPayAmount = 1000;
    const maxPayAmount = 99999;

    if (credits < minPayAmount) return setCreditsError(`Минимальная сумма для пополнения: ${minPayAmount} рублей`);
    if (credits > maxPayAmount) return setCreditsError(`Максимальная сумма для пополнения: ${maxPayAmount} рублей`);
    if (!accepted)
      return setFormError(
        "Для того, чтобы перейти к оплате Вы должны подтвердить и дать согласие с условиями публичной оферты"
      );

    setCreditsError("");

    const { data } = await YookassaService.createPayment(credits);

    window.location.replace(data.confirmation_url);
  };

  return (
    <MainTemplate title="Пополнение баланса">
      <p>
        У каждого аккаунта на DirectPro есть свой баланс. Средства хранятся в виде условной валюты - «кредитов», один
        кредит равен 1 рублю. Переведенные средства сразу отобразятся на вашем балансе. Кредиты могут быть использованы
        исключительно для подписок на курсы других авторов.
      </p>
      <br />
      <form onSubmit={replenish}>
        <TextField disabled title="E-mail покупателя:" value={userModel.user?.email} />
        <TextField error={creditsError} title="Количество кредитов" type="number" value={credits} set={setCredits} />
        <TextField
          style={{ marginBottom: 10 }}
          inputStyle={{ fontWeight: 900 }}
          disabled
          title="Сумма к оплате:"
          value={`${credits} руб.`}
          set={setCredits}
        />
        <Checkbox
          labelContent={
            <>
              Я понимаю, что кредиты могут быть использованы исключительно для моих подписок на курсы других авторов, и
              подтверждаю свое согласие с <Link to="/terms">Условиями публичной оферты</Link>
            </>
          }
          checked={accepted}
          onChange={(event) => setAccepted(event.currentTarget.checked)}
        />

        {formError && <div className="error">{formError}</div>}

        <Button type="submit" text="ПОПОЛНИТЬ" />
      </form>
    </MainTemplate>
  );
});
