import { makeAutoObservable, runInAction } from "mobx";
import cookie from "react-cookies";
import { AuthService } from "../../shared/api/auth";
import { replaceNullFields } from "../../shared/lib/object";

class UserModel {
  user = null;
  token = null;

  constructor() {
    makeAutoObservable(this);
  }

  async check() {
    const { data: user } = await AuthService.check();

    if (!user) return;

    runInAction(() => {
      this.user = replaceNullFields(user, "");
    });
  }

  async signUp(email, password) {
    const { data } = await AuthService.signUp(email, password);

    return data;
  }

  async signIn(email, password) {
    const { data } = await AuthService.signIn(email, password);

    if (data.success) {
      this.setToken(data.token);
      this.check();
    }

    return data;
  }

  async confirmEmail(hash) {
    const { data } = await AuthService.confirmEmail(hash);

    if (data.success) {
      this.setToken(data.token);
      this.check();
    }

    return data;
  }

  async changePassword(oldPassword, newPassword) {
    const { data } = await AuthService.changePassword(oldPassword, newPassword);

    return data;
  }

  async sendRecoverPasswordEmail(email) {
    const { data } = await AuthService.sendRecoverPasswordEmail(email);

    return data;
  }

  async recoverChangePassword(newPassword, hash) {
    const { data } = await AuthService.recoverChangePassword(newPassword, hash);

    return data;
  }

  async saveRequisites({ legalPerson, bic, correspondentAccount, tin, personFullName, accountNumber }) {
    const { data } = await AuthService.saveRequisites({
      legalPerson,
      bic,
      correspondentAccount,
      tin,
      personFullName,
      accountNumber,
    });

    return data;
  }

  signOut() {
    cookie.remove("token", { path: "/" });

    this.token = null;
  }

  loadToken() {
    const token = cookie.load("token");

    if (token === "undefined") return;

    this.token = token;
  }

  setToken(token) {
    cookie.save("token", token, { path: "/" });

    this.token = token;
  }

  changeUserField(key, value) {
    this.user[key] = value;
  }
}

export const userModel = new UserModel();
