import React from "react";
import { Link } from "react-router-dom";
import circleIcon from "../assets/circle.svg";
import greyLockIcon from "../assets/grey-lock.svg";

import { routeNamesWithParams } from "../../../shared/config";
import { userModel } from "../../../entities/user";
import { observer } from "mobx-react-lite";

import styles from "./styles.module.scss";

export const ArticleRow = observer(({ paymentType, article, name, courseId }) => {
  return (
    <div className={styles.row}>
      <span style={{ marginTop: 3, marginRight: 6 }}>
        {paymentType === 1 && article.access === 1 && (
          <img style={{ height: 7, width: 7, marginRight: 3, marginTop: 3, marginLeft: 4 }} src={circleIcon} alt="" />
        )}
        {paymentType === 1 && article.access === 2 && (
          <img style={{ height: 12, width: 12 }} src={greyLockIcon} alt="" />
        )}
        {paymentType === 3 && article.access === 2 && (
          <img style={{ height: 12, width: 12 }} src={greyLockIcon} alt="" />
        )}
      </span>

      <div style={{ marginTop: -2 }}>
        <Link
          className={styles.name}
          to={
            routeNamesWithParams({
              url: userModel.user?.url,
              courseId,
              articleId: article.id,
            }).article
          }
        >
          {name}
        </Link>

        <Link
          to={
            routeNamesWithParams({
              url: userModel.user?.url,
              courseId,
              articleId: article.id,
            }).adminCourseArticleEdit
          }
          className={styles.editLink}
        >
          ред.
        </Link>
      </div>
    </div>
  );
});
