import React, { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import Module from "../../components/Module";

import { Button } from "../../components/Button";
import { TextArea } from "../../components/TextField";
import { Video } from "../../shared/ui/video/video";
import { observer } from "mobx-react-lite";
import { userModel } from "../../entities/user";
import { articleModel } from "../../entities/article";
import { useEffect } from "react";
import { articlesModel } from "../../entities/articles";
import { Popup } from "../../shared/ui/popup/popup";

import { REACT_APP_API_URL, routeNames, routeNamesWithParams } from "../../shared/config";

import "./styles.scss";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const Article = observer(() => {
  const { url, courseId, articleId } = useParams();
  const [isShowAttachments, setShowAttachments] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    articleModel.get(courseId, articleId, url);
  }, [courseId, articleId, url]);

  if (articleModel.article?.error) return navigate(routeNamesWithParams({ url, courseId }).coursePay);

  if (!articleModel.article) return null;

  const { name, text, imageInArticle, video, module, redirectLink } = articleModel.article;

  const parsedModule = module ? JSON.parse(module) : null;

  return (
    <MainTemplate>
      <div className="wrapper-article column">
        {name && (
          <div className="course-breadcrumbs flex items-center my-16 relative">
            <Link to={routeNamesWithParams({ url }).courses}>
              <div className="text-blue">Курсы</div>
            </Link>
            <Link to={routeNamesWithParams({ url, courseId }).course}>
              <div className="text-blue" style={{ marginLeft: "15px" }}>
                Оглавление
              </div>
            </Link>
          </div>
        )}

        {articleModel.isLoaded ? (
          !name ? (
            <React.Fragment>
              <center>
                <h1 style={{ fontSize: 32, fontWeight: 700, marginBottom: 20 }}>Курс закрыт!</h1>
                <p>
                  У вас нет доступа к этому курсу. <br />
                  Автор курса дает доступ к нему лично.
                </p>
              </center>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="text text-title">{name}</div>
              <div className="text text-info">{text}</div>

              {redirectLink && (
                <button className="show-attachments-button" onClick={() => setShowAttachments(true)}>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.2929 4.70721C9.80991 6.22579 9.78909 8.66047 8.30204 10.1558C8.29924 10.1588 8.29594 10.1621 8.2929 10.1652L6.58665 11.8714C5.08174 13.3763 2.63335 13.3761 1.12868 11.8714C-0.376226 10.3668 -0.376226 7.9181 1.12868 6.41346L2.07082 5.47131C2.32067 5.22147 2.75093 5.38752 2.76383 5.7406C2.78029 6.19058 2.86098 6.64266 3.00987 7.07922C3.06029 7.22705 3.02426 7.39056 2.91382 7.50101L2.58153 7.8333C1.86993 8.5449 1.84761 9.70357 2.5522 10.4221C3.26375 11.1478 4.43329 11.1521 5.1503 10.4351L6.85655 8.72908C7.57233 8.01329 7.56934 6.85634 6.85655 6.14355C6.76258 6.04976 6.66792 5.97689 6.59398 5.92598C6.54168 5.89006 6.49849 5.84242 6.46786 5.78684C6.43724 5.73127 6.42002 5.66931 6.41759 5.60591C6.40754 5.3376 6.5026 5.06113 6.71461 4.84911L7.24919 4.31451C7.38937 4.17433 7.60928 4.15712 7.77183 4.27056C7.95798 4.40055 8.13235 4.54666 8.2929 4.70721ZM11.8713 1.1286C10.3666 -0.376099 7.91826 -0.376302 6.41335 1.1286L4.7071 2.83485C4.70406 2.8379 4.70076 2.8412 4.69796 2.84424C3.21094 4.33955 3.19009 6.77423 4.7071 8.29282C4.86764 8.45336 5.042 8.59946 5.22815 8.72944C5.3907 8.84288 5.61063 8.82564 5.75079 8.68548L6.28536 8.15089C6.49737 7.93887 6.59243 7.6624 6.58238 7.39409C6.57995 7.33069 6.56274 7.26873 6.53211 7.21316C6.50149 7.15758 6.4583 7.10994 6.40599 7.07402C6.33205 7.02311 6.2374 6.95024 6.14343 6.85645C5.43064 6.14365 5.42764 4.98671 6.14343 4.27092L7.84968 2.56492C8.56668 1.84792 9.7362 1.85223 10.4478 2.57787C11.1524 3.29643 11.1301 4.4551 10.4184 5.1667L10.0862 5.49899C9.97571 5.60944 9.93968 5.77295 9.99011 5.92078C10.139 6.35734 10.2197 6.80942 10.2361 7.2594C10.2491 7.61248 10.6793 7.77853 10.9292 7.52869L11.8713 6.58654C13.3762 5.08192 13.3762 2.63325 11.8713 1.1286Z"
                      fill="#479BFF"
                    />
                  </svg>
                  <span className="show-attachments-button__text">смотреть вложение</span>
                </button>
              )}
              <Popup isOpen={isShowAttachments} onClose={() => setShowAttachments(false)}>
                <h1 style={{ marginBottom: 4 }}>Внимание!</h1>
                <p style={{ marginBottom: 10 }}>Это сторонняя ссылка, которую указал автор курса:</p>
                <a
                  className="redirect-link"
                  href={redirectLink}
                  style={{ wordBreak: "break-word" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {redirectLink}
                </a>
              </Popup>

              {imageInArticle && (
                <img className="img" src={`${REACT_APP_API_URL}${imageInArticle}`} alt="" style={{ height: "100%" }} />
              )}
              {video && <Video key={video} link={video} />}

              <Module user={url} course={courseId} {...parsedModule} />

              {userModel.token && (
                <FeedbackForm
                  action={(msg) => {
                    articlesModel.feedback(courseId, articleId, msg);
                  }}
                />
              )}
            </React.Fragment>
          )
        ) : (
          <p>Статья загружается...</p>
        )}
      </div>
    </MainTemplate>
  );
});

const FeedbackForm = ({ action }) => {
  const [formIsOpened, setFormIsOpened] = useState(false);
  const [formIsSended, setFormIsSended] = useState(false);
  const [message, setMessage] = useState("");

  function handleSendForm() {
    if (message.length < 3) {
      alert("Сообщение не может быть пустым!");
      return;
    }

    action(message);
    setMessage("");
    setFormIsSended(true);
  }

  return (
    <div className="feedback_form">
      {!formIsSended && (
        <div
          className="feedback_form__anchor mt-16 text-blue text-center"
          onClick={() => {
            setFormIsOpened(true);
          }}
        >
          Обратная связь
        </div>
      )}

      {formIsOpened && (
        <div className="feedback_form__inputs">
          {formIsSended ? (
            <p className="feedback_form__anchor text-center">
              Ваше сообщение отправлено автору курса.
              <br />
              Спасибо за обратную связь!
            </p>
          ) : (
            <React.Fragment>
              <TextArea
                title="Что именно вам кажется не так в этом материале? Укажите проблему:"
                value={message}
                set={setMessage}
              />

              <Button text="Отправить" disabled={message.length < 3} action={handleSendForm} />
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};
