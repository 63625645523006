import { makeAutoObservable, runInAction } from "mobx";
import { CoursesService } from "../../shared/api/courses";

class CourseMyReviewModel {
  review = null;

  constructor() {
    makeAutoObservable(this);
  }

  async getMyReview(courseId) {
    const { data } = await CoursesService.getMyReview(courseId);

    if (!data) return;

    runInAction(() => {
      this.review = data.review;
    });

    return data;
  }

  async deleteMyReview(courseId) {
    const { data } = await CoursesService.deleteReview(courseId);

    if (data.success) {
      runInAction(() => {
        this.review = null;
      });
    }

    return data;
  }
}

export const courseMyReviewModel = new CourseMyReviewModel();
