import { makeAutoObservable, runInAction } from "mobx";
import { CoursesService } from "../../shared/api/courses";

class SubscribersModel {
  subscribers = { added: [], buyed: [] };
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getAll(courseId) {
    const { data } = await CoursesService.fetchSubscribers(courseId);

    if (!data) return;

    runInAction(() => {
      this.subscribers = data.subscribers;
      this.isLoaded = true;
    });
  }

  async add(courseId, email, period) {
    const { data } = await CoursesService.addSubscriber(courseId, email, period);

    return data;
  }

  async remove(courseId, userId) {
    const { data } = await CoursesService.removeSubscriber(courseId, userId);

    return data;
  }
}

export const subscribersModel = new SubscribersModel();
