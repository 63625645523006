import { REACT_APP_SELF_URL } from "../../shared/config";

export const FeedBack = ({ course_url, course, article, email, date, text }) => (
  <div className="feedback_row">
    <p>
      Ссылка:{" "}
      <a style={{ fontWeight: 500 }} className="text-blue" href={`/courses/${course}/${article}`}>
        {REACT_APP_SELF_URL}/{course_url}/{article}
      </a>
    </p>
    <p>
      Дата и время:{" "}
      <span style={{ fontWeight: 500 }}>
        {date.split(" ")[0].split("-")[2]}.{date.split(" ")[0].split("-")[1]}.{date.split(" ")[0].split("-")[0]} в{" "}
        {date.split(" ")[1].split(":")[0]}:{date.split(" ")[1].split(":")[1]}
      </span>
    </p>
    <p>
      Пользователь:{" "}
      <a
        style={{ fontWeight: 500 }}
        target="_blank"
        rel="noreferrer"
        className="text-blue"
        href={`mailto:${email}`}
        title="Связаться с пользователем по e-mail"
      >
        {email}
      </a>
    </p>
    <p className="mt-16">{text}</p>
  </div>
);
