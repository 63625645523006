import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ImageUploader } from "../../shared/ui/file-uploader/file-uploader";

import { Button } from "../../components/Button";

import InputRadio from "../../components/InputRadio";
import TextField, { TextArea } from "../../components/TextField";
import { ArticlesPopup } from "./articles-popup";
import { articlesModel } from "../../entities/articles";
import { articleModel } from "../../entities/article";

import { userModel } from "../../entities/user";
import { observer } from "mobx-react-lite";

import styles from "./styles.module.scss";
import { routeNamesWithParams } from "../../shared/config";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { Video } from "../../shared/ui/video/video";

const imagesInitialState = { file: null, link: "" };

export const CreateArticle = observer(() => {
  const navigate = useNavigate();
  const { courseId, articleId } = useParams();

  const isEditPage = Boolean(articleId);

  useEffect(() => {
    articlesModel.getAll(userModel.user?.url, courseId);
  }, [courseId]);

  const [whichPopupOpened, setWhichPopupOpened] = useState("");
  const [whichPopupButtonClicked, setWhichPopupButtonClicked] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [redirectLink, setRedirectLink] = useState("");
  const [imageForToc, setImageForToc] = useState(imagesInitialState);
  const [imageInArticle, setImageInArticle] = useState(imagesInitialState);
  const [video, setVideo] = useState("");
  const [module, setModule] = useState(0);
  const [access, setAccess] = useState(1);
  const [show, setShow] = useState(2);
  const [tocVideo, setTocVideo] = useState("");

  const [m_btns_btn1, setM_btns_btn1] = useState("");
  const [m_btns_btn2, setM_btns_btn2] = useState("");
  const [m_btns_btn3, setM_btns_btn3] = useState("");
  const [m_btns_btn4, setM_btns_btn4] = useState("");
  const [m_btns_btn5, setM_btns_btn5] = useState("");
  const [m_btns_btn6, setM_btns_btn6] = useState("");

  const [m_next, setM_next] = useState({});

  const [m_input_from, setM_input_from] = useState("");
  const [m_input_to, setM_input_to] = useState("");
  const [m_input_true, setM_input_true] = useState("");
  const [m_input_false, setM_input_false] = useState("");

  const [m_text_value, setM_text_value] = useState("");
  const [m_text_true, setM_text_true] = useState("");
  const [m_text_false, setM_text_false] = useState("");

  const [m_choose_1_article, setM_choose_1_article] = useState("");
  const [m_choose_1_text, setM_choose_1_text] = useState("");
  const [m_choose_2_article, setM_choose_2_article] = useState("");
  const [m_choose_2_text, setM_choose_2_text] = useState("");
  const [m_choose_3_article, setM_choose_3_article] = useState("");
  const [m_choose_3_text, setM_choose_3_text] = useState("");
  const [m_choose_4_article, setM_choose_4_article] = useState("");
  const [m_choose_4_text, setM_choose_4_text] = useState("");
  const [m_choose_5_article, setM_choose_5_article] = useState("");
  const [m_choose_5_text, setM_choose_5_text] = useState("");
  const [m_choose_6_article, setM_choose_6_article] = useState("");
  const [m_choose_6_text, setM_choose_6_text] = useState("");

  const [m_icon_changeID, setM_icon_changeID] = useState("");
  const [m_icon_redirectMode, setM_icon_redirectMode] = useState(0);
  const [m_icon_redirectArticleID, setM_icon_redirectArticleID] = useState("");
  const [m_icon_redirectCourseID, setM_icon_redirectCourseID] = useState("");

  function clearModuleData() {
    setM_btns_btn1("");
    setM_btns_btn2("");
    setM_btns_btn3("");
    setM_btns_btn4("");
    setM_btns_btn5("");
    setM_btns_btn6("");

    setM_next("");

    setM_input_from("");
    setM_input_to("");
    setM_input_true("");
    setM_input_false("");

    setM_choose_1_article("");
    setM_choose_1_text("");
    setM_choose_2_article("");
    setM_choose_2_text("");
    setM_choose_3_article("");
    setM_choose_3_text("");
    setM_choose_4_article("");
    setM_choose_4_text("");
    setM_choose_5_article("");
    setM_choose_5_text("");
    setM_choose_6_article("");
    setM_choose_6_text("");

    setM_icon_changeID("");
    setM_icon_redirectMode(0);
    setM_icon_redirectArticleID("");
    setM_icon_redirectCourseID("");
  }

  useEffect(() => {
    if (articleId) {
      clearModuleData();
      articleModel.get(courseId, articleId);
    }
  }, [courseId, articleId]);

  useEffect(
    () => {
      if (articleId && articleModel.article) {
        const _module = JSON.parse(articleModel.article?.module);

        setName(articleModel.article?.name);
        setText(articleModel.article?.text);
        setRedirectLink(articleModel.article?.redirectLink);
        setImageInArticle((prev) => ({ ...prev, link: articleModel.article?.imageInArticle }));
        setImageForToc((prev) => ({ ...prev, link: articleModel.article?.imageForToc }));
        setTocVideo(articleModel.article?.tocVideo);
        setVideo(articleModel.article?.video);
        setModule(_module.module);
        setAccess(articleModel.article?.access);
        setShow(articleModel.article?.view);

        switch (_module.module) {
          case 1:
            setM_btns_btn1(_module.m_btns_btn1 || "");
            setM_btns_btn2(_module.m_btns_btn2 || "");
            setM_btns_btn3(_module.m_btns_btn3 || "");
            setM_btns_btn4(_module.m_btns_btn4 || "");
            setM_btns_btn5(_module.m_btns_btn5 || "");
            setM_btns_btn6(_module.m_btns_btn6 || "");
            break;

          case 2:
            setM_next(_module.m_next);
            break;

          case 3:
            setM_input_from(_module.m_input_from);
            setM_input_to(_module.m_input_to);
            setM_input_true(_module.m_input_true);
            setM_input_false(_module.m_input_false);
            break;

          case 4:
            setM_choose_1_article(_module.m_choose_1_article || "");
            setM_choose_1_text(_module.m_choose_1_text || "");
            setM_choose_2_article(_module.m_choose_2_article || "");
            setM_choose_2_text(_module.m_choose_2_text || "");
            setM_choose_3_article(_module.m_choose_3_article || "");
            setM_choose_3_text(_module.m_choose_3_text || "");
            setM_choose_4_article(_module.m_choose_4_article || "");
            setM_choose_4_text(_module.m_choose_4_text || "");
            setM_choose_5_article(_module.m_choose_5_article || "");
            setM_choose_5_text(_module.m_choose_5_text || "");
            setM_choose_6_article(_module.m_choose_6_article || "");
            setM_choose_6_text(_module.m_choose_6_text || "");
            break;

          case 5:
            setM_icon_changeID(_module.m_icon_changeID || "");
            setM_icon_redirectMode(_module.m_icon_redirectMode || "");
            setM_icon_redirectArticleID(_module.m_icon_redirectArticleID || "");
            setM_icon_redirectCourseID(_module.m_icon_redirectCourseID || "");
            break;

          case 6:
            setM_text_value(_module.m_text_value);
            setM_text_true(_module.m_text_true);
            setM_text_false(_module.m_text_false);
            break;

          default:
            break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleModel.article]
  );

  async function sendForm() {
    if (name.length === 0) {
      alert("Название не может быть пустым!");
      return;
    }

    if (
      video !== "" &&
      !video.includes("www.youtube.com/watch?v=") &&
      !video.includes("kinescope.io/") &&
      !video.includes("youtu.be/")
    ) {
      alert("Вставьте пожалуйста корректную ссылку из Youtube и Kinescope");
      return;
    }

    let moduleJSON = {};

    switch (module) {
      case 1:
        moduleJSON = {
          module: 1,
          m_btns_btn1: m_btns_btn1,
          m_btns_btn2: m_btns_btn2,
          m_btns_btn3: m_btns_btn3,
          m_btns_btn4: m_btns_btn4,
          m_btns_btn5: m_btns_btn5,
          m_btns_btn6: m_btns_btn6,
        };
        break;

      case 2:
        moduleJSON = {
          module: 2,
          m_next: m_next,
        };
        break;

      case 3:
        moduleJSON = {
          module: 3,
          m_input_from: m_input_from,
          m_input_to: m_input_to,
          m_input_true: m_input_true,
          m_input_false: m_input_false,
        };
        break;

      case 4:
        moduleJSON = {
          module: 4,
          m_choose_1_article: m_choose_1_article,
          m_choose_1_text: m_choose_1_text,
          m_choose_2_article: m_choose_2_article,
          m_choose_2_text: m_choose_2_text,
          m_choose_3_article: m_choose_3_article,
          m_choose_3_text: m_choose_3_text,
          m_choose_4_article: m_choose_4_article,
          m_choose_4_text: m_choose_4_text,
          m_choose_5_article: m_choose_5_article,
          m_choose_5_text: m_choose_5_text,
          m_choose_6_article: m_choose_6_article,
          m_choose_6_text: m_choose_6_text,
        };
        break;

      case 5:
        moduleJSON = {
          module: 5,
          m_icon_changeID: m_icon_changeID,
          m_icon_redirectMode: m_icon_redirectMode,
          m_icon_redirectArticleID: m_icon_redirectArticleID,
          m_icon_redirectCourseID: m_icon_redirectCourseID,
        };
        break;

      case 6:
        moduleJSON = {
          module: 6,
          m_text_value: m_text_value,
          m_text_true: m_text_true,
          m_text_false: m_text_false,
        };
        break;

      default:
        break;
    }

    const formData = new FormData();

    formData.append("courseId", articlesModel.course?.id);
    formData.append("module", JSON.stringify(moduleJSON));
    formData.append("name", name);
    formData.append("text", text);
    formData.append("video", video);
    formData.append("redirectLink", redirectLink);
    formData.append("access", access);
    formData.append("view", show);
    formData.append("imageInArticle", imageInArticle.file);
    formData.append("imageForToc", imageForToc.file);
    // formData.append("tocVideo", tocVideo);

    if (isEditPage) {
      formData.append("articleId", articleId);

      articlesModel
        .edit(formData)
        .then(({ success }) => success && navigate(`/admin/courses/${articlesModel.course?.id}/articles`));
    } else {
      articlesModel
        .add(formData)
        .then(({ success }) => success && navigate(`/admin/courses/${articlesModel.course?.id}/articles`));
    }
  }

  function removeArticle() {
    window.confirm("Вы точно хотите удалить статью? Это действие нельзя отменить!") &&
      articlesModel
        .remove(courseId, articleId)
        .then(({ success }) => success && navigate(`/admin/courses/${articlesModel.course?.id}/articles`));
  }

  const ModuleBtns = (
    <React.Fragment>
      <p className="text title_text" style={{ marginBottom: 10 }}>
        НАСТРОЙКИ МОДУЛЯ:
      </p>
      <ArticlesPopup
        isOpen={whichPopupOpened === "moduleButton"}
        onClose={() => setWhichPopupOpened("")}
        onNameClick={(rowData) => {
          if (m_btns_btn1 === "") return setM_btns_btn1(rowData);
          if (m_btns_btn2 === "") return setM_btns_btn2(rowData);
          if (m_btns_btn3 === "") return setM_btns_btn3(rowData);
          if (m_btns_btn4 === "") return setM_btns_btn4(rowData);
          if (m_btns_btn5 === "") return setM_btns_btn5(rowData);
          if (m_btns_btn6 === "") return setM_btns_btn6(rowData);
        }}
      />

      {m_btns_btn1.id && (
        <div className={styles.articleField}>
          <button onClick={() => setM_btns_btn1("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_btns_btn1.courseId,
                articleId: m_btns_btn1.id,
              }).article
            }
          >
            {m_btns_btn1.name}
          </Link>
        </div>
      )}

      {m_btns_btn2.id && (
        <div className={styles.articleField}>
          <button onClick={() => setM_btns_btn2("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_btns_btn2.courseId,
                articleId: m_btns_btn2.id,
              }).article
            }
          >
            {m_btns_btn2.name}
          </Link>
        </div>
      )}

      {m_btns_btn3.id && (
        <div className={styles.articleField}>
          <button onClick={() => setM_btns_btn3("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_btns_btn3.courseId,
                articleId: m_btns_btn3.id,
              }).article
            }
          >
            {m_btns_btn3.name}
          </Link>
        </div>
      )}

      {m_btns_btn4.id && (
        <div className={styles.articleField}>
          <button onClick={() => setM_btns_btn4("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_btns_btn4.courseId,
                articleId: m_btns_btn4.id,
              }).article
            }
          >
            {m_btns_btn4.name}
          </Link>
        </div>
      )}

      {m_btns_btn5.id && (
        <div className={styles.articleField}>
          <button onClick={() => setM_btns_btn5("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_btns_btn5.courseId,
                articleId: m_btns_btn5.id,
              }).article
            }
          >
            {m_btns_btn5.name}
          </Link>
        </div>
      )}

      {m_btns_btn6.id && (
        <div style={{ marginBottom: 0 }} className={styles.articleField}>
          <button onClick={() => setM_btns_btn6("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_btns_btn6.courseId,
                articleId: m_btns_btn6.id,
              }).article
            }
          >
            {m_btns_btn6.name}
          </Link>
        </div>
      )}
      {!(m_btns_btn1.id && m_btns_btn2.id && m_btns_btn3.id && m_btns_btn4.id && m_btns_btn5.id && m_btns_btn6.id) && (
        <Button
          action={() => setWhichPopupOpened("moduleButton")}
          className={styles.openPopupButton}
          text="Выбрать статью"
        />
      )}
    </React.Fragment>
  );

  const ModuleNext = (
    <React.Fragment>
      <p className="text title_text" style={{ marginBottom: 10 }}>
        НАСТРОЙКИ МОДУЛЯ:
      </p>

      {m_next.id && (
        <div className={styles.articleField}>
          <button onClick={() => setM_next("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({ url: userModel.user?.url, courseId: m_next.courseId, articleId: m_next.id })
                .article
            }
          >
            {m_next.name}
          </Link>
        </div>
      )}
      <ArticlesPopup
        isOpen={whichPopupOpened === "moduleNext"}
        onClose={() => setWhichPopupOpened("")}
        onNameClick={(rowData) => setM_next(rowData)}
      />
      {!m_next.id && (
        <Button
          action={() => setWhichPopupOpened("moduleNext")}
          className={styles.openPopupButton}
          text="Выбрать статью"
        />
      )}
    </React.Fragment>
  );

  const ModuleInput = (
    <React.Fragment>
      <p className="text title_text" style={{ marginBottom: 10 }}>
        НАСТРОЙКИ МОДУЛЯ:
      </p>
      <div>
        <p style={{ marginBottom: 7 }} className="text">
          От:
        </p>

        <TextField
          type="number"
          style={{ marginBottom: 14 }}
          className={styles.miniTextField}
          value={m_input_from}
          set={setM_input_from}
        />

        <p style={{ marginBottom: 7 }} className="text">
          До:
        </p>

        <TextField
          style={{ marginBottom: 14 }}
          className={styles.miniTextField}
          type="number"
          value={m_input_to}
          set={setM_input_to}
        />
      </div>
      <div>
        <p style={{ marginBottom: 7 }} className="text">
          Правильно:
        </p>
        {!m_input_true.id && (
          <Button
            action={() => {
              setWhichPopupOpened("moduleInput");
              setWhichPopupButtonClicked("correct");
            }}
            className={styles.openPopupButton}
            styles={{ marginBottom: 10 }}
            text="Выбрать статью"
          />
        )}

        {m_input_true.id && (
          <div style={{ marginBottom: 10 }} className={styles.articleField}>
            <button onClick={() => setM_input_true("")} className={styles.articleFieldDeleteButton} />
            <Link
              className={styles.articleFieldLink}
              to={
                routeNamesWithParams({
                  url: userModel.user?.url,
                  courseId: m_input_true.courseId,
                  articleId: m_input_true.id,
                }).article
              }
            >
              {m_input_true.name}
            </Link>
          </div>
        )}
        <p style={{ marginBottom: 7 }} className="text">
          Неправильно:
        </p>
        {!m_input_false.id && (
          <Button
            action={() => {
              setWhichPopupOpened("moduleInput");
              setWhichPopupButtonClicked("uncorrect");
            }}
            className={styles.openPopupButton}
            text="Выбрать статью"
          />
        )}

        {m_input_false.id && (
          <div style={{ marginBottom: 0 }} className={styles.articleField}>
            <button onClick={() => setM_input_false("")} className={styles.articleFieldDeleteButton} />
            <Link
              className={styles.articleFieldLink}
              to={
                routeNamesWithParams({
                  url: userModel.user?.url,
                  courseId: m_input_false.courseId,
                  articleId: m_input_false.id,
                }).article
              }
            >
              {m_input_false.name}
            </Link>
          </div>
        )}
      </div>

      <ArticlesPopup
        isOpen={whichPopupOpened === "moduleInput"}
        onClose={() => setWhichPopupOpened("")}
        onNameClick={(rowData) => {
          if (whichPopupButtonClicked === "correct") return setM_input_true(rowData);
          if (whichPopupButtonClicked === "uncorrect") return setM_input_false(rowData);
        }}
      />
    </React.Fragment>
  );

  const ModuleText = (
    <React.Fragment>
      <p className="text title_text" style={{ marginBottom: 10 }}>
        НАСТРОЙКИ МОДУЛЯ:
      </p>
      <div>
        <p style={{ marginBottom: 7 }} className="text">
          Текст:
        </p>

        <TextArea isShowSymbols={false} value={m_text_value} set={setM_text_value} />
      </div>

      <p style={{ marginBottom: 7 }} className="text">
        Правильно:
      </p>
      {!m_text_true.id && (
        <Button
          styles={{ marginBottom: 10 }}
          action={() => {
            setWhichPopupOpened("moduleText");
            setWhichPopupButtonClicked("correct");
          }}
          className={styles.openPopupButton}
          text="Выбрать статью"
        />
      )}

      {m_text_true.id && (
        <div style={{ marginBottom: 10 }} className={styles.articleField}>
          <button onClick={() => setM_text_false("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_text_true.courseId,
                articleId: m_text_true.id,
              }).article
            }
          >
            {m_text_true.name}
          </Link>
        </div>
      )}

      <p style={{ marginBottom: 7 }} className="text">
        Неправильно:
      </p>
      {!m_text_false.id && (
        <Button
          action={() => {
            setWhichPopupOpened("moduleText");
            setWhichPopupButtonClicked("uncorrect");
          }}
          className={styles.openPopupButton}
          text="Выбрать статью"
        />
      )}

      {m_text_false.id && (
        <div style={{ marginBottom: 0 }} className={styles.articleField}>
          <button onClick={() => setM_text_false("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_text_false.courseId,
                articleId: m_text_false.id,
              }).article
            }
          >
            {m_text_false.name}
          </Link>
        </div>
      )}

      <ArticlesPopup
        isOpen={whichPopupOpened === "moduleText"}
        onClose={() => setWhichPopupOpened("")}
        onNameClick={(rowData) => {
          if (whichPopupButtonClicked === "correct") return setM_text_true(rowData);
          if (whichPopupButtonClicked === "uncorrect") return setM_text_false(rowData);
        }}
      />
    </React.Fragment>
  );

  const moduleChooseItems = [
    {
      id: 1,
      articleValue: m_choose_1_article,
      setArticle: setM_choose_1_article,
      textValue: m_choose_1_text,
      setText: setM_choose_1_text,
    },
    {
      id: 2,
      articleValue: m_choose_2_article,
      setArticle: setM_choose_2_article,
      textValue: m_choose_2_text,
      setText: setM_choose_2_text,
    },
    {
      id: 3,
      articleValue: m_choose_3_article,
      setArticle: setM_choose_3_article,
      textValue: m_choose_3_text,
      setText: setM_choose_3_text,
    },
    {
      id: 4,
      articleValue: m_choose_4_article,
      setArticle: setM_choose_4_article,
      textValue: m_choose_4_text,
      setText: setM_choose_4_text,
    },
    {
      id: 5,
      articleValue: m_choose_5_article,
      setArticle: setM_choose_5_article,
      textValue: m_choose_5_text,
      setText: setM_choose_5_text,
    },
    {
      id: 6,
      articleValue: m_choose_6_article,
      setArticle: setM_choose_6_article,
      textValue: m_choose_6_text,
      setText: setM_choose_6_text,
    },
  ];

  const ModuleChoose = (
    <React.Fragment>
      <p className="text title_text" style={{ marginBottom: 10 }}>
        НАСТРОЙКИ МОДУЛЯ:
      </p>
      {moduleChooseItems.map(({ id, articleValue, setArticle, textValue, setText }) => {
        if (articleValue === "") return null;

        return (
          <div style={{ marginBottom: 10 }} key={id}>
            {articleValue.id && (
              <div style={{ marginBottom: 10 }} className={styles.articleField}>
                <button onClick={() => setArticle("")} className={styles.articleFieldDeleteButton} />
                <Link
                  className={styles.articleFieldLink}
                  to={
                    routeNamesWithParams({
                      url: userModel.user?.url,
                      courseId: articleValue.courseId,
                      articleId: m_btns_btn3.id,
                    }).article
                  }
                >
                  {articleValue.name}
                </Link>
              </div>
            )}

            {articleValue.id && <TextArea isShowSymbols={false} value={textValue} set={setText} />}
          </div>
        );
      })}
      <ArticlesPopup
        isOpen={whichPopupOpened === "moduleChoose"}
        onClose={() => setWhichPopupOpened("")}
        onNameClick={(rowData) => {
          if (m_choose_1_article === "") return setM_choose_1_article(rowData);
          if (m_choose_2_article === "") return setM_choose_2_article(rowData);
          if (m_choose_3_article === "") return setM_choose_3_article(rowData);
          if (m_choose_4_article === "") return setM_choose_4_article(rowData);
          if (m_choose_5_article === "") return setM_choose_5_article(rowData);
          if (m_choose_6_article === "") return setM_choose_6_article(rowData);
        }}
      />
      {!(
        moduleChooseItems[0].articleValue.id &&
        moduleChooseItems[1].articleValue.id &&
        moduleChooseItems[2].articleValue.id &&
        moduleChooseItems[3].articleValue.id &&
        moduleChooseItems[4].articleValue.id &&
        moduleChooseItems[5].articleValue.id
      ) && (
        <Button
          action={() => {
            setWhichPopupOpened("moduleChoose");
          }}
          className={styles.openPopupButton}
          styles={{ marginBottom: 10 }}
          text="Выбрать статью"
        />
      )}
    </React.Fragment>
  );

  const ModuleIcon = (
    <React.Fragment>
      <p className="text title_text" style={{ marginBottom: 10 }}>
        НАСТРОЙКИ МОДУЛЯ:
      </p>
      {/* <TextField
        type="number"
        value={m_icon_changeID}
        set={setM_icon_changeID}
        className={styles.miniTextField}
      /> */}
      {/* <InputRadio
        title="Куда перейти с этой статьи?"
        values={["На другую статью", "На страницу курса"]}
        selected={m_icon_redirectMode}
        set={setM_icon_redirectMode}
      /> */}
      поменять крестик на галочку
      <br />
      <br />
      {m_icon_changeID.id && (
        <div style={{ marginBottom: 10 }} className={styles.articleField}>
          <button onClick={() => setM_icon_changeID("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_icon_changeID.courseId,
                articleId: m_icon_changeID.id,
              }).article
            }
          >
            {m_icon_changeID.name}
          </Link>
        </div>
      )}
      {!m_icon_changeID.id && (
        <Button
          action={() => {
            setWhichPopupOpened("moduleChoose");
            setWhichPopupButtonClicked("changeIcon");
          }}
          className={styles.openPopupButton}
          styles={{ marginBottom: 10 }}
          text="Выбрать статью"
        />
      )}
      куда перенаправить
      <br />
      <br />
      {m_icon_redirectArticleID.id && (
        <div style={{ marginBottom: 10 }} className={styles.articleField}>
          <button onClick={() => setM_icon_redirectArticleID("")} className={styles.articleFieldDeleteButton} />
          <Link
            className={styles.articleFieldLink}
            to={
              routeNamesWithParams({
                url: userModel.user?.url,
                courseId: m_icon_redirectArticleID.courseId,
                articleId: m_icon_redirectArticleID.id,
              }).article
            }
          >
            {m_icon_redirectArticleID.name}
          </Link>
        </div>
      )}
      {!m_icon_redirectArticleID.id && (
        <Button
          action={() => {
            setWhichPopupOpened("moduleChoose");
            setWhichPopupButtonClicked("redirectToArticle");
          }}
          className={styles.openPopupButton}
          styles={{ marginBottom: 10 }}
          text="Выбрать статью"
        />
      )}
      <ArticlesPopup
        isOpen={whichPopupOpened === "moduleChoose"}
        onClose={() => setWhichPopupOpened("")}
        onNameClick={(rowData) => {
          if (whichPopupButtonClicked === "changeIcon") return setM_icon_changeID(rowData);
          if (whichPopupButtonClicked === "redirectToArticle") return setM_icon_redirectArticleID(rowData);
        }}
      />
      {/* {m_icon_redirectMode > 0 && (
        <TextField
          type="number"
          title={`id ${m_icon_redirectMode === 1 ? "статьи" : "курса"}`}
          value={m_icon_redirectMode === 1 ? m_icon_redirectArticleID : m_icon_redirectCourseID}
          set={m_icon_redirectMode === 1 ? setM_icon_redirectArticleID : setM_icon_redirectCourseID}
          placeholder={
            articleId > 0 && name === "" ? "Загрузка..." : `id ${m_icon_redirectMode === 1 ? "статьи" : "курса"}`
          }
        />
      )} */}
    </React.Fragment>
  );

  const MODULES = {
    1: ModuleBtns,
    2: ModuleNext,
    3: ModuleInput,
    4: ModuleChoose,
    5: ModuleIcon,
    6: ModuleText,
  };

  useEffect(() => {
    if (articlesModel.course?.paymentType === 3) {
      setAccess(2);
    }
  }, []);

  return (
    <MainTemplate>
      <div className="column">
        <div className="navbar row items-center">
          <Link to="/admin/courses">
            <p className="buttonText_text">Курсы/</p>
          </Link>
          <Link
            to={
              routeNamesWithParams({ url: userModel.user?.url, courseId: articlesModel.course?.id }).adminCourseArticles
            }
            className="buttonText_text"
          >
            Статьи
          </Link>
        </div>

        <ImageUploader
          getImageOnLoad={(file) => setImageForToc((prev) => ({ ...prev, file }))}
          isPreviewMaxHeight={false}
          existingImageUrl={imageForToc.link}
          onImageDelete={() => {
            if (isEditPage) {
              articlesModel.removeImage(courseId, articleId, "imageForToc");
              setImageForToc(imagesInitialState);
            }
          }}
          title="Картинка к оглавлению статьи (до 3 МБ. ширина: 566px):"
        />

        {/* <TextField
          helpButtonProps={{
            popupContent: <Video link="https://www.youtube.com/watch?v=8_DazhgI57w" />,
          }}
          title="Видео краткого обзора этой статьи (ссылка с Youtube, Kinescope):"
          value={tocVideo}
          set={setTocVideo}
        /> */}

        <TextField isShowSymbols title="Название статьи:" value={name} set={setName} />

        <TextArea isShowSymbols title="Описание статьи:" value={text} set={setText} />

        <TextField
          // helpButtonProps={{
          //   popupContent: <Video link="https://www.youtube.com/watch?v=8_DazhgI57w" />,
          // }}
          title="Ссылка на файл, облако:"
          value={redirectLink}
          set={setRedirectLink}
        />

        <TextField
          // helpButtonProps={{
          //   popupContent: <Video link="https://www.youtube.com/watch?v=8_DazhgI57w" />,
          // }}
          title="Ссылка на видео внутри статьи (Youtube, Kinescope):"
          value={video}
          set={setVideo}
        />

        <ImageUploader
          getImageOnLoad={(file) => setImageInArticle((prev) => ({ ...prev, file }))}
          isPreviewMaxHeight={false}
          existingImageUrl={imageInArticle.link}
          onImageDelete={() => {
            if (isEditPage) {
              articlesModel.removeImage(courseId, articleId, "imageInArticle");
              setImageInArticle(imagesInitialState);
            }
          }}
          title="Картинка к статье (до 3 МБ. ширина: 566px):"
        />

        <>
          {articlesModel.course?.paymentType !== 3 && (
            <InputRadio
              title="ДОСТУП К СТАТЬЕ"
              // videoLinks={[
              //   "https://www.youtube.com/watch?v=8_DazhgI57w",
              //   "https://www.youtube.com/watch?v=8_DazhgI57w",
              // ]}
              values={["Открыто", "Платно"]}
              selected={access}
              set={setAccess}
            />
          )}

          <InputRadio
            title="ОТОБРАЖЕНИЕ"
            // videoLinks={["https://www.youtube.com/watch?v=8_DazhgI57w", "https://www.youtube.com/watch?v=8_DazhgI57w"]}
            values={["Показать статью в оглавлении курса", "Скрыть статью"]}
            selected={show}
            set={setShow}
          />
        </>

        <InputRadio
          className={styles.moduleInputRadio}
          title="МОДУЛИ"
          // videoLinks={[
          //   "https://www.youtube.com/watch?v=8_DazhgI57w",
          //   "https://www.youtube.com/watch?v=8_DazhgI57w",
          //   "https://www.youtube.com/watch?v=8_DazhgI57w",
          //   "https://www.youtube.com/watch?v=8_DazhgI57w",
          //   "https://www.youtube.com/watch?v=8_DazhgI57w",
          //   "https://www.youtube.com/watch?v=8_DazhgI57w",
          // ]}
          values={["Кнопки", "Далее", "Поле ввода", "Выбор варианта ответа", "Иконка", "Текст"]}
          selected={module}
          set={setModule}
        />

        {MODULES[module]}

        {Boolean(module) && (
          <div className="row pad">
            <Button text="Сохранить" action={sendForm} />
            {isEditPage && <Button text="Удалить" action={removeArticle} />}
          </div>
        )}
      </div>
    </MainTemplate>
  );
});
