import { makeAutoObservable, runInAction } from "mobx";
import { CoursesService } from "../../shared/api/courses";

class CoursesModel {
  courses = [];
  isLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getAll(url) {
    const { data } = await CoursesService.fetchAll(url);

    if (!data) return;

    runInAction(() => {
      this.courses = data.data;
      this.isLoaded = true;
    });
  }
}

export const coursesModel = new CoursesModel();
