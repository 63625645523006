import React from "react";
import { HelpButton } from "../../shared/ui/help-button/help-button";
import "./TextField.scss";
import styles from "./styles.module.scss";

const TextField = ({
  style,
  className,
  type = "text",
  value,
  set,
  placeholder = "",
  title = "",
  color = "black",
  isShowSymbols,
  disabled,
  error,
  inputStyle,
  helpButtonProps,
  maxSymbolsCount = 300,
}) => {
  return (
    <div style={style} className={`textField column ${className ? className : ""}`}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {title !== "" && (
          <div style={{ marginRight: 5, marginBottom: 7 }} className="text title_text">
            <span style={{ marginRight: 5 }}>{title}</span>

            {helpButtonProps && <HelpButton {...helpButtonProps} />}
          </div>
        )}

        {isShowSymbols && (
          <span style={{ fontSize: 14, color: "rgb(157 157 157)", marginLeft: "auto" }}>
            {value.length}/{maxSymbolsCount}
          </span>
        )}
      </div>
      <input
        disabled={disabled}
        type={type}
        value={value}
        onChange={(e) => {
          if (e.target.value.length <= maxSymbolsCount) set(e.target.value);
        }}
        placeholder={placeholder}
        style={{ ...inputStyle, color: color }}
      />
      <div className={styles.error}>{error}</div>
    </div>
  );
};

export const TextArea = ({ style, value, set, placeholder = "", title = "", isShowSymbols }) => {
  const maxSymbolsCount = 1500;

  return (
    <div style={style} className="textField column">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {title !== "" && (
          <div style={{ marginBottom: 7 }} className="text title_text">
            {title}
          </div>
        )}

        {isShowSymbols && (
          <span style={{ fontSize: 14, color: "rgb(157 157 157)", marginLeft: "auto" }}>
            {value.length}/{maxSymbolsCount}
          </span>
        )}
      </div>
      <textarea
        value={value}
        onChange={(e) => {
          if (e.target.value.length <= maxSymbolsCount) set(e.target.value);
        }}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextField;
