import React from "react";
import { REACT_APP_SELF_URL, routeNames } from "../../shared/config";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const Terms = () => {
  return (
    <MainTemplate>
      <div className="sign sign_up column justify-center items-center">
        <h1 className="text text-center">Публичная оферта</h1>

        <p className="text">
          Настоящее Соглашение определяет права и обязанности Лицензиара и Лицензиата при использовании Информационного
          сервиса «{REACT_APP_SELF_URL}». Соглашение адресовано неопределенному кругу лиц и является публичной офертой в
          соответствии с п. 2 ст. 437 ГК РФ.
          <br />
          <br />
          Безусловным акцептом Лицензиатом Соглашения является Регистрация на Сайте путем заполнения Регистрационной
          формы либо проставление отметки “Регистрируясь, вы принимаете политику конфиденциальности, условия публичной
          оферты”.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>1. ТЕРМИНЫ</span>
          <br />
          <br />
          Стороны используют указанные термины в следующем значении:
          <br />
          Законодательство — действующее законодательство Российской Федерации.
          <br />
          Информационный сервис «{REACT_APP_SELF_URL}» (Сервис) — сервис доступный на Сайте Лицензиара, позволяющий
          Лицензиату создавать онлайн курсы. Курсы могут быть открытые, закрытые и платные. Лицензиат может устанавливать
		  самостоятельно цену на платную подписку курсов, которые он создает, согласно тарифу указанному по этому адресу: https://directpro.net/tariff   
		  Сервис включает в себя интерфейс и иные элементы, необходимые для надлежащего
          функционирования. Все права на Сервис (и входящие в него элементы) принадлежат Лицензиару в полном объеме. Ни
          одно из положений настоящего Соглашения не может трактоваться как передача исключительных прав на Сервис (его
          отдельные элементы) Лицензиату.
          <br />
          Лицензиар — “ИП Горшков Петр Владимирович”, являющийся стороной настоящего Соглашения, предоставляющий
          Лицензиату доступ к Информационному сервису.
          <br />
          Лицензиат — сторона настоящего Соглашения, акцептовавшая Соглашение.
          <br />
          Личный кабинет Лицензиата (Личный кабинет) — закрытая часть Сервиса, представляющая собой совокупность
          защищенных страниц на Сайте, создаваемых при Регистрации Лицензиата, посредством которого осуществляется
          использование Сервиса и взаимодействие Сторон. Доступ к Личному профилю осуществляется Лицензиатом
          посредством ввода Логина и пароля.
          <br />
          <br />
          Неисключительная лицензия — неисключительное, возмездное право использования Сервиса, в порядке и способами
          указанными в настоящем Соглашении.
          <br />
          Партнерская программа Лицензиата — программа сотрудничества Лицензиата и Партнеров Лицензиата, по
          осуществлению последними рекламы услуг и продуктов Лицензиата за вознаграждение.
          <br />
          Партнеры Лицензиара — физические (юридические) лица, индивидуальные предприниматели, регистрирующиеся на Сайте
          для взаимодействия с Лицензиаром.
          <br />
          Партнеры Лицензиата — физические (юридические) лица, индивидуальные предприниматели, регистрирующиеся на Сайте
          для взаимодействия с Лицензиатом, либо уже зарегистрированные на Сайте.
          <br />
          Регистрационная форма — электронная форма, расположенная на Сайте, которую Лицензиат должен заполнить для
          прохождения Регистрации на Сайте.
          <br />
          Регистрация — заполнение Лицензиатом Регистрационной формы, расположенной на Сайте, путем указания необходимых
          сведений и выбора Логина и пароля.
          <br />
          Сайт Лицензиара (Сайт) — сайт, расположенный в сети Интернет по адресу {REACT_APP_SELF_URL}.
          <br />
          Соглашение — настоящее Соглашение, включая все приложения и правила, указанные в настоящем Соглашении либо
          прямо поименованные как приложения к настоящему Соглашению.
          <br />
          Тарифы Лицензиара (Тарифы) - прайс-лист Лицензиара, расположенный в сети Интернет по адресу: https://directpro.net/tariff  
          Функционал Сервиса, доступный Лицензиату, зависит от Тарифа и указан в
          описании Тарифа. ​<br />
          <br />
          <span style={{ fontWeight: 500 }}>2. ПРЕДМЕТ СОГЛАШЕНИЯ. ОБЩИЕ ТРЕБОВАНИЯ</span>
          <br />
          <br />
          2.1. Предметом настоящего Соглашения является передача Лицензиаром Неисключительной лицензии Лицензиату на
          использования Сервиса.
          <br />
          2.2. Все условия, оговоренные далее, относятся как к Сервису в целом, так и ко всем его компонентам в
          отдельности.
          <br />
          2.3. Территория передаваемых прав - все страны мира.
          <br />
          2.4. Срок передаваемых прав - ограничен сроком действия Тарифа, выбранного Лицензиатом.
          <br />
          2.5. Изменение Соглашения. Лицензиар имеет право в любой момент изменять настоящее Соглашение и все приложения
          к нему (полностью или в части) в одностороннем порядке, без предварительного согласования с Лицензиатом. Все
          изменения вступают в силу на следующий календарный день после размещения на Сайте.
          <br />
          2.5.1. Лицензиат обязуется самостоятельно следить за изменениями настоящего Соглашения и приложений к нему,
          путем периодического ознакомления с актуальной редакцией не менее одного раза в месяц. Лицензиат
          самостоятельно отвечает за любые последствия, наступившие в связи с неознакомлением с настоящим Соглашением
          (приложениями к Соглашению).
          <br />
          2.5.2. В случае, если Лицензиат не согласен с каким-либо положением Соглашения и (или) приложений к нему, он
          должен незамедлительно прекратить использование Сайта и Сервиса.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>3. РЕГИСТРАЦИЯ</span>
          <br />
          <br />
          3.1. Для использования полного функционала Сервиса Лицензиат обязан пройти процедуру Регистрации. Регистрация
          производится путем заполнения Лицензиатом Регистрационной формы.
          <br />
          3.2. При Регистрации Лицензиат указывает следующие данные: Адрес электронной почты, логин и пароль.
          <br />
          3.3. Регистрация считается оконченной в момент заполнения Регистрационной формы и отправки ее Лицензиару.
          После отправки Регистрационной формы на электронную почту Лицензиата приходит письмо, подтверждающее
          прохождение Регистрации.
          <br />
          3.4. Лицензиат гарантирует, что данные, указанные при Регистрации, являются актуальными и достоверными и не
          относятся к третьим лицам.
          <br />
          3.5. В случае, если при Регистрации Лицензиат действует от имени юридического лица (индивидуального
          предпринимателя) он гарантирует, что имеет соответствующие полномочия на акцепт настоящего Соглашения.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>
            4. ПОРЯДОК И СПОСОБЫ ИСПОЛЬЗОВАНИЯ СЕРВИСА. ПОРЯДОК ПРЕДОСТАВЛЕНИЯ ДОСТУПА К СЕРВИСУ. ОГРАНИЧЕНИЯ
          </span>
          <br />
          <br />
          4.1. В рамках Неисключительной лицензии Лицензиат вправе использовать Сервис следующими способами:
          <br />
          4.1.1. получение доступа к Сервису посредством Сайта Лицензиара (без скачивания дистрибутива на устройство
          Лицензиата);
          <br />
          4.1.2. использование Сервиса по целевому назначению для собственных нужд Лицензиата, в том числе при ведении
          предпринимательской деятельности;
          <br />
          4.2. При использовании Сервиса Лицензиату запрещается:
          <br />
          4.2.1. осуществлять модификацию программного обеспечения, входящего в Сервис, в том числе изменять,
          декомпилировать, дизассемблировать, дешифровать и производить иные действия с объектным кодом программного
          обеспечения;
          <br />
          4.2.2. использовать Сервис способом, прямо не предусмотренным настоящим Соглашением;
          <br />
          4.2.3. распространять, копировать или иным образом осуществлять обнародование программного обеспечения,
          входящего в Сервис;
          <br />
          4.2.4. пытаться обойти технические ограничения в программном обеспечении, входящем в Сервис;
          <br />
          4.2.5. осуществлять использование Сервиса или его частей за пределами срока действия настоящего Соглашения;
          <br />
          4.2.6. осуществлять посредством Сервиса рассылку без получения предварительного согласия получателей рассылки;
          <br />
          4.2.7. осуществлять рассылку без получения предварительного согласия получателей рассылки в которой
          размещается ссылка на сайт, на котором используется любой элемент Сервиса.
          <br />
          4.2.8. Создавать веб-страницы и осуществлять рассылку, содержащую в составе и/или заголовке письма или
          страницы заведомо ложную информацию (быстрый, легкий, не требующий вложений денег и времени заработок,
          финансовые пирамиды, хайпы, безрисковые операции на биржах и т.д.) или информацию, которая может ввести
          получателя в заблуждение (ложные письма о незавершенных регистрациях, заказах, выплатах, фишинговые письма и
          т.д.).
          <br />
          4.3. Доступ к Сервису осуществляется круглосуточно (за исключением случаев, когда проводятся технические
          работы). Лицензиар не несет ответственности в случае, если доступ к Сервису ограничен в связи с отсутствием
          доступа Лицензиата к сети Интернет.
          <br />
          4.4. При использовании Сервиса Лицензиат получает доступ ко всем справочным материалам, размещенным на
          Сервисе.
          <br />
          4.5. Лицензиат не имеет право передавать третьим лицам право на использование Сервиса (на возмездной или
          безвозмездной основе) в том числе не имеет право передавать третьим лицам Логин и пароль.
          <br />
          4.6. Доступ к функционалу Сервиса предоставляется Лицензиату в Личном профиле.
          <br />
          4.7. Права на Сервис считаются переданными Лицензиату в момент предоставления доступа в соответствии с п. 4.6.
          настоящего Соглашения.
          <br />
          4.8. Посредством Сервиса запрещается использовать (размещать) материалы, содержащие:
          <br />
          4.8.1. нецензурные или бранные слова или словосочетания, порнографические изображения и тексты или сцены
          сексуального характера с участием несовершеннолетних;
          <br />
          4.8.2. угрозы, призывы к насилию и совершению противоправных действий, нарушению законодательства;
          <br />
          4.8.3. элементы насилия, жестокости, расовой, межнациональной или межрелигиозной розни, а также ссылки на
          такие материалы;
          <br />
          4.8.4. нацистскую атрибутику или символику;
          <br />
          4.8.5. пропаганду преступной деятельности, руководства по совершению противоправных действий;
          <br />
          4.8.6. материал, содержащий угрозы, который дискредитирует, оскорбляет, порочит честь и достоинство или
          деловую репутацию или нарушает неприкосновенность частной жизни других Пользователей или третьих лиц;
          <br />
          4.8.7. материалы нарушающие действующее законодательство РФ.
          <br />
          4.9. Лицензиат гарантирует, что посредством Сервиса размещает (использует) материалы (информацию),
          исключительные права на которые принадлежат Лицензиату.
          <br />
          4.10. В случае нарушения соглашения - Лицензиар имеет право заблокировать и удалить
          аккаунт Лицензиата. Лицензиар не несет ответственности за убытки (ущерб), причиненные Лицензиату блокировкой
          или удалением аккаунта.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>5. ПРАВА И ОБЯЗАННОСТИ СТОРОН</span>
          <br />
          <br />
          5.1. Лицензиар обязан:
          <br />
          5.1.1. предоставить Лицензиату доступ к Сервису;
          <br />
          5.1.2. в разумные сроки устранять сбои в работе Сервиса, возникшие по вине Лицензиара.
          <br />
          5.2. Лицензиат обязан:
          <br />
          5.2.1. использовать Сервис в рамках, предусмотренных разделом 4 настоящего Соглашения;
          <br />
          5.2.2. не передавать третьим лицам Логин и пароль и обеспечивать конфиденциальность Логина и пароля. В случае
          утери Логина и пароля и (или) нарушения конфиденциальности Логина и пароля незамедлительно уведомить об этом
          Лицензиара;
          <br />
          5.3. Лицензиар имеет право:
          <br />
          5.3.1. в одностороннем порядке расторгнуть Соглашение и (или) блокировать доступ Лицензиата к Сервису или его
          части в случаях нарушения Лицензиатом Законодательства и (или) условий Соглашения (приложений к нему).
          Лицензиар не несет ответственности за убытки (ущерб), возникшие у Лицензиата в связи с расторжением Соглашения
          и (или) блокировкой. Лицензиар не обязан сообщать Лицензиату причины расторжения Соглашения и (или)
          блокировки;
          <br />
          5.3.2. проводить профилактические работы, для чего временно приостанавливать доступ к Сервису. О
          профилактических работах Лицензиар уведомляет Лицензиата путем оповещения на Сайте или с помощью e-mail
          рассылки;
          <br />
          5.3.3. использовать наименование Лицензиата, а также его товарные знаки (знаки обслуживания) и логотипы
          Лицензиата для целей рекламы услуг Лицензиара.
          <br />
          5.3.4. модифицировать или выпускать обновления, добавлять новые свойства
          или функциональные возможности программного обеспечения, повышающие его работоспособность или иным способом
          улучшающие его характеристики, в том числе в целях удовлетворения требований законодательства Российской
          Федерации.
          <br />
          5.4. Лицензиат имеет право:
          <br />
          5.4.1. на получение доступа к Сервису в соответствии с условиями настоящего Соглашения.
          <br />
          5.5. При создании рассылок Пользователям Лицензиат обязуется не нарушать действующее законодательство РФ и не
          направлять сообщений лицам, не давшим согласие на их получение.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>6. ПЕРСОНАЛЬНЫЕ ДАННЫЕ. ОБЕСПЕЧЕНИЕ КОНФИДЕНЦИАЛЬНОСТИ</span>
          <br />
          <br />
          6.1. Лицензиат (физическое лицо) дает согласие на обработку Лицензиаром персональных данных Лицензиата,
          указываемых при Регистрации адрес электронной почты.
          <br />
          6.1.1. настоящее согласие действует бессрочно, срок хранения персональных данных Лицензиата не ограничен.
          <br />
          6.1.2. при обработке персональных данных Лицензиата Лицензиар руководствуется Федеральным законом РФ «О
          персональных данных» № 152-ФЗ от 29.07.2006.
          <br />
          6.2. Лицензиат имеет право отозвать свое согласие посредством составления соответствующего письменного
          документа, который может быть направлен Лицензиатом в адрес Лицензиара по почте заказным письмом с
          уведомлением о вручении либо вручен лично под расписку представителю Лицензиара. В случае получения
          письменного заявления об отзыве настоящего согласия на обработку персональных данных Лицензиар обязан
          прекратить их обработку.
          <br />
          6.3. В результате сбоя, технических неисправностей, действий третьих лиц, в том числе (но не ограничиваясь)
          вирусной или хакерской атаки, данные Лицензиата, размещенные в Сервисе, могут стать доступны третьим лицам.
          Лицензиат осознает это и обязуется не предъявлять требований к Лицензиару о возмещении убытков (ущерба),
          возникших в связи с этим.
          <br />
          6.4. Лицензиат дает согласие на:
          <br />
          6.4.1. хранение персональных данных Лицензиаром;
          <br />
          6.4.2. получение информации об услугах, новостях Лицензиара и (или) Партнеров Лицензиара
          <br />
          6.4.4. трансграничную передачу персональных данных;
          <br />
          6.4.5. передачу персональных данных другому Лицензиату в случае присоединения к Партнерской программе другого
          Лицензиата.
          <br />
          6.5. Лицензиат имеет право в любой момент изменить данные, указанные при Регистрации, используя Личный
          кабинет.
          <br />
          6.6. Лицензиар обрабатывает только данные Лицензиата, которые необходимы для исполнения настоящего Соглашения.
          Согласно положению ст.6 ФЗ РФ «О Персональных данных», согласие Лицензита на обработку его данных не
          требуется. Согласно положениям ст.22 ФЗ РФ «О Персональных данных», Лицензиар не должен уведомлять
          Роскомнадзор РФ об обработке Персональных данных Лицензиата.
          <br />
          6.7. Лицензиар не имеет доступа к персональным данным третьих лиц, которые Лицензиат заносит в Сервис.
          <br />
          6.8. Лицензиат обязуется предварительно получать согласие субъекта персональных данных на использование его
          персональных данных посредством Сервиса. Лицензиат обязуется не размещать персональные данные третьих лиц либо
          сотрудников Лицензиата, не давших согласия на такое размещение.
          <br />
          6.9. Лицензиар обязуется приложить все усилия для обеспечения конфиденциальности данных, размещенных
          Лицензиатом посредством Сервиса, на весь период их нахождения на сервере Лицензиара.
          <br />
          6.10. Лицензиат осведомлен и согласен, что телефонные разговоры с Лицензиаром могут быть записаны в целях
          контроля качества работы Лицензиара.
          <br />
          6.11. Лицензиар не имеет доступа к персональным данным третьих лиц (Пользователям Лицензиата) в соответствии с
          п. 6.7. настоящего Соглашения.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>7. ОТВЕТСТВЕННОСТЬ. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</span>
          <br />
          <br />
          7.1. Сервис предоставляется Лицензиату «как есть» (as is), в соответствии с общепринятым в международной
          практике принципом. Это означает, что за проблемы, возникающие в процессе установки, обновления, поддержки и
          эксплуатации Сервиса (в т. ч. проблемы совместимости с другими программными продуктами (пакетами, драйверами и
          др.), несоответствия результатов использования Сервиса ожиданиям Лицензиата и т.п.), Лицензиар ответственности
          не несет. Лицензиат должен понимать, что несет полную ответственность за возможные негативные последствия,
          вызванные несовместимостью или конфликтами Сервиса с другими программными продуктами, установленными на
          компьютере или ином устройстве Лицензиата. Сервис не предназначен и не может быть использован в информационных
          системах, работающих в опасных средах либо обслуживающих системы жизнеобеспечения, в которых сбой в работе
          Сервиса может создать угрозу жизни людей или повлечь большие материальные убытки.
          <br />
          7.2. Лицензиар не несет ответственности за невозможность использования Сервиса по причинам, зависящим от
          Лицензиата или третьих лиц.
          <br />
          7.3. Лицензиар прилагает все возможные усилия для обеспечения нормальной работоспособности Сервиса и Сайта,
          однако не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по настоящему
          Соглашению, а также за прямые и косвенные убытки Лицензиата, включая упущенную выгоду и возможный ущерб,
          возникший в том числе в результате:
          <br />
          7.3.1. неправомерных действий пользователей сети Интернет, направленных на нарушение информационной
          безопасности или нормального функционирования Сервиса.
          <br />
          7.3.2. отсутствия (невозможности установления, прекращения и пр.) интернет-соединений между сервером
          Лицензиата и сервером Лицензиара.
          <br />
          7.3.3. проведения государственными и муниципальными органами, а также иными организациями мероприятий в рамках
          оперативно-розыскных мероприятий.
          <br />
          7.3.4. установления государственного регулирования (или регулирования иными организациями) хозяйственной
          деятельности коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых
          ограничений, затрудняющих или делающих невозможным исполнение Соглашения.
          <br />
          7.3.5. других случаев, связанных с действиями (бездействием) пользователей сети Интернет и/или других
          субъектов, направленными на ухудшение общей ситуации с использованием сети Интернет и/или компьютерного
          оборудования, существовавшей на момент заключения Соглашения.
          <br />
          7.4. Лицензиар оставляет за собой право приостанавливать работу Сайта и (или) Сервиса для проведения
          профилактических работ (по возможности в ночное время или выходные дни).
          <br />
          7.5. При использовании Сервиса Лицензиат обязуется не нарушать Законодательство, интересы третьих лиц, в том
          числе при создании веб-страниц, отправке рассылок, оказании услуг (продаже товаров) третьим лицам. Лицензиар
          не несет ответственность за действия Лицензиата, совершаемые с использованием Сервиса, равно как не несет
          ответственность за невыполнение Лицензиатом обязательств перед третьими лицами.
          <br />
          7.6. Лицензиат самостоятельно несет ответственность за сохранность своего Логина и пароля и за убытки, которые
          могут возникнуть по причине его несанкционированного использования.
          <br />
          7.7. При любых обстоятельствах ответственность Лицензиара ограничивается суммой лицензионного вознаграждения
          за 1 месяц.
          <br />
          7.8. В случае возникновения между Лицензиатом и Лицензиаром споров или разногласий, вытекающих из настоящего
          Лицензионного соглашения или связанных с ним, стороны примут все меры к их разрешению путем переговоров между
          собой. Стороны установили, что срок ответа на досудебную претензию составляет 10 рабочих дней.
          <br />
          7.9. В случае, если не удается разрешить возникшие между сторонами споры и/или разногласия путем переговоров,
          то такие споры разрешаются в Арбитражном суде г. Москвы либо суде общей юрисдикции по месту нахождения
          Лицензиара.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>8. СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ, ПОРЯДОК РАСТОРЖЕНИЯ</span>
          <br />
          <br />
          8.1. Настоящее Соглашение вступает в силу с даты акцепта и действует до расторжения Сторонами, а в части
          передачи Неисключительной лицензии — в течение срока, установленного Тарифами.
          <br />
          8.2. Настоящее Соглашение является офертой и в силу действующего гражданского законодательства РФ Лицензиар
          имеет право на отзыв оферты в соответствии со ст. 436 ГК РФ. В случае отзыва настоящего Соглашения Лицензиаром
          в течение срока его действия настоящее Соглашение считается прекращенным с момента отзыва. Отзыв
          осуществляется путем размещения соответствующей информации на сайте {REACT_APP_SELF_URL}.
          <br />
          8.3. Настоящее Соглашение может быть расторгнуто досрочно:
          <br />
          8.3.1. по взаимному соглашению Лицензиара и Лицензиата.
          <br />
          8.3.2. по инициативе Лицензиара в одностороннем внесудебном порядке.
          <br />
          8.3.3. по инициативе Лицензиара в одностороннем внесудебном порядке в случае нарушения Лицензиатом авторского
          права третьих лиц, условий Соглашения, Законодательства без возврата Лицензиату каких-либо денежных средств.
          Уведомление о расторжении направляется Лицензиаром в Личный кабинет за 1 календарный день до даты расторжения.
          <br />
          8.3.4. по инициативе Лицензиара в одностороннем внесудебном порядке с уведомлением Лицензиата за 1 месяц.
          Уведомление о расторжении направляется Лицензиаром в Личный кабинет.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>9. ПРОЧИЕ УСЛОВИЯ</span>
          <br />
          <br />
          9.1. Действие Неисключительной лицензии по Соглашению распространяется на все последующие обновления / новые
          версии Сервиса.
          <br />
          9.2. Принимая условия настоящего Соглашения, Лицензиат подтверждает наличие у него законных оснований для
          обработки принадлежащей ему информации с использованием Сервиса.
          <br />
          9.3. Все вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с
          Законодательством.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>10. АРБИТРАЖ ЛИЦЕНЗИАРА</span>
          <br />
          <br />
          10.1. Лицензиат либо Пользователи сети Интернет, имеющие жалобы на действия конкретного Лицензиата имеют право
          обратиться к Лицензиару.
          <br />
          10.2. По итогам рассмотрения жалобы Лицензиар имеет право установить факт нарушения настоящего Соглашения и
          (или) Законодательства и блокировать доступ к Сервису Лицензиата, на которого поступила жалоба.
          <br />
          10.3. Решение Лицензиара по жалобе является окончательным.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>11. РЕКВИЗИТЫ ЛИЦЕНЗИАРА</span>
          <br />
          <br />
          ИП Горшков Петр Владимирович
          <br />
          ИНН: 370305802700
          <br />
          ОГРНИП: 323370000026538
          <br />
          Юридический адрес: Ивановская область г. Кинешма, ул. Жуковская, дом 4, кв 5
          <br />
          <br />
          АО АЛЬФА-БАНК
          <br />
          Р/сч 40802810001780002329
          <br />
          кор.счет: 30101810200000000593
          <br />
          БИК: 044525593
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>Агентский договор (публичная оферта)</span>
          <br />
          <br />
          Настоящий Договор адресован неопределенному кругу лиц и является публичной офертой в соответствии с п. 2 ст.
          437 ГК РФ.
          <br />
          Безусловным акцептом Принципалом настоящего Договора является Регистрация на Сайте путем заполнения
          Регистрационной формы либо проставление отметки “прочитал и согласен с условиями оферты” при заполнении в
          Личном кабинете реквизитов на выплату денежных средств.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>1. ТЕРМИНЫ</span>
          <br />
          <br />
          Стороны используют указанные термины в следующем значении:
          <br />
          Агент – “ИП Горшков Петр Владимирович”, являющееся стороной настоящего Договора и предоставляющее Принципалу
          услуги в порядке и на условиях настоящего Договора.
          <br />
          Сайт – сайт, расположенный в сети Интернет по адресу {REACT_APP_SELF_URL}
          <br />
          Информационный сервис {REACT_APP_SELF_URL} (Сервис) – сервис доступный на Сайте, позволяющий Принципалу
          создавать онлайн-странички с курсам, а также реализации иного функционала. Агент не является стороной
          Соглашения.
          <br />
          Принципал – сторона настоящего Договора, осуществляющая дистанционную торговлю услугами, совершившая акцепт в
          установленном порядке. Совершая акцепт настоящего Договора, Принципал гарантирует, что является резидентом
          Российской Федерации в статусе юридического лица или индивидуального предпринимателя и использует упрощенную,
          либо патентную системы налогообложения.
          <br />
          Личный кабинет – закрытая часть Сайта, доступ к которой осуществляется Принципалом путем ввода логина и пароля
          полученного при Регистрации на Сайте.
          <br />
          Регистрационная форма – форма, расположенная на Сайте, которую Принципал должен заполнить для прохождения
          Регистрации на Сайте.
          <br />
          Законодательство – действующее законодательство Российской Федерации.
          <br />
          Плательщик – лицо приобретающее товары (услуги) Принципала и оплачивающее их посредством сервиса Агента.
          <br />
          Отчетный период – один календарный месяц.
          <br />
          Web-интерфейс – совокупность унифицированных технических и программных средств и правил (описаний, соглашений,
          протоколов), обеспечивающих взаимодействие Принципала , Агента и Плательщика.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>2. ПРЕДМЕТ ДОГОВОРА</span>
          <br />
          <br />
          2.1. Агент обязуется от своего имени и за счет Принципала (поставщика) осуществлять:
          <br />
          – техническую поддержку Плательщиков;
          <br />
          – техническую поддержку Принципала;
          <br />
          – маркетинговые услуги Принципалу,
          <br />
          2.2. Агент имеет право в любой момент изменять настоящий Договор и все приложения к нему (полностью или в
          части) в одностороннем порядке, без предварительного согласования с Принципалом. Все изменения вступают в силу
          на следующий календарный день после размещения на Сайте.
          <br />
          2.3. Принципал обязуется самостоятельно следить за изменениями настоящего Договора и приложений к нему путем
          периодического ознакомления с актуальной редакцией, не менее одного раза в месяц. Принципал самостоятельно
          отвечает за любые последствия, наступившие в связи с неознакомлением с Договором и приложениями к нему.
          <br />
          2.4. В случае, если Принципал не согласен с каким-либо положением Договора и (или) приложений к нему, он
          должен незамедлительно прекратить использование услуг Агента
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</span>
          <br />
          <br />
          3.1. Агент обязуется:
          <br />
          3.1.1. разместить на Сайте форму для переадресации Плательщиков;
          <br />
          3.2. Принципал обязуется:
          <br />
          3.2.1. по запросу Агента в течение 3 (трех) рабочих дней предоставлять информацию и документы подтверждающие
          надлежащее исполнение обязательств перед Плательщиками. Запрос может направляться посредством электронной
          почты.
          <br />
          3.3. Агент имеет право:
          <br />
          3.3.1. привлекать других лиц – субагентов - для осуществления приема платежей физических лиц (Плательщиков) в
          целях исполнения поручений Принципала по настоящему договору. Субагент вправе осуществлять прием платежей или
          от своего имени или от имени Агента. Агент остается ответственным за действия субагента перед Принципалом.
          <br />
          3.4. Принципал имеет право:
          <br />
          3.4.1. контролировать действия Агента, производимые им в рамках настоящего Договора посредством Личного
          кабинета;
          <br />
          3.4.2. получать Отчеты Агента о выполнении поручений Принципала;
          <br />
          3.5. Агент осуществляет прием платежей от Плательщиков при условии, если:
          <br />
          3.5.1. при осуществлении платежа Плательщиком указана информация, позволяющая однозначно идентифицировать
          Плательщика и Принципала;
          <br />
          3.5.2. Платеж совершается в рублях Российской Федерации.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>4. Вознаграждение АГЕНТА и отчет АГЕНТА</span>
          <br />
          <br />
          4.1. Принципал уплачивает Агенту вознаграждение за исполнение поручения в размерах установленных Тарифами.
          <br />
          4.2. С периодичностью один раз в месяц не ранее 15-го числа, но не позднее 20-го числа Агент обязуется
          предоставлять Принципалу Отчет Агента за предыдущий месяц.
          <br />
          4.3. Принципал обязан рассмотреть Отчет в течение 4 (четырех) рабочих дней с момента размещения в Личном
          кабинете, и в случае имеющихся возражений в письменном виде сообщить о них Агенту в течение вышеуказанного
          срока. В противном случае Отчет считается принятым, а услуги Агента оказанными в полном объеме. После
          утверждения Отчета претензии по Отчетному периоду не принимаются.
          <br />
          4.4. Агент имеет право удерживать причитающееся вознаграждение из сумм подлежащих перечислению Принципалу.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>5. ПОРЯДОК РАСЧЕТОВ</span>
          <br />
          <br />
          5.1. Все денежные средства, полученные Агентом по исполнению поручений по Договору, совершенных в интересах
          Принципала, Агент обязан перечислять Принципалу на банковский счет Принципала. Перевод денежных средств
          осуществляется по запросу Принципала, в течение 15 (пятнадцати) рабочих дней с момента получения запроса.
          <br />
          5.2. Запрос Принципала на перевод денежных средств направляется посредством электронной почты.
          <br />
          5.3. В случае, если в течение Отчетного периода от Плательщиков в адрес Принципала поступило менее 5 000
          рублей, указанная сумма не перечисляется Принципалу до следующего Отчетного периода.
          <br />
          5.4. В случае недостаточности сумм, поступивших от Плательщиков, для погашения агентского вознаграждения
          Принципал обязан оплатить причитающееся Агенту вознаграждение в течение 4 (четырех) рабочих дней после
          выставления счета на оплату.
          <br />
          5.5. Датой исполнения обязанности Агента по перечислению денежных средств считается дата списания денежных
          средств с расчетного счета Агента.
          <br />
          5.6. Принципал обязуется возместить Агенту денежные суммы взысканные с Агента платежными системами (кредитными
          учреждениями) за нарушение Принципалом действующего законодательства, правил международных платежных систем,
          совершение мошеннических или противоправных действий. Агент имеет право вычесть указанные суммы из сумм
          причитающихся Принципалу. В случае, если денежных средств полученных от Покупателей недостаточно для удержания
          указанных сумм Принципал возмещает их в течение 5 рабочих дней с момента выставления счета. Счет на оплату
          выставляется посредством Личного кабинета.
          <br />
          5.7. В случае, если получателем платежа является нерезидент РФ Агент оставляет за собой права удерживать
          дополнительные комиссии/сборы.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>6. ОТВЕТСТВЕННОСТЬ СТОРОН</span>
          <br />
          <br />
          6.1. За неисполнение или ненадлежащее исполнение обязанностей по настоящему Договору стороны несут
          ответственность, предусмотренную действующим законодательством.
          <br />
          6.2. За несвоевременную оплату вознаграждения, установленную п.5.1.2. настоящего договора, Агент вправе
          начислить Принципалу неустойку в размере 0,01% за каждый день просрочки перечисления денежных средств, но не
          более 10 % от просроченной суммы.
          <br />
          6.3. Агент не несет ответственности перед Плательщиком за действия (бездействия) Принципала.
          <br />
          6.4. Агент не осуществляет возврат денежных средств Плательщику, за исключением случаев, когда порядок и сроки
          возврата установлены дополнительным соглашением Сторон. Дополнительное соглашение о порядке и сроках возврата
          денежных средств Плательщикам подписывается сторонами путем составления единого документам и обмена
          скан-копиями по электронной почте с последующей передачей оригиналов документов.
          <br />
          6.5. За нарушение Принципалом обязанности предусмотренной п. 3.2.7. Принципал уплачивает штраф в размере 10
          000 руб. за каждое нарушение.
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>7. ПЕРСОНАЛЬНЫЕ ДАННЫЕ</span>
          <br />
          <br />
          7.1. В рамках исполнения настоящего Договора Агентом обрабатываются следующие Персональные данные:
          <br />
          - фамилия, имя и отчество;
          <br />
          - адрес электронной почты;
          <br />
          - номер телефона;
          <br />
          - паспортные данные;
          <br />
          - а также иные Персональные данные, которые Принципал самостоятельно по своей инициативе.
          <br />
          7.2. Принципал дает согласие на:
          <br />
          7.2.1. хранение персональных данных на сервере Агента
          <br />
          7.2.2. получение информации об услугах, новостях Агента
          <br />
          7.2.3. трансграничную передачу персональных данных;
          <br />
          7.3. Персональные данные, обработка которых осуществляется Агентом, используются и обрабатываются для
          исполнения Агентом своих обязательств перед Принципалом в соответствии с настоящим агентским договором.
          <br />
          7.4. Согласно положению ст.6 ФЗ РФ «О Персональных данных», согласие Принципала на обработку его данных не
          требуется. Согласно положениям ст.22 ФЗ РФ «О Персональных данных», Агент не должен уведомлять Роскомнадзор РФ
          об обработке Персональных данных Принципала.
          <br />
          7.5. Агент не предоставляет Персональные данные Прицнипала третьим лицам, за исключением случаев, когда
          Принципал дал согласие на предоставление таких данных или предоставление таких данных осуществляется по
          законному требованию государственных уполномоченных органов РФ.
          <br />
          7.6. В случае отзыва Принципалом согласия на обработку его персональных данных, Агент обязан прекратить их
          обработку и уничтожить персональные данные в срок, не превышающий тридцати дней с даты поступления указанного
          уведомления.
          <br />
          7.7. Агент предпринимает все необходимые меры по защите Персональных данных Принципала от неправомерного
          доступа третьих лиц.
          <br />
          7.8. Персональные данные Платильщиков Принципала.
          <br />
          7.8.1. Сторонами определено, что в соответствии с настоящим агентским договором, данные Платильщиков,
          приобретающих товары (услуги) Принципала и оплачивающих их посредством сервиса Агента должны обрабатываться
          Принципалом в соответствии с п.3 статьи 6 ФЗ № 152 РФ «О Персональных данных».
          <br />
          7.8.2. Согласно п.3 статьи 6 ФЗ № 152 РФ «О Персональных данных» Принципал, как оператор Персональных данных
          своих покупателей, поручает Агенту хранение таких Персональных данных с целью надлежащего оказания услуг
          Агентом.
          <br />
          7.8.3. Агент обязан соблюдать конфиденциальность таких Персональных данных и обеспечивать их безопасность при
          хранении.
          <br />
          7.8.4. Положение о конфиденциальности не распространяется в случае, если запрос о предоставлении такого рода
          сведений поступил от государственного органа.
          <br />
          7.9. Принципал обязуется предварительно получать согласие субъекта персональных данных на использование его
          персональных данных посредством Сервиса. Принципал обязуется не размещать персональные данные третьих лиц, не
          давших согласия на такое размещение.
          <br />
          7.10. Агент обязуется приложить все усилия для обеспечения конфиденциальности данных, размещенных Принципалом
          посредством Сервиса, на весь период их нахождения на сервере Агента.
          <br />
          7.11. Принципал осведомлен и согласен, что телефонные разговоры с Агентом могут быть записаны в целях контроля
          качества работы Агента.
          <br />
          7.12. Принципал, как оператор персональных данных своих Платильщиков, поручает Агенту хранение таких
          персональных данных на сервере Агента с использованием необходимых программно-аппаратных средств защиты с
          целью надлежащего оказания услуг Агентом в рамках исполнения договорных обязательств между Агентом и
          Принципалом.
          <br />
          7.13. В соответствии со статьей 22 Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных»
          Прицнипал до начала обработки персональных данных обязан уведомлять Роскомнадзор РФ о намерении осуществлять
          обработку персональных данных своих Платильщиков.
          <br />
          7.14. В целях соблюдения защиты персональных данных третьих лиц, предусмотренных Федеральным законом от
          27.07.2006 г. №152-ФЗ «О персональных данных», и невозможности Агентом самостоятельно производить какие-либо
          действия, касающиеся обработки персональных данных, Стороны договорились установить следующий порядок
          взаимодействия при получении Принципалом Запроса:
          <br />
          7.14.1. При получении Принципалом (Оператором обработки персональных данных) Запроса, содержащего отзыв
          субъекта персональных данных согласия на обработку персональных данных, Принципал обязуется в течение от трех
          до семи рабочих дней с момента его (отзыва) получения уведомить Агента о необходимости удаления отозванных
          данных либо представить субъекту персональных данных мотивированный отказ от выполнения Запроса.
          <br />
          7.14.2. По требованию Агента Принципал обязан представить доказательства соблюдения законодательства о защите
          персональных данных, а также документы, подтверждающие надлежащее исполнение Принципалом обязательств,
          предусмотренных действующим законодательством в области обработки персональных данных.
          <br />
          7.14.3. В случае проведения проверки деятельности Агента и/или выявления нарушений обработки персональных
          данных уполномоченным органом по защите прав субъектов персональных данных Агент адресует требование
          уполномоченного органа, которое не может быть выполнено Агетом в силу объективных причин, Принципалу.
          Указанное требование подлежит немедленному исполнению Принципалом.
          <br />
          7.14.4. С момента передачи Агентом требований уполномоченного органа по защите прав субъектов персональных
          данных Принципал становится ответственным за неисполнение или ненадлежащее исполнение указанных требований в
          соответствующей части и обязуется возместить Агенту причиненные таким неисполнением убытки в течение 20
          календарных дней с момента получения требования Агента в письменной форме.
          <br />
          7.14.5. Третье лицо признает и соглашается с тем, что Агент не несет и не может нести ответственности за
          действия Принципала за ненадлежащую обработку персональных данных третьего лица/третьих лиц.
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>8. ФОРС - МАЖОР</span>
          <br />
          <br />
          8.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по настоящему
          Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после
          заключения Договора в результате событий чрезвычайного характера, которые участник не мог ни предвидеть, ни
          предотвратить разумными мерами. К таким событиям чрезвычайного характера относятся: война и военные действия,
          восстание, эпидемии, землетрясения, наводнения, акты органов власти, непосредственно затрагивающие предмет
          настоящего Договора, и другие события, которые компетентный государственный орган признает и объявит случаями
          непреодолимой силы.
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>9. РАЗРЕШЕНИЕ СПОРОВ</span>
          <br />
          <br />
          9.1. Стороны будут стремиться разрешить все споры и разногласия, которые могут возникнуть из настоящего
          Договора, путем переговоров. Если указанные споры не могут быть решены путем переговоров, они подлежат
          передаче на разрешение в Арбитражный суд г. Москвы либо в суде общей юрисдикции по месту нахождения Агента.
          <br />
          9.2. Стороны обязуются соблюдать досудебный претензионный порядок перед обращением в суд. Срок ответа на
          поступившую досудебную претензию составляет 30 (тридцать) календарных дней. Досудебная претензия должна быть
          направлена в письменном виде по адресу местонахождения Агента.
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span>
          <br />
          <br />
          10.1. Настоящий Договор вступает в силу с момента его акцепта Принципалом и действует в течение года. Если в
          течение 30 (Тридцати) календарных дней до истечения указанного срока ни одна из Сторон не заявит письменно о
          прекращении настоящего Договора, последний считается пролонгированным каждый раз на новый срок, равный одному
          году на прежних условиях
          <br />
          10.2. Любая из сторон вправе отказаться от исполнения договора путем письменного уведомления другой стороны не
          менее, чем за 20 дней до даты прекращения. В этом случае договор считается расторгнутым с даты, указанной в
          таком уведомлении. Расторжение договора не влечет автоматическое погашение задолженностей, возникших до даты
          расторжения. Агент имеет право на отзыв оферты в соответствии со ст. 436 ГК РФ. В случае отзыва настоящего
          Договора Агентом в течение срока его действия настоящий Договор считается прекращенным с момента отзыва. Отзыв
          осуществляется путем размещения соответствующей информации на Сайте.
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>11. РЕКВИЗИТЫ АГЕНТА</span>
          ​<br />
          <br />
          ИП Горшков Петр Владимирович
          <br />
          ИНН: 370305802700
          <br />
          ОГРНИП: 323370000026538
          <br />
          Юридический адрес: Ивановская область г. Кинешма, ул. Жуковская, дом 4, кв 5
          <br />
          <br />
          АО АЛЬФА-БАНК
          <br />
          Р/сч 40802810001780002329
          <br />
          кор.счет: 30101810200000000593
          <br />
          БИК: 044525593
          <br />
          <br />
          Приложение к Лицензионному соглашению (публичной оферте)
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>ТРЕБОВАНИЯ К ЛИЦЕНЗИАТУ</span>
          <br />
          <br />
          Перед Регистрацией на Сайте {REACT_APP_SELF_URL} Лицензиат обязан ознакомиться с настоящими Требованиями.
          Используя Сайт {REACT_APP_SELF_URL} и его функционал Лицензиат подтверждает, что соблюдает настоящие
          Требования.
          <br />
          <br />
          Лицензиар оставляет за собой право проводить проверки соответствия сайтов Лицензиата указанным требованиям. В
          случае нарушения настоящих Требований Лицензиар имеет право применить к Лицензиату ответственность, указанную
          в Лицензионном соглашении (публичной оферте).
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>1. ТРЕБОВАНИЯ К САЙТУ ЛИЦЕНЗИАТА</span>
          <br />
          <br />
          1.1. Сайт должен быть функционирующий, с заполненным контентом, работающими внутренними ссылками;
          <br />
          1.2. Сайт не должен состоять из одной страницы и/или реализовывать одну единицу товара, должна быть
          представлена большая часть ассортимента;
          <br />
          1.3. Сайт должен быть выложен в публичный доступ и не запрашивать логин/пароль для входа;
          <br />
          1.4. Услуги, представленные на Сайте, не должны противоречить Законодательству;
          <br />
          1.5. Сайт не должен быть размещен на бесплатном сервере.
          <br />
          1.6. На Сайте должно быть указано полное фирменное наименование, ИНН, юридический и фактический адреса,
          телефон и адрес электронной почты организации, осуществляющей продажу услуг;
          <br />
          1.7. Продаваемые на Сайте услуги должны быть подробно описаны. Описание должно включать характеристики
          предлагаемых услуг, их потребительские свойства и цену;
          <br />
          1.8. Все страницы, которые связаны с реализацией услуг на сайте Лицензиата, должны находиться под единым
          доменным именем.
          <br />
          1.9. Запрещается указывать/использовать ссылки с настроенным редиректом. Магазин не пройдет модерацию, если в
          настройках указан один домен, но при переходе посетителя переадресовывает на адрес с другим доменным именем.
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>2. ТРЕБОВАНИЯ К ПУБЛИЧНОЙ ОФЕРТЕ НА САЙТЕ ЛИЦЕНЗИАТА</span>
          <br />
          <br />
          2.1. Публичная оферта, размещенная на сайте Лицензиата, должна содержать следующие положения (разделы):
          <br />
          ​полные реквизиты Лицензиата;
          <br />
          порядок акцепта оферты;
          <br />
          порядок заказа услуг;
          <br />
          порядок оплаты услуг;
          <br />
          2.2. На сайте Лицензиата должно быть размещено согласие Покупателя на обработку персональных данных,
          составленное в соответствии с действующим законодательством, в том числе ФЗ «О персональных данных» № 152-ФЗ.
          <br />
          <br />﻿
          <span style={{ fontWeight: 500 }}>3. ТРЕБОВАНИЯ К РАЗДЕЛУ «ПОРЯДОК ОПЛАТЫ УСЛУГ» НА САЙТЕ ЛИЦЕНЗИАТА</span>
          <br />
          <br />
          3.1. На сайте Лицензиата должен быть размещен раздел «Порядок (Способы) оплаты».
          <br />
          3.2. В разделе должна быть размещена следующая информация:
          <br />
          способы оплаты;
          <br />
          сервисы (платежные системы) используемые для осуществления оплаты;
          <br />
          сроки зачисления денежных средств;
          <br />
          указание на то, что сервисы (платежные системы) не несут ответственность перед Покупателями. Возврат денежных
          средств всегда осуществляет Лицензиат.
          <br />
          <br />﻿<span style={{ fontWeight: 500 }}>4. ТРЕБОВАНИЯ К УСЛУГАМ ЛИЦЕНЗИАТА</span>
          <br />
          <br />
          4.1. Сайт не должен использоваться для распространения и рекламы товаров или услуг незаконного, криминального
          характера, не соответствующих общепринятым этическим и правовым нормам.
          <br />
          4.2. Запрещается Регистрироваться в случае, если сайт Лицензиата использует в качестве товаров:
          <br />
          предметы истории и искусства, составляющие культурные ценности страны, в которой находится потребитель. бывшую
          в употреблении косметику.
          <br />
          поддельную валюту, в том числе электронную, например Биткоин (Bitcoin), поддельные монеты и поддельные марки.
          <br />
          наркотические вещества и сопутствующие изделия, описание наркотиков или наркотических веществ, стероиды,
          вещества, подлежащие контролю и ограничениям, или другие продукты, которые представляют риск для безопасности
          потребителей.
          <br />
          электронное оборудование, запрещенное на территории страны потребителя (например, дешифраторы для кабельного
          ТВ, радары и устройства контроля за светофорами, электронное оборудование для наблюдения, подслушивающие
          устройства и телефонные жучки и т.п.) огнестрельное оружие и его точные копии, холодное и метательное оружие,
          средства защиты, спреи, электрошоки.
          <br />
          государственные отличительные знаки, правительственные документы, униформа, правительственные удостоверения
          личности и лицензии, а также товары, имеющие отношение к полиции (милиции).
          <br />
          опасные и скоропортящиеся товары, а также товары с ограничениями в использовании (например, взрывчатка,
          радиоактивные материалы, токсичные вещества, аккумуляторы, фейерверки, фреон и т.п.) человеческие органы и
          останки.
          <br />
          товары, поощряющие незаконную деятельность или подстрекающие других к участию в незаконной деятельности.
          <br />
          устройства для взлома и вскрытия замков.
          <br />
          списки для рассылки спама, данные для доступа к учетным записям в системах электронной почты, социальных
          сетях, телефонных сетях, службам доставки сообщений, а также любая информация содержащая персональные данные.
          <br />
          похищенные товары, включая цифровые и виртуальные товары, а также способы доступа в электронные системы,
          многопользовательские игры, включая служебные коды доступа и специальные программы для обхода защиты
          программного обеспечения.
          <br />
          оскорбительные материалы (например, товары оскорбляющие нравственность, этнически или расово, памятные
          нацистские предметы, товары проповедующие насилие, этническую вражду, ненависть, расизм, обидные и ругательные
          высказывания и оскорбления).
          <br />
          лекарства и медицинские препараты, их производные, а также сырье для их производства, отпускаемые по рецепту.
          <br />
          запрещенные услуги и товары, пропагандирующие ненависть, насилие, расовую нетерпимость или получение
          экономической выгоды от преступной деятельности, включая незаконные сексуальные услуги, а также услуги,
          которые нарушают/противоречат Кодексу Системы.
          <br />
          краденая собственность и собственность с удаленными серийными номерами.
          <br />
          ядовитые травы и запрещенные семена.
          <br />
          подпольные или нелегальные аудио и видео записи.
          <br />
          поддельные или контрафактные товары.
          <br />
          мод-чипы, игровые эмуляторы, загрузочные диски и устройства.
          <br />
          товары, не имеющие потребительской стоимости.
          <br />
          порнография, детская порнография, извращения, эскорт-услуги или проституция.
          <br />
          аукционы или торговые системы, позволяющие участникам вести деятельность, исключающую передачу товаров, услуг
          или иных продуктов, имеющих потребительскую стоимость.
          <br />
          финансовые или платежные инструменты, системы учета которых не обеспечивают должной идентификации личности
          владельца для целей борьбы с незаконной торговлей, финансовыми махинациями, отмыванием и легализацией денежных
          средств, полученных незаконным путем.
          <br />
          многоуровневый маркетинг, финансовые пирамиды и матрицы, бизнес-пакеты.
          <br />
          табачные изделия.
          <br />
          акции и другие ценные бумаги
          <br />
          азартные игры (любые разновидности казино, игр, включая покер, рулетку, бинго, баккара, букмекерство – все
          виды ставок на всевозможные события), лотерейные билеты, бинарные опционы
          <br />
          финансовые инструменты
          <br />
          алкогольные напитки
          <br />
          сбор пожертвований в пользу третьих лиц в качестве благотворительной или некоммерческой организации
          <br />
          4.3. Запрещается продавать обучающие курсы (аудио, видео, текст и в других форматах) по вышеперечисленным
          материалам.
          <br />
          <br />﻿
          <span style={{ fontWeight: 500 }}>5. ТРЕБОВАНИЯ К ЛИЦЕНЗИАТУ В ОБЛАСТИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
          <br />
          <br />
          Собирая персональные данные посредством своего сайта Лицензиат становится Оператором персональных данных.
          Перед началом обработки персональных данных Лицензиат обязан ознакомиться и выполнить настоящие Требования.
          <br />
          <br />
          Лицензиат обязан:
          <br />
          5.1. Собирать и обрабатывать с помощью своих форм только те данные, которые необходимы для осуществления
          заявленных Лицензиатом целей.
          <br />
          5.2. Иметь на своем Сайте Политику конфиденциальности (далее - Политика), в которой определяются положения о
          том, как Лицензиат обрабатывает и защищает персональные данные своих Пользователей, и Согласие на обработку
          персональных данных (далее - Согласие).
          <br />
          5.3. Включить в обязательном порядке в раздел Политики, посвященный согласию Пользователя, или в текст
          Согласия пункты о том, что Пользователь дает свое согласие на: обработку персональных данных; передачу
          персональных данных Лицензиару – “ИП Горшков Петр Владимирович”;
          <br />
          5.4. Разместить ссылку на Cогласие под каждой формой сбора персональных данных (формой регистрации, формой
          заказа, формой сбора контактов, формой подписки на рассылку и т.д.).
          <br />
          5.4.1. Оптимальным способом получения Согласия является размещение галочки/чекбокса «согласие на обработку
          персональных данных» (с гиперссылкой на текст Cогласия) внизу любой формы сбора персональных данных на Сайте.
          Отправка данных из формы в личный кабинет Лицензиата должна быть невозможной без нажатия галочки «согласие на
          обработку персональных данных».
          <br />
          5.5. Разместить Политику на своем сайте и обеспечить ее доступность.
          <br />
          5.5.1. Гиперссылка на Политику должна быть размещена внизу страницы регистрации {REACT_APP_SELF_URL}
          {routeNames.signUp}
          Сайта Лицензиата.
          <br />
          5.6. Отписывать подписчиков от рассылок или помещать их в блек-листы по их запросу в срок от трех до 7 дней (в
          зависимости от причин. Подробнее: в ст.20 152-ФЗ).
          <br />
          5.7. При несоблюдении данных требований аккаунт Лицензиата может быть заблокирован в одностороннем порядке
          Лицензиаром в соответствии с п. 5.3.1
          <br />
          <br />
        </p>
      </div>
    </MainTemplate>
  );
};
