import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { observer } from "mobx-react-lite";
import { articlesModel } from "../../entities/articles";
import { courseModel } from "../../entities/course";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { InputRadio } from "../../shared/ui/input-radio/input-radio";
import { CoursesService } from "../../shared/api/courses";
import { userModel } from "../../entities/user";

export const CoursePay = observer(() => {
  const { url, courseId } = useParams();
  const [period, setPeriod] = useState("month");

  const handlePeriodChange = (event) => {
    setPeriod(event.currentTarget.value);
  };

  useEffect(() => {
    if (courseId && url) articlesModel.getAll(url, courseId);
  }, [courseId, url]);

  useEffect(() => {
    if (courseId) courseModel.get(courseId);
  }, [courseId]);

  const onlinePayment = (
    <React.Fragment>
      {/* <TextField
        required
        type="text"
        title="ФИО получателя:"
        value={userModel.user?.fullName}
        set={(value) => userModel.changeUserField("fullName", value)}
      /> */}

      <br />

      <div className="tariff__box">
        <InputRadio
          value="month"
          checked={period === "month"}
          onChange={handlePeriodChange}
          className="tariff__radio"
          label={
            <div>
              Месяц <br />
              <span style={{ fontWeight: 700 }}>{courseModel.course?.priceMonth} руб.</span>
            </div>
          }
        />
        {courseModel.course?.priceSixMonths && (
          <>
            <hr />
            <InputRadio
              value="sixMonths"
              checked={period === "sixMonths"}
              onChange={handlePeriodChange}
              className="tariff__radio"
              label={
                <div>
                  Полгода <br />
                  <span style={{ fontWeight: 700 }}>{courseModel.course?.priceSixMonths} руб.</span>
                </div>
              }
            />
          </>
        )}

        {courseModel.course?.priceUnlimited && (
          <>
            <hr />
            <InputRadio
              value="unlimited"
              onChange={handlePeriodChange}
              checked={period === "unlimited"}
              className="tariff__radio"
              label={
                <div>
                  Год <br />
                  <span style={{ fontWeight: 700 }}>{courseModel.course?.priceUnlimited} руб.</span>
                </div>
              }
            />
          </>
        )}
      </div>
      <Button
        action={() => {
          if (!period) return;

          CoursesService.pay(courseId, userModel.user?.fullName, period).then(({ data }) =>
            window.location.replace(data.confirmationUrl)
          );
        }}
        text="Оплатить"
      />
    </React.Fragment>
  );

  const invitePayment = (
    <p style={{ marginTop: "16px" }}>
      Автор курса не подключил систему онлайн платежей, он открывает доступ лично каждому пользователю. Он оставил вам
      это сообщение: {courseModel.course?.contactsInfo || "контактная информация отсутствует"}
    </p>
  );

  const privateCourse = (
    <p style={{ marginTop: "16px" }}>Это Закрытый курс, для внутреннего использования. Автор дает вам доступ лично.</p>
  );

  const CONTENT = {
    1: onlinePayment,
    2: invitePayment,
    3: privateCourse,
  };

  return (
    <MainTemplate>
      <h2 className="tariff__title">
        Подписаться на курс: <br /> {courseModel.course?.name}
      </h2>

      <p>
        После подписки все закрытые статьи курса станут открытыми, даже те статьи, которые автор курса добавит после
        того, как вы подпишитесь.
      </p>
      <br />
      {CONTENT?.[parseInt(courseModel.course?.paymentType)] || "Загрузка..."}
    </MainTemplate>
  );
});
