import { makeAutoObservable, runInAction } from "mobx";
import { CoursesService } from "../../shared/api/courses";
import { replaceNullFields } from "../../shared/lib/object";

class CourseModel {
  initialState = {
    contactsInfo: "",
    courseVisibleType: 1,
    description: "",
    image: "",
    video: "", // TODO: в бэке добавить
    name: "",
    paymentType: 1,
    priceMonth: 0,
    priceSixMonths: 0,
    priceUnlimited: 0,
  };
  course = { ...this.initialState };
  success = false;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async get(courseId) {
    this.isLoading = true;
    const { data } = await CoursesService.fetchOne(courseId);

    if (!data) return;

    runInAction(() => {
      this.course = replaceNullFields(data, "");
      this.success = data.success;
      this.isLoading = false;
    });

    return data;
  }

  async add() {
    const formData = new FormData();

    const keys = Object.keys(this.course);

    keys.forEach((key) => {
      formData.append(key, this.course[key]);
    });

    const { data } = await CoursesService.add(formData);

    return data;
  }

  async edit() {
    const formData = new FormData();

    const keys = Object.keys(this.course);

    keys.forEach((key) => {
      formData.append(key, this.course[key]);
    });

    const { data } = await CoursesService.editOne(formData);

    return data;
  }

  async remove(id) {
    const { data } = await CoursesService.removeOne(id);

    return data;
  }

  async removeImage(courseId, fileType) {
    const { data } = await CoursesService.removeImage(courseId, fileType);

    return data;
  }

  changeField(fieldName, value) {
    this.course[fieldName] = value;
  }

  reset() {
    this.course = { ...this.initialState };
    this.success = false;
    this.isLoading = false;
  }
}

export const courseModel = new CourseModel();
