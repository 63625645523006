import React, { useEffect } from "react";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { Link } from "../../shared/ui/link/link";
import clsx from "clsx";
import { InputRadioGroup } from "../../shared/ui/input-radio-group/input-radio-group";
import TextField from "../../components/TextField";
import { useState } from "react";

import styles from "./sales.module.scss";
import { Button } from "../../components/Button";
import { observer } from "mobx-react-lite";
import { userModel } from "../../entities/user";
import { salesModel } from "../../entities/sales/sales";
import { routeNames } from "../../shared/config";

export const Sales = observer(() => {
  const [isShowForm, setShowForm] = useState(true);
  const [legalPerson, setLegalPerson] = useState("Самозанятый");
  const [bic, setBic] = useState("");
  const [correspondentAccount, setCorrespondentAccount] = useState("");
  const [tin, setTin] = useState("");
  const [personFullName, setPersonFullName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [error, setError] = useState(false);

  const saveRequisites = (event) => {
    event.preventDefault();

    if (
      legalPerson === "" ||
      bic === "" ||
      correspondentAccount === "" ||
      tin === "" ||
      personFullName === "" ||
      accountNumber === ""
    ) {
      return setError(true);
    }

    userModel.saveRequisites({ legalPerson, bic, correspondentAccount, tin, personFullName, accountNumber });

    setShowForm(false);
  };

  const editRequisites = () => {
    setLegalPerson(userModel.user.legalPerson);
    setBic(userModel.user.bic);
    setCorrespondentAccount(userModel.user.correspondentAccount);
    setTin(userModel.user.tin);
    setPersonFullName(userModel.user.personFullName);
    setAccountNumber(userModel.user.accountNumber);

    setShowForm(true);
  };

  useEffect(() => {
    if (userModel.user?.legalPerson) {
      setLegalPerson(userModel.user.legalPerson);
      setBic(userModel.user.bic);
      setCorrespondentAccount(userModel.user.correspondentAccount);
      setTin(userModel.user.tin);
      setPersonFullName(userModel.user.personFullName);
      setAccountNumber(userModel.user.accountNumber);
      setShowForm(false);
    }
  }, [userModel.user?.legalPerson]);

  useEffect(() => {
    salesModel.getAll();
  }, []);

  return (
    <MainTemplate title="Статистика продаж">
      <span className={styles.description}>
        Таблицу продаж вы можете посмотреть по{" "}
        <Link target="_blank" to={routeNames.salesTable}>
          этой ссылке
        </Link>
      </span>
      <div className={styles.card}>
        <h4 className={styles.cardTitle}>Вам пришло:</h4>
        <div className={styles.cardAmount}>
          {salesModel.payin} <span className={styles.cardAmountCurrency}>руб.</span>
        </div>
      </div>
      <div className={styles.card}>
        <h4 className={styles.cardTitle}>Наша комиссия:</h4>
        <div className={styles.cardAmount}>
          {salesModel.commission} <span className={styles.cardAmountCurrency}>руб.</span>
        </div>
      </div>
      <div className={styles.card}>
        <h4 className={styles.cardTitle}>Мы вам перевели:</h4>
        <div className={clsx(styles.cardAmount, styles.cardAmountSended)}>
          {salesModel.payout} <span className={styles.cardAmountCurrency}>руб.</span>
        </div>
      </div>
      <div className={styles.card}>
        <h4 className={styles.cardTitle}>Мы должны вам:</h4>
        <div className={clsx(styles.cardAmount, styles.cardAmountShould)}>
          {salesModel.balance} <span className={styles.cardAmountCurrency}>руб.</span>
        </div>
      </div>
      <br />
      ВАШИ РЕКВИЗИТЫ ДЛЯ ПЕРЕВОДА ДЕНЕГ:
      <br />
      <br />
      {isShowForm ? (
        <form onSubmit={saveRequisites}>
          <InputRadioGroup
            title="Выберите кто вы:"
            value={legalPerson}
            setValue={setLegalPerson}
            className={styles.legalPerson}
            values={["Самозанятый", "ИП", "ООО", "ЗАО"]}
          />
          <TextField
            required
            type="number"
            title="БИК подразделения банка, который выпустил карточку получателя:"
            value={bic}
            set={setBic}
          />
          <TextField
            required
            type="number"
            title="Корреспондентский счет банка, выпустившего карту получателя денег:"
            value={correspondentAccount}
            set={setCorrespondentAccount}
          />
          <TextField required type="number" title="ИНН банка выпустившего карточку:" value={tin} set={setTin} />
          <TextField required type="text" title="ФИО получателя:" value={personFullName} set={setPersonFullName} />
          <TextField
            required
            type="number"
            title="Номер расчетного счета получателя средств:"
            value={accountNumber}
            set={setAccountNumber}
          />
          {error && <div className="error">Заполните все поля.</div>}
          <Button type="submit" text="Сохранить" />
        </form>
      ) : (
        <>
          <div>{legalPerson}</div>
          <div>БИК: {bic}</div>
          <div>КОР СЧЕТ: {correspondentAccount}</div>
          <div>ИНН: {tin}</div>
          <div>ФИО: {personFullName}</div>
          <div>НОМЕР СЧЕТА: {accountNumber}</div>
          <Button text="Редактировать" action={editRequisites} />
        </>
      )}
    </MainTemplate>
  );
});
