import React, { useEffect } from "react";
import { ButtonLink } from "../../components/Button";
import { CourseRowAdmin } from "../../components/constructor/course.row/course.row";
import LoadingSpinner from "../../components/loader";
import { userModel } from "../../entities/user";
import { observer } from "mobx-react-lite";
import { coursesModel } from "../../entities/courses/courses";
import { REACT_APP_SELF_URL, routeNames, routeNamesWithParams } from "../../shared/config";
import { Link } from "../../shared/ui/link/link";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const AdminCourses = observer(() => {
  useEffect(() => {
    if (userModel.user?.url) coursesModel.getAll(userModel.user?.url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userModel.user?.url]);

  return (
    <MainTemplate>
      {userModel.user?.id === "" ? (
        <LoadingSpinner />
      ) : (
        <div className="column">
          <div className="navbar row items-center">
            <p className="text">Курсы/</p>
            <p>Статьи</p>
          </div>
          <p style={{ marginTop: -14 }}>
            Адрес страницы с вашими курсами:
            <br />
            <Link to={routeNamesWithParams({ url: userModel.user?.url }).courses} target="_blank" rel="noreferrer">
              {REACT_APP_SELF_URL}
              {routeNamesWithParams({ url: userModel.user?.url }).courses}
            </Link>
          </p>
          {/* <p style={{ marginBottom: 14 }}>
            Тариф:
            <Link className={styles.tariff} to="/tariff">
              бесплатно
            </Link>
          </p> */}
          <ButtonLink link={routeNames.adminCourseCreate} text="Добавить курс" />
          {coursesModel.isLoaded ? (
            coursesModel.courses.map((course, key) => (
              <CourseRowAdmin
                key={key}
                id={course.id}
                name={course.name}
                description={course.description}
                messages={course.feedbacksUnreaded}
                paymentType={course.paymentType}
                status={course.status}
              />
            ))
          ) : (
            <p>Список курсов загружается...</p>
          )}
        </div>
      )}
    </MainTemplate>
  );
});
