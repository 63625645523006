import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuElement } from "../../components/Header";
import { routeNames } from "../../shared/config";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

import styles from "./styles.module.scss";

export const Settings = () => {
  const navigate = useNavigate();

  return (
    <MainTemplate>
      <div className="column">
        <h1 className={styles.settingsTitle}>Настройки</h1>

        <MenuElement icon="whiteblack" text="Изменить пароль" action={() => navigate(routeNames.changePassword)} />

        {/* <MenuElement icon="dollar" text="Подключить приём платежей" action={() => navigate("/merchant")} /> */}

        {/* <MenuElement icon="arrowback" text="Оплаченные курсы" action={() => navigate("/bought")} /> */}
      </div>
    </MainTemplate>
  );
};
