import React from "react";
import { HelpButton } from "../../shared/ui/help-button/help-button";
import "./InputRadio.scss";
import styles from "./styles.module.scss";

const InputRadio = ({
  className,
  videoLinks,
  title = false,
  values = [],
  set,
  selected = 0,
  helpPopupContent,
  group = Math.random(1000, 9999),
}) => (
  <div className={`inputRadio ${className ? className : ""}`}>
    {title && (
      <p style={{ display: "flex" }} className="text title_text">
        {title}: {helpPopupContent && <HelpButton popupContent={helpPopupContent} />}
      </p>
    )}

    {values?.length > 0 &&
      values?.map((variant, index) => {
        if (variant === "") return null;

        return (
          <div
            key={index}
            className={`inputRadio_element ${selected === index + 1 ? "inputRadio_element--checked" : ""}`}
          >
            <div onClick={() => set(index + 1)} className="inputRadio_element__dot" />

            <input type="radio" name={group} id={`${group}_${index + 1}`} defaultChecked={selected === index + 1} />

            <label
              className={styles.label}
              key={index}
              htmlFor={`${group}_${index + 1}`}
              onClick={() => set(index + 1)}
            >
              <span className={styles.labelVariant}>
                {variant} {videoLinks?.length && <HelpButton videoLink={videoLinks[index]} />}
              </span>
            </label>
          </div>
        );
      })}
  </div>
);

export default InputRadio;
