import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import InputRadio from "../../components/InputRadio";
import LoadingSpinner from "../../components/loader";
import TextField from "../../components/TextField";
import { courseModel } from "../../entities/course";
import { subscribersModel } from "../../entities/courses/subscribers";
import { observer } from "mobx-react-lite";
import { validateEmail } from "../../shared/lib/validation";
import { REACT_APP_SELF_URL, routeNames, routeNamesWithParams } from "../../shared/config";
import { userModel } from "../../entities/user";
import { Link } from "../../shared/ui/link/link";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { isUnlimitedDate } from "../../shared/lib/date/is-unlimited-date";

export const AdminCourseSubscribers = observer(() => {
  const { courseId } = useParams();

  const [email, setEmail] = useState("");
  const [period, setPeriod] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");

  const reload = () => subscribersModel.getAll(courseId);

  useEffect(() => {
    courseModel.get(courseId);
    subscribersModel.getAll(courseId);
  }, [courseId]);

  async function handleAddSubscriber() {
    const { success, error } = await subscribersModel.add(courseId, email, period);

    success && reload();

    setErrorMessage(error);
  }

  async function handleRemoveSubscriber(userId) {
    if (window.confirm("Вы действительно хотите лишить этого пользователя доступа к курсу?")) {
      const { success } = await subscribersModel.remove(courseId, userId);

      success && reload();
    }
  }

  return (
    <MainTemplate>
      {!subscribersModel.isLoaded ? (
        <LoadingSpinner />
      ) : (
        <div className="column">
          <div className="navbar row items-center">
            <Link to={routeNames.adminCourses} className="text-blue text">
              Курсы
            </Link>
            <p>/Статьи</p>
          </div>
          <div className="course_row_admin__name">{courseModel.course?.name}</div>
          <span>
            Ссылка на закрытый курс:{" "}
            <Link to={routeNamesWithParams({ url: userModel.user?.url, courseId }).course}>
              {REACT_APP_SELF_URL}
              {routeNamesWithParams({ url: userModel.user?.url, courseId }).course}
            </Link>
          </span>
          <br />
          <p>
            Доступ к курсу имеете только Вы. Хотите показать курс другим пользователям, отправьте эту ссылку им и
            откройте для них доступ. Доступ открывается по e-mail который был указан при регистрации на сайте {""}
            <Link to={routeNames.main}>{REACT_APP_SELF_URL}</Link>. Это сделано для защиты вашего курса от взлома и
            сбора статистики с пользователей.
          </p>
          <br />
          <React.Fragment>
            <TextField
              title="Введите e-mail пользователя, которому хотите открыть доступ:"
              value={email}
              set={setEmail}
            />

            {errorMessage && <p className="error">Этот пользователь на прошел регистрацию на сайте directpro.net</p>}

            <InputRadio
              title="Курс будет доступен"
              values={[`Месяц`, `Полгода`, `На год`]}
              selected={period}
              set={setPeriod}
            />

            <Button text="Добавить" disabled={email.length < 4 || !validateEmail(email)} action={handleAddSubscriber} />
          </React.Fragment>
          {subscribersModel.subscribers?.added?.length > 0 && (
            <React.Fragment>
              <p style={{ marginBottom: 15 }}>ПОЛЬЗОВАТЕЛИ: {subscribersModel.subscribers?.added?.length}</p>

              {subscribersModel.subscribers?.added.map((subscriber) => (
                <Subscriber
                  key={subscriber.id}
                  id={subscriber.id}
                  email={subscriber.email}
                  date={subscriber.dateExpired}
                  remove={() => handleRemoveSubscriber(subscriber.id)}
                />
              ))}
            </React.Fragment>
          )}
        </div>
      )}
    </MainTemplate>
  );
});

const Subscriber = ({ id = false, email, date, remove = false }) => {
  const isUnlimited = isUnlimitedDate(date);

  return (
    <div className="feedback_row">
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue"
          href={`mailto:${email}`}
          title="Связаться с пользователем по e-mail"
        >
          {email}
        </a>
      </p>
      <p>Доступ до: {isUnlimited ? "на год" : date}</p>
      {id && (
        <p style={{ cursor: "pointer" }} className="error" onClick={remove}>
          удалить
        </p>
      )}
    </div>
  );
};
