import React from "react";
import { REACT_APP_API_URL, routeNamesWithParams } from "../../../shared/config";
import { Link } from "../../../shared/ui/link/link";
import styles from "./feedback.module.scss";

export const Feedback = ({ userId, fullName, avatarUrl, publishDate, text }) => {
  return (
    <div className={styles.feedback}>
      <Link to={routeNamesWithParams({ userId }).profile}>
        <img src={REACT_APP_API_URL + avatarUrl} className={styles.avatar} alt="" />
      </Link>

      <div>
        <div className={styles.row}>
          <Link to={routeNamesWithParams({ userId }).profile} className={styles.fullName}>
            {fullName}
          </Link>
          <span className={styles.publishDate}>{publishDate}</span>
        </div>

        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
};
