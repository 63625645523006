import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { articlesModel } from "../../entities/articles";
import { userModel } from "../../entities/user";
import { ButtonLink } from "../../components/Button";
import { routeNamesWithParams } from "../../shared/config";
import { SortableArticleRow } from "./article-row/sortable-article-row";
import { ArticleRow } from "./article-row/article-row";
import { MainTemplate } from "../../shared/ui/main-template/main-template";

export const Articles = observer(() => {
  const { courseId } = useParams();

  useEffect(() => {
    articlesModel.getAll(userModel.user?.url, courseId);
  }, [courseId]);

  const renderArticle = useCallback((article, index) => {
    return (
      <SortableArticleRow
        key={article.id}
        article={article}
        name={article.name}
        index={index}
        paymentType={articlesModel.course?.paymentType}
        courseId={courseId}
      />
    );
  }, []);

  return (
    <MainTemplate>
      <div className="column">
        <div className="navbar row items-center">
          <Link to="/admin/courses">
            <p className="buttonText_text">Курсы/</p>
          </Link>
          <p className="text">Статьи</p>
        </div>
        <p
          style={{ color: "black", fontWeight: 700, fontSize: 18, lineHeight: "24px", marginBottom: 6 }}
          className="text"
        >
          {articlesModel.course?.name}
        </p>

        <p>{articlesModel.course?.description}</p>

        <ButtonLink link={routeNamesWithParams({ courseId }).adminCourseArticleCreate} text="Добавить статью" />

        <br />

        {articlesModel.isLoaded ? (
          <>
            <div>{articlesModel.articles?.visible?.map((article, index) => renderArticle(article, index))}</div>
            <br />
            <p style={{ color: "#7e7e7e" }}>Cтатьи ниже, не отображаются в оглавлении курса:</p>
            <br />
            {articlesModel.articles?.hidden?.map((article) => (
              <ArticleRow
                key={article.id}
                paymentType={articlesModel.course?.paymentType}
                name={article.name}
                article={article}
                courseId={courseId}
              />
            ))}
          </>
        ) : (
          <p>Список статей загружается...</p>
        )}
      </div>
    </MainTemplate>
  );
});
