import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { articlesModel } from "../../entities/articles";
import { ArticleRow } from "../../components/ArticleRow";

import "./styles.scss";
import { routeNamesWithParams } from "../../shared/config";
import { courseModel } from "../../entities/course";
import { MainTemplate } from "../../shared/ui/main-template/main-template";
import { Video } from "../../shared/ui/video/video";

export const Course = observer(() => {
  const { url, courseId } = useParams();

  useEffect(() => {
    if (courseId) {
      articlesModel.getAll(url, courseId);
      courseModel.get(courseId);
    }
  }, [courseId, url]);

  if (courseModel.isLoading) return null;

  return (
    <MainTemplate>
      {courseModel.success ? (
        <div className="column text-center">
          {courseModel.course.paymentType === 3 && (
            <div className="courseRow">
              {/* {courseModel.course.image && (
              <div
                className="img"
                style={{ backgroundImage: `url( ${REACT_APP_API_URL}/${courseModel.course.image} )` }}
              />
            )} */}
              {courseModel.course.video && <Video link={courseModel.course.video} />}

              <div className="text">{courseModel.course.name}</div>
              <p className="course-row-description">{courseModel.course.description}</p>
            </div>
          )}

          <div style={{ marginBottom: 10 }} className="course-breadcrumbs flex items-center my-16">
            {(courseModel.course.paymentType === 1 || courseModel.course.success === false) && (
              <Link style={{ marginRight: 10 }} to={routeNamesWithParams({ url }).courses}>
                <div className="text-blue">Курсы</div>
              </Link>
            )}
            <div>Оглавление</div>
          </div>

          {articlesModel.isLoaded &&
            articlesModel.articles?.visible?.map((article, key) => (
              <ArticleRow
                key={key}
                url={url}
                course={articlesModel.course}
                article={article}
                id={article.id}
                text={article.name}
                // video={article.tocVideo}
                image={article.imageForToc}
                isCompleted={article.isCompleted}
              />
            ))}
        </div>
      ) : (
        <center>
          <h1 style={{ fontSize: 32, fontWeight: 700, marginBottom: 20 }}>Курс закрыт!</h1>
          <p>
            У вас нет доступа к этому курсу. <br />
            Автор курса дает доступ к нему лично.
          </p>
        </center>
      )}
    </MainTemplate>
  );
});
